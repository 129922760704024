
import { Handle, Position } from 'reactflow';

const StepFlowNode = ({ data }) => {

  return (
    <div className="step-flow-node">
      
      <div className='px-3 my-1 text-center flex justify-content-center align-items-center'>
        <img className='flex justify-content-center' src={data.type.iconBlack} alt="" width="25px"/>
        <span className='flex ml-3'>{data.name}</span>
      </div>

      <Handle position={Position.Top} id="a" />
      <Handle position={Position.Bottom} id="b" />
      <Handle position={Position.Right} id="c" />
      <Handle position={Position.Left} id="d" />
    </div>
  );
}

export default StepFlowNode;

