import SupplierForm from './supplierForm';

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';

import FileNewEdit from '../components/fileNewEdit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import _ from 'underscore';

import React from 'react';

import { useState } from 'react';
import { useNavigate } from "react-router-dom";


const SupplyChainStepForm = (props) => {
    const supplyChainStep = props.supplyChainStep;
    const suppliers = props.suppliers;

    const navigate = useNavigate();

    /******************************* INFORMAZIONI GENERALI *******************************/
    const onNameChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'name', supplyChainStep.id);
    }

    const onDescriptionChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'description', supplyChainStep.id);
    }

    const onOriginChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'originSupplier', supplyChainStep.id);
    }

    const onProcessorChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'processorSupplier', supplyChainStep.id);
    }

    const onTraderChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'traderSupplier', supplyChainStep.id);
    }

    const onOwnerChange = (e) => {
        props.onSupplyChainStepChange(e.target.value, 'owner', supplyChainStep.id);
    }

    /******************************* SUPPLIERS *******************************/
    const mediaHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <FontAwesomeIcon icon="photo-film" className='mx-3'/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Media</span>
            </a>
        )
    }

    const documentsHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <FontAwesomeIcon icon="file-alt" className='mx-3'/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Documenti</span>
            </a>
        )
    }

    const claimsHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <FontAwesomeIcon icon="file-image" className='mx-3'/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Claims</span>
            </a>
        )
    }

    const getCombinedMedia = () => {
        let media = []
        if(supplyChainStep.originSupplier){
            if(supplyChainStep.originSupplier?.media?.length !== 0){
                _.each(supplyChainStep.originSupplier.media, (m) => {
                    media.push(m);
                })
            }
        }
        if(supplyChainStep.processorSupplier){
            if(supplyChainStep.processorSupplier?.media?.length !== 0){
                _.each(supplyChainStep.processorSupplier.media, (m) => {
                    media.push(m);
                })
            }
        }
        if(supplyChainStep.traderSupplier){
            if(supplyChainStep.traderSupplier?.media?.length !== 0){
                _.each(supplyChainStep.traderSupplier.media, (m) => {
                    media.push(m);
                })
            }
        }
        if(supplyChainStep.owner){
            if(supplyChainStep.owner?.media?.length !== 0){
                _.each(supplyChainStep.owner.media, (m) => {
                    media.push(m);
                })
            }
        }
        return media;
    }

    const getCombinedDocs = () => {
        let documents = []
        if(supplyChainStep.originSupplier){
            if(supplyChainStep.originSupplier?.documents?.length !== 0){
                _.each(supplyChainStep.originSupplier.documents, (m) => {
                    documents.push(m);
                })
            }
        }
        if(supplyChainStep.processorSupplier){
            if(supplyChainStep.processorSupplier?.documents?.length !== 0){
                _.each(supplyChainStep.processorSupplier.documents, (m) => {
                    documents.push(m);
                })
            }
        }
        if(supplyChainStep.traderSupplier){
            if(supplyChainStep.traderSupplier?.documents?.length !== 0){
                _.each(supplyChainStep.traderSupplier.documents, (m) => {
                    documents.push(m);
                })
            }
        }
        if(supplyChainStep.owner){
            if(supplyChainStep.owner?.documents?.length !== 0){
                _.each(supplyChainStep.owner.documents, (m) => {
                    documents.push(m);
                })
            }
        }
        return documents;
    }

    const getCombinedClaims = () => {
        let claims = []
        if(supplyChainStep.originSupplier){
            if(supplyChainStep.originSupplier?.claims?.length !== 0){
                _.each(supplyChainStep.originSupplier.claims, (m) => {
                    claims.push(m);
                })
            }
        }
        if(supplyChainStep.processorSupplier){
            if(supplyChainStep.processorSupplier?.claims?.length !== 0){
                _.each(supplyChainStep.processorSupplier.claims, (m) => {
                    claims.push(m);
                })
            }
        }
        if(supplyChainStep.traderSupplier){
            if(supplyChainStep.traderSupplier?.claims?.length !== 0){
                _.each(supplyChainStep.traderSupplier.claims, (m) => {
                    claims.push(m);
                })
            }
        }
        if(supplyChainStep.owner){
            if(supplyChainStep.owner?.claims?.length !== 0){
                _.each(supplyChainStep.owner.claims, (m) => {
                    claims.push(m);
                })
            }
        }
        return claims;
    }

    return(
        <>
            <div>
                <TabView className="tabview-header-icon">

                    <TabPanel header="Caratteristiche" leftIcon="pi pi-list" className='p-2'>
                        <div className='col col-12 grid p-0' style={{maxHeight: "60vh", overflow: 'scroll'}}>
                            {   
                                (props.suppliers.length !== 0 && _.contains([8,6,5,3], supplyChainStep.type.code)) &&
                                <div className='col col-12 lg:col-4'>
                                    <h5 className='mb-1'>Origin</h5>
                                    <InputText value={supplyChainStep.originSupplier?.name} className='flex-auto flex mr-3 w-full mb-1' disabled={true}/>
                                    <div className="p-inputgroup">
                                        <Dropdown className="w-full" value={supplyChainStep.originSupplier} label="name" options={suppliers} optionLabel="name" onChange={onOriginChange}/>
                                        <Button className="prisma-green-bg text-blue no-border" onClick={() => navigate("/supplierNewEdit")}>
                                            <FontAwesomeIcon icon="pen-to-square"/>
                                        </Button>
                                    </div>
                                </div>
                            }

                            {   
                                (props.suppliers.length !== 0 && _.contains([8,7,6,5,4,3], supplyChainStep.type.code)) &&
                                <div className='col col-12 lg:col-4'>
                                    <h5 className='mb-1'>Processed by</h5>
                                    <InputText value={supplyChainStep.processorSupplier?.name} className='flex-auto flex mr-3 w-full mb-1' disabled={true}/>
                                    <div className="p-inputgroup">
                                        <Dropdown className="w-full"  value={supplyChainStep.processorSupplier} label="name" options={suppliers} optionLabel="name" onChange={onProcessorChange}/>
                                        <Button className="prisma-green-bg text-blue no-border" onClick={() => navigate("/supplierNewEdit")}>
                                            <FontAwesomeIcon icon="pen-to-square"/>
                                        </Button>
                                    </div>
                                </div>
                            }

                            {              
                                (props.suppliers.length !== 0 && _.contains([8,7,6,5,4,3], supplyChainStep.type.code)) &&
                                <div className='col col-12 lg:col-4'>
                                    <h5 className='mb-1'>Traded by</h5>
                                    <InputText value={supplyChainStep.traderSupplier?.name} className='flex-auto flex mr-3 w-full mb-1' disabled={true}/>
                                    <div className="p-inputgroup">
                                        <Dropdown className="w-full"  value={supplyChainStep.traderSupplier} label="name" options={suppliers} optionLabel="name" onChange={onTraderChange}/>
                                        <Button className="prisma-green-bg text-blue no-border" onClick={() => navigate("/supplierNewEdit")}>
                                            <FontAwesomeIcon icon="pen-to-square"/>
                                        </Button>
                                    </div>
                                </div>
                            }

                            {              
                                (props.suppliers.length !== 0 && _.contains([1,2], supplyChainStep.type.code)) &&
                                <div className='col col-12 lg:col-4'>
                                    <h5 className='mb-1'>Owned by</h5>
                                    <InputText value={supplyChainStep.owner?.name} className='flex-auto flex mr-3 w-full mb-1' disabled={true}/>
                                    <div className="p-inputgroup">
                                        <Dropdown className="w-full"  value={supplyChainStep.owner} label="name" options={suppliers} optionLabel="name" onChange={onOwnerChange}/>
                                        <Button className="prisma-green-bg text-blue no-border" onClick={() => navigate("/supplierNewEdit")}>
                                            <FontAwesomeIcon icon="pen-to-square"/>
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='grid col col-12 p-0'>
                            <div className='col-12 lg:col-6'>
                                <h5 className='mb-1'>Nome *</h5>
                                <InputText type="text" className="block w-full" placeholder="Nome" value={supplyChainStep.name} onChange={onNameChange}/>
                            </div>

                            <div className='col-12 lg:col-6'>
                                <h5 className='mb-1'>Descrizione *</h5>
                                <InputTextarea rows={5} type="text" className="block w-full" placeholder="Descrizione" value={supplyChainStep.description} onChange={onDescriptionChange}/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel headerTemplate={mediaHeaderTemplate} className='p-2'>
                        <FileNewEdit 
                            file={getCombinedMedia()}
                            type="media"
                            writeEnabled={false}
                            onNameChange={() => {}}
                            onDescriptionChange={() => {}}
                            onShowChange={() => {}}
                            onUpload={() => {}}
                            onRemove={() => {}}
                            onFileLoaderActive={() => {}}
                        ></FileNewEdit>
                    </TabPanel>

                    <TabPanel headerTemplate={documentsHeaderTemplate} className='p-2'>
                        <FileNewEdit 
                            file={getCombinedDocs()}
                            type="document"
                            writeEnabled={false}
                            onNameChange={() => {}}
                            onDescriptionChange={() => {}}
                            onTypeChange={() => {}}
                            onExpirationDateChange={() => {}}
                            onUpload={() => {}}
                            onRemove={() => {}}
                            onFileLoaderActive={() => {}}
                        ></FileNewEdit>
                    </TabPanel>

                    <TabPanel headerTemplate={claimsHeaderTemplate} className='p-2'>
                        <FileNewEdit 
                            file={getCombinedClaims()}
                            type="claim"
                            writeEnabled={false}
                            onNameChange={() => {}}
                            onDescriptionChange={() => {}}
                            onTypeChange={() => {}}
                            onConfirmationDocChange={() => {}}
                            onUpload={() => {}}
                            onRemove={() => {}}
                            onFileLoaderActive={() => {}}
                        ></FileNewEdit>
                    </TabPanel>
                    
                </TabView>

            </div>

        </>
    )

};

export default SupplyChainStepForm


