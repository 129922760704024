import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Chips } from 'primereact/chips';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Divider } from 'primereact/divider';

import _ from 'underscore';

import { Component, useEffect, useState } from 'react';
import { getCollections, uploadCollection } from '../services/RemoteService';

const countries = [ 'IT', 'BR', 'CN', 'EG', 'FR', 'DE', 'IT', 'IN', 'JP', 'ES', 'US']

const careAndMantainances = [
    { label: 'Non usare candeggina', iconBlack: 'https://imageshack.com/i/pofCyFsgp', iconWhite: 'https://imageshack.com/i/pofCyFsgp' },
    { label: 'Lavare a freddo', iconBlack: 'https://imageshack.com/i/pnTiBLvsp', iconWhite: 'https://imageshack.com/i/pnTiBLvsp' },
    { label: 'Non usare centrifuga', iconBlack: 'https://imageshack.com/i/pnaWnTD4p', iconWhite: 'https://imageshack.com/i/pnaWnTD4p' },
    { label: 'Stirare a freddo', iconBlack: 'https://imageshack.com/i/poo5lXV2p', iconWhite: 'https://imageshack.com/i/poo5lXV2p' },
    { label: 'Lavare a secco', iconBlack: 'https://imageshack.com/i/pmUCEUM6p', iconWhite: 'https://imageshack.com/i/pmUCEUM6p' }
]

const GeneralInformationForm = (props) => {
    useEffect(() => {
        const fetchSupplier = async () => {
            const collections = await getCollections();
            if(collections.status === 200){
                setCollections(collections.response.data.collections);
            }
        };
    
        fetchSupplier();
    }, [])

    const [collections, setCollections] = useState()

    const [garments, setGarments] = useState([]);

    const [newCollection, setNewCollection] = useState('');
    const [newGarment, setNewGarment] = useState('');
    const [newGarmentPercentage, setNewGarmentPercentage] = useState(0);
    const [garmentSumError, setGarmentSumError] = useState(false);
    const [files, setFiles] = useState([]);

    const onSizesChange = (e) => {
        var uppercaseSizes = e.target.value.map(elem => {
            return elem.toUpperCase()
        })
        props.onGeneralInformationChange(uppercaseSizes, 'sizes')
    }

    /* COLLECTIONS */

    const addNewCollection = async () => {
        const collectionsUploadResponse = await uploadCollection(newCollection)
        if(collectionsUploadResponse.status === 200){
            const collections = await getCollections();
            if(collections.status === 200){
                setCollections(collections.response.data.collections);
            }
        }
    }

    /* MEDIA */
    const onMediaUpload = (e) => {
        props.onGeneralInformationChange(e.files, 'files');
    }

    /* GARMENTS */

    const onGarmentsChange = (e) => {
        props.onGeneralInformationChange(e.target.value, 'garments');
        setGarments(e.target.value);
    }

    const addNewGarment = () => {
        const garmentsCopy = [...garments];
        var totPercentages = _.reduce([newGarmentPercentage].concat(_.map(garments, function(garment){
            return garment.percentage
        })), function(currentTotal, percentage){ 
            return currentTotal + percentage; 
        }, 0);

        if(totPercentages <= 100){
            garmentsCopy.push({
                name: newGarment,
                percentage: newGarmentPercentage
            });

            setNewGarment('');
            setNewGarmentPercentage('');
            setGarmentSumError(false);

            setGarments(garmentsCopy);

            props.onGeneralInformationChange(garmentsCopy, 'garments')
        }else{
            setGarmentSumError(true);
        }
        
    }

    const generalInformations = props.generalInformations;
    
    const  garmentTemplate = (item) => {
        return (
            <div>
                <span>{item.percentage}% - {item.name} </span>
            </div>
        );
    }

    const careAndMantainanceTemplate = (option) => {
        return (
            <div className="flex align-content-center flex-wrap care-item-value">
                <img className='flex align-items-center mr-3' src={option.iconBlack} width="3%"/>
                <div className='flex align-items-center'>{option.label}</div>
            </div>
        );
    }

    const selectedCareAndMantainanceTemplate = (option) => {
        if (option) {
            return (
                <div className="flex align-content-center flex-wrap care-item-value">
                    <img className='flex align-items-center mr-3' src={option.iconBlack} width="7%"/>
                    <div className='flex align-items-center'>{option.label}</div>
                </div>
            );
        }
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }

    const mediaItemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <div className="px-3 py-2">{props.formatSize} </div>
                <Button type="button" icon="pi pi-times" className="prisma-green-bg text-blue no-border ml-auto" onClick={props.onRemove} />
            </div>
        )
    }

    const selectedCollectionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    }


    return(
        <>
            <div className="grid mx-0 px-4 col align-content-start justify-content-start col-12">
                {/**************** RIGA 1 ****************/}
                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Nome</h5>
                    <InputText type="text" className="block w-full" placeholder="Nome" value={generalInformations.name} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'name')}/>
                </div>

                <div className='col col-6 lg:col-4 pr-3'>
                    <Tooltip target=".target-icon-descr" style={{fontSize: '10px'}}/>
                    <h5 className='mb-1 flex justify-content-between flex-wrap'>Descrizione<i className="pi pi-info-circle target-icon-descr" data-pr-tooltip="Descrizione completa del prodotto e della sua storia" data-pr-position="top" ></i></h5>
                    <InputText type="text" className="block w-full" placeholder="Descrizione" value={generalInformations.description} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'description')}/>
                </div>

                    <div className='col col-6 lg:col-4 pr-3'>
                    <Tooltip target=".target-icon-descr-tech" style={{fontSize: '10px'}}/>
                    <h5 className='mb-1 flex justify-content-between flex-wrap'>Descrizione tecnica<i className="pi pi-info-circle target-icon-descr-tech" data-pr-tooltip="Breve descrizione del prodotto e delle sue caratteristiche" data-pr-position="top" ></i></h5>
                    <InputText type="text" className="block w-full" placeholder="Descrizione tecnica" value={generalInformations.technicalDescription} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'technicalDescription')}/>
                </div>

                {/**************** RIGA 2 ****************/}
                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Collezione</h5>
                    <div className='w-full m-0 p-0 grid'>
                        <InputText value={generalInformations.collection?.name} className='flex-auto flex mr-3' disabled={true}/>
                        <Dropdown className='col-6' value={generalInformations.collection} valueTemplate={selectedCollectionTemplate} options={collections} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'collection')} optionLabel="name" placeholder="Scegli una collezione" />  
                    </div>
                    <div className='flex mt-3'>
                        <InputText value={newCollection} onChange={(e) => setNewCollection(e.target.value)} className='flex-auto flex mr-3' placeholder="Aggiungi una nuova collezione"/>
                        <Button icon="pi pi-plus" className="flex-initial flex prisma-green-bg text-blue no-border-btn" onClick={addNewCollection} />
                    </div>
                </div>
                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Paesi di produzione</h5>
                    <MultiSelect className="w-full" value={generalInformations.countries} options={countries} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'countries')} placeholder="Scegli almeno un paese" display="chip" filter/>
                </div>
                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Prezzo</h5>
                    <InputNumber className="w-full" placeholder="Prezzo" value={generalInformations.price} onValueChange={(e) => props.onGeneralInformationChange(e.target.value, 'price')} mode="currency" currency="EUR" locale="de-DE" />
                </div>

                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Taglie</h5>
                    <Chips className='block w-full' value={generalInformations.sizes} onChange={(e) => onSizesChange(e)} aria-describedby="sizes-help"/>
                    <small id="sizes-help" className="block mt-1">Premere il tasto invio dopo ogni elemento per inserirlo</small>
                </div>
                
                {/**************** RIGA 3 ****************/}
                
                <div className='col col-6 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Colori *</h5>
                    <Chips className='block w-full' value={generalInformations.colors} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'colors')} aria-describedby="colors-help" />
                    <small id="colors-help" className="block mt-1">Premere il tasto invio dopo ogni elemento per inserirlo</small>
                </div>

                <div className='col col-12 lg:col-4 pr-3'>
                    <h5 className='mb-1'>Materiali</h5>
                    <Chips className={'block w-full ' + (garmentSumError ? 'p-invalid' : '')} value={generalInformations.garments} onChange={(e) => onGarmentsChange(e)} itemTemplate={garmentTemplate}/>
                    { garmentSumError && <small id="username2-help" className="p-error block">Il totale deve essere inferiore a 100</small>}
                    <div className="grid p-fluid formgrid mt-3">
                        <div className="field col-5">
                            <InputNumber prefix="%" placeholder="Percentuale" value={newGarmentPercentage} onValueChange={(e) => setNewGarmentPercentage(e.target.value)}/>
                        </div>
                        <div className="field col-5">
                            <InputText placeholder="Materiale" value={newGarment} onChange={(e) => setNewGarment(e.target.value)}/>
                        </div>
                        <div className="field col-2 flex justify-content-end">
                            <Button icon="pi pi-plus" className="prisma-green-bg text-blue no-border-btn" onClick={addNewGarment} />
                        </div>
                    </div>
                </div>
                
                {/**************** RIGA 4 ****************/}
                <div className='col col-12 lg:col-6 pr-3 multiselect-care'>
                    <h5 className='mb-1'>Cura e conservazione</h5>
                    <MultiSelect className="w-full multiselect-custom" value={generalInformations.careAndMantainance} options={careAndMantainances} onChange={(e) => props.onGeneralInformationChange(e.target.value, 'careAndMantainance')} display="chip" placeholder='Istruzioni per la cura'
                        itemTemplate={careAndMantainanceTemplate} selectedItemTemplate={selectedCareAndMantainanceTemplate}/>
                </div>

                {/* <div className='col col-8 pr-3 multiselect-care'>
                    <Tooltip target=".target-icon-descr" style={{fontSize: '10px'}}/>
                    <h5 className='mb-1 flex justify-content-between flex-wrap'>Immagini *<i className="pi pi-info-circle target-icon-descr" data-pr-tooltip="Per una resa migliore inserire immagini di uguale dimensione e con sfondo trasparente" data-pr-position="top" ></i></h5>
                    <FileUpload accept=".png, .jpeg, .jpg, .mp4" maxFileSize={10000000} multiple
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                        emptyTemplate={<p className="m-0">Trascina le immagini per caricarle.</p>} headerTemplate={headerTemplate} itemTemplate={mediaItemTemplate}
                        customUpload uploadHandler={onMediaUpload}/>
                </div>  */}

            </div>
        </>
    )
}

export default GeneralInformationForm

