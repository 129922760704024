import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { sha256 } from 'crypto-hash';

import { v4 as uuid } from 'uuid'

import _ from 'underscore';

import MediaDataView from './mediaDataView';
import { useRef, useState } from 'react';
import DocumentDataView from './documentDataView';
import ClaimDataView from './claimDataView';

 //------------------- Refactored code to upload to s3 8-11-2023
import { uploadToS3, uploadFileToDb, uploadMedia, uploadDocument, uploadClaim } from '../services/RemoteService';
 //------------------- Refactored code to upload to s3 


const FileNewEdit = (props) => {
    /* FILE PROPERTIES */
    const [fileName, setFileName] = useState('');
    const [fileDescription, setFileDescription] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileExpiration, setFileExpiration] = useState('');
    const [fileConfirmationDoc, setFileConfirmationDoc] = useState();

    const documentTypes = [
        {
            'label': 'Certificate',
            'value': "CERTIFICATE",
            'code': 1
        },{
            'label': 'Laboratory test result',
            'value': "LABORATORY",
            'code': 2
        },{
            'label': 'Formless',
            'value': "FORMLESS",
            'code': 3
        }
    ]

    const claimTypes = [
        { label: 'Sostenibilità', iconWhite: 'https://imageshack.com/i/pndbAkFAp', iconBlack: 'https://imageshack.com/i/pndbAkFAp' },
        { label: 'Qualità', iconWhite: 'https://imageshack.com/i/poZyVjz7p', iconBlack: 'https://imageshack.com/i/poZyVjz7p' },
        { label: 'Lavoro', iconWhite: 'https://imageshack.com/i/pn7rzFGrp', iconBlack: 'https://imageshack.com/i/pn7rzFGrp' }
    ]
    

    /* UPLOAD HEADER */
    const chooseOptions = {icon: 'pi pi-fw pi-images', label: "Seleziona", className: 'prisma-green-bg text-blue no-border'};
    const uploadOptions = {icon: 'pi pi-fw pi-save', label: "Carica", className: 'prisma-green-bg text-blue no-border'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', label: "Svuota", className: 'prisma-green-bg text-blue no-border'};

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }

    /* UPLOAD ITEM */
    const handleFileUpload = async (e) => {
        let accept = true;
        if(props.type === 'document' && (fileName === '' || fileDescription === '' || !fileType || !fileExpiration)){
            toast.current.show({ severity: 'error', summary: 'Errore', detail: 'Controlla di aver compilato tutti i campi.' });
            accept = false;
        }

        if(props.type === 'claim' && (fileName === '' || fileDescription === '' || !fileType || !fileConfirmationDoc)){
            toast.current.show({ severity: 'error', summary: 'Errore', detail: 'Controlla di aver compilato tutti i campi.' });
            accept = false;
        }

        if(accept){
            props.onFileLoaderActive(true);

            const fr = new FileReader();
    
            fr.onload = async () => {

                //------------------- Refactored code to upload to s3 8-11-2023

                const fileHash = await sha256(fr.result);
                const file = e.files[0];
                const fileMimeType = file.type;
                const buffer = await e.files[0].arrayBuffer();
                const awsfileName = fileHash + '.' + fileMimeType.split('/')[1];
                const s3Path = 'platform-media';
    
                const fileUrl = await uploadToS3({
                    mime_type: fileMimeType, 
                    file_name: awsfileName, 
                    s3_path: s3Path,
                    bucket_name: 'blockvision-public-media', 
                    buffer: buffer});

              
                if(fileUrl){
                    const fileId = uuid();
                    var fileUploadResponse = await uploadFileToDb(fileId, (e.files[0].type === 'application/pdf' ? 'DOC' : 'IMAGE'), e.files[0].name, fileHash, fileUrl);

                //------------------- End of refactored code

                    if(fileUploadResponse.status === 200){
                        if(props.type === 'media'){
                            var mediaUploadResponse = await uploadMedia(null, null, null, fileUploadResponse.response.data.createFile.id, e.files[0].name, "Image file", true);
    
                            if(mediaUploadResponse.status === 200){
                                props.onUpload(mediaUploadResponse.response.data.createMedia);
                            }
                        }
                        if(props.type === 'document'){
                            var documentUploadResponse = await uploadDocument(null, null, null, fileUploadResponse.response.data.createFile.id, fileName, fileDescription, "TO_BE_CONFIRMED", fileType, fileExpiration.toISOString());
    
                            if(documentUploadResponse.status === 200){
                                setFileName('');
                                setFileDescription('');
                                setFileType('');
                                setFileExpiration('');
                                props.onUpload(documentUploadResponse.response.data.createDocument);
                            }
                        }
                        if(props.type === 'claim'){
                            var claimUploadResponse = await uploadClaim(null, null, null, fileUploadResponse.response.data.createFile.id, fileName, fileDescription, fileType, fileConfirmationDoc.id, "TO_BE_CONFIRMED")
                            
                            if(claimUploadResponse.status === 200){
                                props.onUpload(claimUploadResponse.response.data.createClaim);
                            }
    
                        }
                    }
    
                }
            } 
    
            fr.readAsText(e.files[0]);
        }
    }

    const fileItemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>

                    {
                        props.type === 'media' &&
                        <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    }
                    {
                        props.type === 'document' &&
                        <embed type="application/pdf" src={file.objectURL} width="200" height="200" />
                    }
                    
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <div className="px-3 py-2">{props.formatSize} </div>
                <Button type="button" icon="pi pi-times" className="prisma-green-bg text-blue no-border ml-auto" onClick={props.onRemove} />
            </div>
        )
    }

    const confirmationDocOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const claimTypeOptionsTemplate = (option) => {
        return (
            <div className="flex align-content-center flex-wrap care-item-value">
                <img className='flex align-items-center mr-3' src={option.iconWhite} alt="icon" width="3%"/>
                <div className='flex align-items-center'>{option.label}</div>
            </div>
        );
    }

    const selectedClaimTypeTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-content-center flex-wrap care-item-value">
                    <img className='flex align-items-center mr-3' src={option.iconWhite} alt="icon" width="10%"/>
                    <div className='flex align-items-center'>{option.label}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const toast = useRef(null);

    return(
        <>
            <Toast ref={toast} />
            <div className={'col col-12 mt-5 ' + (props.type === 'document' || props.type === 'claim' ? 'lg:col-6' : '')}>
            {
                props.writeEnabled &&

                <div className=''>
                    <Tooltip target=".target-icon-descr" style={{fontSize: '10px'}}/>
                    <FileUpload accept={props.type === 'document' ? ".pdf" : ".png, .jpeg, .jpg, .mp4"} maxFileSize={10000000}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                        emptyTemplate={<p className="m-0">Trascina le immagini per caricarle.</p>} headerTemplate={headerTemplate} itemTemplate={fileItemTemplate}
                        customUpload uploadHandler={handleFileUpload}/>
                    <small>{props.instructions}</small>
                </div>
            }
            </div>

            <div className='col col-12 lg:col-6 grid mt-1 pr-0 mr-0'>
                {
                    props.writeEnabled &&

                    <>
                        {
                            (props.type === 'document' || props.type === 'claim') &&
                            <>
                                <div className='col col-6 pr-0'>
                                    <h5 className='mb-1'>Nome *</h5>
                                    <InputText type="text" className="w-full" value={fileName} onChange={(e) => setFileName(e.target.value)} placeholder="Nome"/>
                                </div>
                                <div className='col col-6 pr-0'>
                                    <h5 className='mb-1'>Descrizione</h5>
                                    <div className='w-full'>
                                        <InputTextarea className="w-full" rows="3" value={fileDescription} onChange={(e) => setFileDescription(e.target.value)} placeholder="Descrizione"/>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            props.type === 'document' &&
                            <>
                                <div className='col col-6 pr-5'>
                                    <h5 className='mb-1'>Tipologia *</h5>
                                    <Dropdown className="w-full"  value={fileType} options={documentTypes} optionLabel="label" onChange={(e) => setFileType(e.target.value)} placeholder="Scegli un tipo di documento"/>
                                </div>
                                <div className='col col-6 pr-5'>
                                    <h5 className='mb-1'>Scadenza documento *</h5>
                                    <div className='w-full'>
                                        <Calendar className='w-full' id="basic" value={fileExpiration} onChange={(e) => setFileExpiration(e.value)} />
                                    </div>
                                </div>
                            </>
                        }
                        {
                            props.type === 'claim' &&
                            <>
                                <div className='col col-6 pr-5'>
                                <h5 className='mb-1'>Tipologia *</h5>
                                    <Dropdown className="w-full"  value={fileType} options={claimTypes} optionLabel="label" onChange={(e) => setFileType(e.target.value)} filter showClear filterBy="label" placeholder="Scegli una tipologia di claim"
                                        valueTemplate={selectedClaimTypeTemplate} itemTemplate={claimTypeOptionsTemplate} />
                                </div>

                                <div className='col col-6 pr-5'>
                                    <h5 className='mb-1'>Confermato in *</h5>
                                    <Dropdown className="w-full"  value={fileConfirmationDoc} options={props.documents} optionLabel="name" onChange={(e) => setFileConfirmationDoc(e.target.value)} filter showClear filterBy="name" placeholder="Scegli un documento" itemTemplate={confirmationDocOptionTemplate} />
                                </div>
                            </>
                        }       

                        
                        
                    </>
                }
            </div>
            
            <Divider className='divider-blue ml-2' align="left" type="dashed">
            </Divider>

            <div className='col col-12'>
                <div>
                    {
                        props.file?.length === 0 &&
                        <h3 className='text-center w-full mt-5 pt-5'>Ancora nessun file caricato.</h3>
                    }

                    {
                        (props.file?.length > 0 && props.type === 'media') &&
                        <>
                            <MediaDataView rows={2} media={props.file} isList={false} onChangeMediaShow={props.onShowChange()} onChangeMediaName={props.onNameChange()} onChangeMediaDescription={props.onDescriptionChange()} onRemoveMedia={props.onRemove()}/>
                        </>
                    }

                    {
                        (props.file?.length > 0 && props.type === 'document') &&
                        <>
                            <DocumentDataView rows={2} documents={props.file} isList={false} documentTypes={documentTypes} onChangeDocumentName={props.onNameChange()} onChangeDocumentDescription={props.onDescriptionChange()} onChangeDocumentExpirationDate={props.onExpirationDateChange()} onChangeDocumentType={props.onTypeChange()} onRemoveDocument={props.onRemove()}/>
                        </>
                    }

                    {
                        (props.file?.length > 0 && props.type === 'claim') &&
                        <>
                            <ClaimDataView rows={2} claims={props.file} documents={props.documents} confirmationDocOptionTemplate={confirmationDocOptionTemplate} isList={false} claimTypes={claimTypes} onChangeClaimName={props.onNameChange()} onChangeClaimDescription={props.onDescriptionChange()} onChangeClaimType={props.onTypeChange()} onChangeClaimConfirmationDoc={props.onConfirmationDocChange()} onRemoveClaim={props.onRemove()}/>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default FileNewEdit;