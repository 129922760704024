import React, { Component, useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { login, submitEmailRequest } from "../services/RemoteService";
import { useNavigate } from "react-router-dom";

import jwt_decode from "jwt-decode";
 
const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('PRISMA_TOKEN')){
      navigate("/suppliers");
    }
  })

  const handleLogin = async () => {
    const loginToken = await login(userName, password);
    if(loginToken.status === 200){
      localStorage.setItem("PRISMA_TOKEN", loginToken.response.data.login)
      localStorage.setItem("PRISMA_CLIENT", jwt_decode(loginToken.response.data.login).id)
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }
  }

  const [displayMail, setDisplayMail] = useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
  const [email, setEmail] = useState('');
  const handleSubmitEmailRequest = async () => {
    const emailResponse = await submitEmailRequest(email);
    if(emailResponse.status === 200){
      setDisplaySuccessMessage(true);
    }
  }

  return (
    <>
      <div className='w-full' style={{height: '100vh', overflow: 'scroll', backgroundColor: '#002873'}}>
        <Dialog className="mx-2" header='Accedi alla piattaforma' visible={true} modal={true}
          draggable={false} resizable={false}>
            {
              !displayMail &&
              <div className='grid'>
                <div className='col col-12'>
                  <InputText autoFocus type="text" className="block w-full" placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)}/>
                </div>
                <div className='col col-12'>
                  <InputText autoFocus type="password" className="block w-full" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div className='col col-12 text-right'>
                  <Button icon="pi pi-user" label="Login" className="prisma-green-bg text-blue no-border" onClick={handleLogin} disabled={userName === "" || password === ""}/>
                </div>
              </div>
            }

            {
              displayMail &&
              <div className='grid'>

                {
                  displaySuccessMessage &&
                  <div className="col col-12 w-full">
                    <Message className="block w-full" severity="success" text="La tua richiesta è stata inviata, ti ricontatteremo al più presto!" />
                  </div>
                }
                <div className='col col-12'>
                  <InputText autoFocus type="text" className="block w-full" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className='col col-12 text-right'>
                  <Button icon="pi pi-send" label="Invia" className="prisma-green-bg text-blue no-border" onClick={handleSubmitEmailRequest} disabled={email === ""}/>
                </div>
              </div>
            }

            <small>Non sei ancora registrato ma vorresti provare il nostro servizio?<br/> <span className="font-bold underline cursor-pointer" onClick={() => setDisplayMail(true)}>Clicca qui</span> e inserisci la tua mail, saremo felici di contattarti!</small>
        </Dialog>
      </div>
    </>
  );
}
 
export default Login;