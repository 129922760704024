import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Image } from 'primereact/image';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

import { classNames } from 'primereact/utils';

import { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import _ from 'underscore';

import { generateProductUnity } from '../services/StateService';
import { uploadProduct, deleteProduct, getFreeNfcTags, createProductUnity, createProductUnityBulk} from '../services/RemoteService';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProductDetail from './productDetail';
import ProductItemDataView from './productItemDataView';
import CreateProductUnityForm from './createProductUnityForm'


const ProductsList = (props) => {
    const products = props.products;

    const header = (
        <div className="table-header">
            <i className="pi pi-list"></i> Lista
        </div>
    );

    const paginatorTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Precedente</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Successiva</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        'CurrentPageReport': (options) => {
            return (
                <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    Vai a <InputText size="2" className="ml-1" value={props.currentPage} tooltip={props.pageInputTooltip}
                        onKeyDown={(e) => props.onPageInputKeyDown(e, options)} onChange={props.onPageInputChange}/>
                </span>
            )
        }
    };

    const descriptionTemplate = (rowData) => {
        return(
            <div style={{maxHeight: '70px', overflow: 'scroll'}}>
                {rowData.description}
            </div>
        )
    }

    const nameTemplate = (rowData) => {
        return (
            <div className='flex align-items-center'>
                <div className='flex align-items-center'>
                    <Image src={"https://imageshack.com/i/poR1pn6Pj"} className="flex" style={{height: '75px', width: "auto"}} preview/>
                    <div className='ml-3'>
                        <div>{rowData.name}</div>
                        {
                            !_.isEmpty(rowData.collection) &&
                            <div>({rowData.collection.name})</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const priceTemplate = (rowData) => {
        return(
            <span>{rowData.price} €</span>
        )
    }

    const countriesTemplate = (data) => {
        const content = [];

        for(var i = 0; i<data.countries.length; i++){
            const country = data.countries[i];
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{country}</div>
                </span>
            )
        }
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const garmentsTemplate = (data) => {
        const content = [];

        _.each(data.garments, (g) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{g.percentage+'%'}<div>&nbsp;</div>{g.name}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const colorsTemplate = (data) => {
        const content = [];

        _.each(data.colors, (c) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{c}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const sizesTemplate = (data) => {
        const content = [];

        _.each(data.sizes, (s) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{s}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const distanceTemplate = (rowData) => {
        let totDist = 0;

        _.each(rowData.supplyChainLinks, (link) => {
            if(_.has(link, 'distance')){
                totDist+=link.distance;
            }
        })

        return(
            <span>{totDist} km</span>
        )
    }

    const [showRowDetail, setShowRowDetail] = useState(false);
    const [selectedRow, setSelectedRow] = useState();

    const detailTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center'>
                <Button className="p-button-rounded p-button-text" style={{color: 'black', height: "38px", width: '38px'}} onClick={() => {
                        setSelectedRow(rowData);
                        setShowRowDetail(true);
                    }
                }>
                    <FontAwesomeIcon icon="search" />
                </Button>
            </div>
        )
    }



    const actionsTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center'>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text mr-1" style={{color: 'black'}} onClick={() => navigate("/productNewEdit/"+rowData.id)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text mr-1" style={{color: 'black'}} onClick={() => deleteCurrentProduct(rowData.id)} />
                <Button className="p-button-rounded p-button-text" style={{color: 'black'}} onClick={() => generateProductUnity(rowData.id)} disabled={true}>
                    <FontAwesomeIcon icon="passport" />
                </Button>
            </div>
        )
    }

    const deleteCurrentProduct = (id) => {
        if(id){
            confirmDialog({
                message: 'Sicuro di voler cancellare il prodotto? L\'operazione non sarà reversibile',
                header: 'Cancella prodotto',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: ()  => {
                    deleteProduct(id);
                    window.location.reload(false);
                },
                reject: () => function(){}
            });
            
        }
    }

    const navigate = useNavigate();

    const productVisualizationOption = [
        { label: 'tabella', icon: 'pi pi-list', code: 1},
        { label: 'box', icon: 'pi pi-th-large', code: 2 }
    ]

    const [selectedProductVisualizationOption, setSelectedProductVisualizationOption] = useState(productVisualizationOption[1]);

    const setSelectedProductVisualizationOptionControl = (value) => {
        if(value!==null){
            setSelectedProductVisualizationOption(value);
        }
    }
    
    const claimVisualizationOptionTemplate = (option) => {
        return <i className={option.icon}></i>;
    }

    const createBlankProduct = async () => {
        const blankProd = {
            "name": "",
            "description" : "",
            "technicalDescription": "",
            "collectionId": null,
            "countries": [],
            "price": null,
            "sizes": [],
            "colors": [],
            "garments": [],
            "careAndMantainance" : [],
            "media": [],
            "supplyChainSteps": [],
            "supplyChainLinks": []
        }

        const blankUploadResponse = await uploadProduct(blankProd);
        if(blankUploadResponse.status === 200){
            navigate("/productNewEdit/" + blankUploadResponse.response.data.createProduct.id)
        }
    }

    const [currentProduct, setCurrentProduct] = useState('')

    const [showGenerateProductUnityModal, setShowGenerateProductUnityModal] = useState(false);
    const [nfcTags, setNfcTags] = useState([]);
    const [limit, setLimit] = useState(10);
    const [tagIdFilter, setTagIdFilter] = useState("")
    const [showLimitMessage, setShowLimitMessage] = useState(false);
    const [tagLoading, setTagLoading] = useState(false);

    const getTags = (product) => {
        setCurrentProduct(product);
        console.log(product);
        setTagLoading(true);
        const fetchTags = async () => {
            const nfcTagsResponse = await getFreeNfcTags(limit, tagIdFilter);
            if(nfcTagsResponse.status === 200){
                setNfcTags(nfcTagsResponse.response.data.nfcTags);
                if(!showGenerateProductUnityModal){
                    setShowGenerateProductUnityModal(true);
                }
                setTagLoading(false);
            }
            if(limit>nfcTagsResponse.response.data.nfcTags.length){
                setShowLimitMessage(true)
            }else{
                setShowLimitMessage(false)
            }
        }

        setTimeout(() => {
            fetchTags()
        }, 500);
    }

    const handleGenerateQrCode = async () => {
        const productUnity = {
            "description": currentProduct.name,
            "name": currentProduct.description,
            "productId": currentProduct.id,
            "metadata": null,
            "nfcTagId": null,
        }

        const productUnityResponse = await createProductUnity(productUnity);
        if(productUnityResponse.status === 200){
            setShowGenerateProductUnityModal(false);
            toast.current.show({ severity: 'info', summary: 'Nuovo Qr Code generato', detail: 'Il Qr Code generato è visibile all\'interno del dettaglio prodotto.' });
        }
    }

    const registerProductUnityBulk = async (productUnities) => {
        const input = {
            "description": currentProduct.description,
            "name": currentProduct.name,
            "productId": currentProduct.id,
            "unities": _.map(productUnities, (pu) => {
                return pu.id
            })
          }
        const productUnitiesResponse = await createProductUnityBulk(input);
        if(productUnitiesResponse.status === 200){
            setShowGenerateProductUnityModal(false);
            toast.current.show({ severity: 'info', summary: 'Passaporti generati', detail: 'La lista delle unità di prodotto con relativi Qr Code è visibile all\'interno del dettaglio prodotto' });
        }
    }

    const toast = useRef(null);

    return(
        <>
            <Toast ref={toast} />

            <ConfirmDialog></ConfirmDialog>

            <div className='flex mt-5 justify-content-between'>
                <div >
                    <Button icon="pi pi-plus" label="Crea nuovo" className="prisma-green-bg text-blue no-border mr-2" onClick={() => createBlankProduct()}/>
                </div>
                <div className='flex'>
                    <SelectButton value={selectedProductVisualizationOption} options={productVisualizationOption} onChange={(e) => setSelectedProductVisualizationOptionControl(e.value)} itemTemplate={claimVisualizationOptionTemplate} optionLabel="value" />
                </div>
            </div>

            <Divider className='divider-blue'></Divider>

            {
                selectedProductVisualizationOption.code === 1 && 

                <DataTable resizableColumns columnResizeMode="expand" showGridlines className='mt-5' value={products} 
                    stripedRows responsiveLayout="scroll" paginator paginatorTemplate={paginatorTemplate} first={props.startRecord} 
                    rows={props.recordsPerPage} onPage={props.onPageChange}
                >

                    <Column bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={detailTemplate} />
                    <Column header="Nome" body={nameTemplate}></Column>
                    <Column header="Descrizione" body={descriptionTemplate}></Column>
                    <Column header="Prodotta in" body={countriesTemplate}></Column>
                    <Column header="Prezzo" body={priceTemplate}></Column>
                    <Column header="Colori" body={colorsTemplate}></Column>
                    <Column header="Taglie" body={sizesTemplate}></Column>
                    <Column header="Materiali" body={garmentsTemplate}></Column>
                    <Column header="Step" field="supplyChainSteps.length"></Column>
                    <Column header="Trasporti" field="supplyChainLinks.length"></Column>
                    <Column header="Km totali" body={distanceTemplate}></Column>

                    {/* <Column header="Ragione sociale" body={companyNameTemplate}></Column> */}
                    <Column bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionsTemplate} />
                </DataTable>
            }

            {
                selectedProductVisualizationOption.code === 2 && 
                
                <ProductItemDataView products={products} onDeleteCurrentProduct={deleteCurrentProduct} onOpenProductUnityModal={getTags} onSetSelectedRow={setSelectedRow} onSetShowRowDetail={setShowRowDetail} onTotalDistance={distanceTemplate}/>
            }

            <Dialog className='product-detail-modal' header={'Dettaglio prodotto: ' + (selectedRow ? selectedRow.name : "")} visible={showRowDetail} modal={true} position={'right'}
                draggable={false} resizable={true} onHide={() => setShowRowDetail(false)}>
                    <ProductDetail product={selectedRow}/>
            </Dialog>

            <Dialog className='assign-nfc-modal' header='Crea unità di prodotto' visible={showGenerateProductUnityModal} modal={true}
                draggable={false} resizable={true} onHide={() => setShowGenerateProductUnityModal(false)}>
                {
                    tagLoading &&
                    <div className='px-3 col-12 grid align-content-center justify-content-center'>
                        <div className='col-9'>
                            <h3>Sto scaricando i dati, attendere qualche secondo.</h3>
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        </div>
                    </div>
                }
                {
                    !tagLoading &&
                    <CreateProductUnityForm nfcTags={nfcTags} product={currentProduct} limit={limit} onLimitChange={(e) => setLimit(e.target.value)} 
                    onRefreshTags={() => getTags(currentProduct)} onGenerateQrCode={() => handleGenerateQrCode()}
                    tagIdFilter={tagIdFilter} onTagIdFilterChange={(e) => setTagIdFilter(e.target.value)}
                    onRegisterProductUnityBulk={registerProductUnityBulk} showLimitMessage={showLimitMessage}></CreateProductUnityForm>
                }
            </Dialog>
        </>
    )
}

export default ProductsList;