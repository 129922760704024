import React, { useEffect } from 'react';
import './index.css';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLightbulb, faTruckPlane, faIndustry, faGripLinesVertical, faMapPin, faAngleRight, faAngleLeft, faCalendarDays, faArrowUpRightFromSquare, faRuler, 
  faArrowRightLong, faInfoCircle, faPerson, faSeedling, faRightLeft, faEye, faEyeSlash, faEnvelope, faPhone, faGlobe, faRoad, faCity, faPenToSquare,
  faPhotoFilm, faFileAlt, faFileImage, faRoute, faFileImport, faPersonDress, faBuilding, faAngleUp, faAngleDown, faPassport,
  faBars, faSearch, faTriangleExclamation, faCircleQuestion, faTimes, faQrcode, faTag, faWandMagicSparkles, faCircleExclamation, faCircleCheck, faArrowDown} from '@fortawesome/free-solid-svg-icons'

import Menu from './components/menu';
import PageHeader from './components/pageHeader';

import Main from './routes/main'
import ProductNewEdit from './routes/productNewEdit'
import SupplierNewEdit from './routes/supplierNewEdit'
import Suppliers from './routes/suppliers'
import Products from './routes/products'
import Analytics from './routes/analytics'
import Login from './routes/login'
import DigitalProductPassport from './routes/digitalProductPassport'
import { BrowserRouter, createBrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

import WebFont from 'webfontloader';

library.add(faLightbulb,faTruckPlane,faIndustry,faGripLinesVertical,faMapPin,faAngleRight,faAngleLeft,faCalendarDays,faArrowUpRightFromSquare,faRuler,
  faInfoCircle,faPerson,faSeedling,faRightLeft,faEye,faEyeSlash,faEnvelope,faPhone,faGlobe,faRoad,faCity,faPenToSquare,faPhotoFilm,faFileAlt,faFileImage,
  faTriangleExclamation,faRoute,faFileImport,faPersonDress,faBuilding,faAngleUp,faAngleDown,faPassport,faBars,faSearch,faCircleQuestion,faTimes, faQrcode,
  faTag,faWandMagicSparkles,faCircleExclamation,faCircleCheck, faArrowDown, faArrowRightLong)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/productNewEdit",
    element: <ProductNewEdit />,
  },
  {
    path: "/productNewEdit/:id",
    element: <ProductNewEdit />,
  },
  {
    path: "/supplierNewEdit/:id",
    element: <SupplierNewEdit />,
  },
  {
    path: "/supplierNewEdit",
    element: <SupplierNewEdit />,
  },
  {
    path: "/suppliers",
    element: <Suppliers />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/digitalProductPassport/:id",
    element: <DigitalProductPassport />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);



function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter Tight', 'Chilanka']
      }
    });
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route exact path="/digitalProductPassport/:id" element={<DigitalProductPassport />}/>
            <Route exact path="/login" element={<Login />}/>
            <Route exact path="*" element={<DefaultContainer />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

const DefaultContainer = () => (
  <React.Fragment>
    <div className="grid mx-0">
      <div className="col col-12 lg:col-2 my-0 pb-0 mx-0 px-0">
        <Menu />
      </div>
      
      <div className="col col-12 lg:col-10 my-0 py-0 mx-0 px-0">
        <div className="col col-12 md:col-10 w-full m-0 p-0">
          <PageHeader />
        </div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/products" element={<Products />} />
          <Route path="/productNewEdit" element={<ProductNewEdit />} />
          <Route path="/productNewEdit/:id" element={<ProductNewEdit />} />
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/supplierNewEdit" element={<SupplierNewEdit />} />
          <Route path="/supplierNewEdit/:id" element={<SupplierNewEdit />} />
          <Route path="/analytics" element={<Analytics />} />
        </Routes>
      </div>
    </div>
  </React.Fragment>
)
