import SuppliersList from '../components/suppliersList';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import React, { useEffect } from 'react';
import { useState } from 'react';
import _ from 'underscore';
import { supplierModelToView } from '../services/DbUtils';
import { useNavigate } from 'react-router-dom';
import { getSuppliers } from '../services/RemoteService';

const Suppliers = () => {
    const auth = localStorage.getItem('PRISMA_TOKEN')
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!auth) {
            navigate("/login");
        }    
    }, [])
    
    const [suppliers, setSuppliers] = useState();
    
    useEffect(() => {
        const fetchSuppliers = async () => {
            const suppliersResponse = await getSuppliers(null);
            if(suppliersResponse.status === 200){

                var viewSuppliers = _.map(suppliersResponse.response.data.suppliers, (supp) => {
                    console.log("Supplier: ", supp);
                    return supplierModelToView(supp);
                })
                
                setSuppliers(viewSuppliers);
            }
        };
    
        fetchSuppliers();
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const [startRecord, setStartRecord] = useState(0);
    const [recordsPerPage, setRecordPerPage] = useState(5);
    const [pageInputTooltip, setPageInputTooltip] = useState('Premi \'Invio\' per passare alla pagina selezionata.');

    const handlePageInputKeyDown = (event, options) => {
        if (event.key === 'Enter') {
            const page = parseInt(currentPage);
            if (page < 1 || page > options.totalPages) {
                setPageInputTooltip('Il valore deve essere compreso tra 1 e '+options.totalPages+'.');
            }
            else {
                const first = currentPage ? options.rows * (page - 1) : 0;

                setStartRecord(first);
                setPageInputTooltip('Premi \'Invio\' per passare alla pagina selezionata.');
            }
        }
    }

    const handlePageChange = (event) => {
        setStartRecord(event.first);
        setRecordPerPage(event.rows);
        setCurrentPage(event.page + 1);
    }

    const handlePageInputChange = (event) => {
        setCurrentPage(event.target.value);
    }

    return(
        <>
            <div className='w-full' style={{height: '90vh', overflow: 'scroll'}}>

                <div className='px-5' style={{overflow: "scroll"}}>
                    <SuppliersList suppliers={suppliers} currentPage={currentPage} recordsPerPage={recordsPerPage} startRecord={startRecord} allowExpansion={true}
                        onPageInputKeyDown={handlePageInputKeyDown} onPageChange={handlePageChange} onPageInputChange={handlePageInputChange} pageInputTooltip={pageInputTooltip}
                    />
                </div>

            </div>
        </>
    )
}

export default Suppliers