import _ from 'underscore';

export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2){
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}

export function deg2rad(deg){
    return deg * (Math.PI/180)
}

export function rad2deg(rad){
    return rad * (180/Math.PI)
}

export function getMidPoint(lon1, lat1 , lon2, lat2){
    const dLon = deg2rad(lon2 - lon1);

    //convert to radians
    lat1 = deg2rad(lat1);
    lat2 = deg2rad(lat2);
    lon1 = deg2rad(lon1);

    const Bx = Math.cos(lat2) * Math.cos(dLon);
    const By = Math.cos(lat2) * Math.sin(dLon);
    const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By));
    const lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx);

    //print out in degrees
    return [rad2deg(lon3), rad2deg(lat3)];
}

export function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export function getStepClaimsMediaDocs(step){
    const claims = [];
    const media = [];
    const docs = [];
    _.each(step.claims, (claim) => {
        claims.push(claim)
    })
    _.each(step.media, (m) => {
        media.push(m)
    })
    _.each(step.documents, (doc) => {
        docs.push(doc)
    })

    if(!_.isEmpty(step.originSupplier)){
        _.each(step.originSupplier.claims, (claim) => {
            if(_.filter(claims, (c) => {
                return c.id === claim.id;
            }).length === 0){
                claims.push(claim)
            }
        })
        _.each(step.originSupplier.media, (m) => {
            if(_.filter(media, (m) => {
                return m.id === media.id;
            }).length === 0){
                media.push(m)
            }
        })
        _.each(step.originSupplier.documents, (doc) => {
            if(_.filter(docs, (d) => {
                return d.id === doc.id;
            }).length === 0){
                docs.push(doc)
            }
        })
    }
    if(!_.isEmpty(step.processorSupplier)){
        _.each(step.processorSupplier.claims, (claim) => {
            if(_.filter(claims, (c) => {
                return c.id === claim.id;
            }).length === 0){
                claims.push(claim)
            }
        })
        _.each(step.processorSupplier.media, (m) => {
            if(_.filter(media, (m) => {
                return m.id === media.id;
            }).length === 0){
                media.push(m)
            }
        })
        _.each(step.processorSupplier.documents, (doc) => {
            if(_.filter(docs, (d) => {
                return d.id === doc.id;
            }).length === 0){
                docs.push(doc)
            }
        })
    }
    if(!_.isEmpty(step.traderSupplier)){
        _.each(step.traderSupplier.claims, (claim) => {
            if(_.filter(claims, (c) => {
                return c.id === claim.id;
            }).length === 0){
                claims.push(claim)
            }
        })
        _.each(step.traderSupplier.media, (m) => {
            if(_.filter(media, (m) => {
                return m.id === media.id;
            }).length === 0){
                media.push(m)
            }
        })
        _.each(step.traderSupplier.documents, (doc) => {
            if(_.filter(docs, (d) => {
                return d.id === doc.id;
            }).length === 0){
                docs.push(doc)
            }
        })
    }
    if(!_.isEmpty(step.owner)){
        _.each(step.owner.claims, (claim) => {
            if(_.filter(claims, (c) => {
                return c.id === claim.id;
            }).length === 0){
                claims.push(claim)
            }
        })
        _.each(step.owner.media, (m) => {
            if(_.filter(media, (m) => {
                return m.id === media.id;
            }).length === 0){
                media.push(m)
            }
        })
        _.each(step.owner.documents, (doc) => {
            if(_.filter(docs, (d) => {
                return d.id === doc.id;
            }).length === 0){
                docs.push(doc)
            }
        })
    }
    return [claims, media, docs];
}