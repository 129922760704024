import _ from 'underscore'

import { getSupplyChainStep, getSupplierByID, saveFile, getFile } from './StateService'

import {v4 as uuid} from 'uuid'

/* export function productViewToModel(info, steps, links){
    const modelProduct = {};
    
    if(!_.isEmpty(info)){
        if(_.has(info, 'id')){
            modelProduct.id = info.id
        }
        modelProduct.name = info.name;
        modelProduct.description = info.description;
        modelProduct.technicalDescription = info.technicalDescription;
        modelProduct.collection = info.collection;
        modelProduct.countries = info.countries;
        modelProduct.price = info.price;
        modelProduct.sizes = info.sizes;
        modelProduct.colors = info.colors;
        modelProduct.garments = info.garments;
        modelProduct.careAndMantainances = info.careAndMantainances;
        modelProduct.files = info.files;
        modelProduct.image = info.image;
    };

    modelProduct.supplyChainSteps = [];
    if(steps.length !== 0){
        _.each(steps, (s) => {
            modelProduct.supplyChainSteps.push(supplyChainStepViewToModel(s));
        })
    }

    if(links.length !== 0){
        modelProduct.supplyChainLinks = links
    }else{
        modelProduct.supplyChainLinks = [];
    }

    return modelProduct;
} */

/* export function productModelToView(modelProduct){
    const viewProduct = {};
    
    if(!_.isEmpty(modelProduct)){
        viewProduct.generalInformations = {
            'id': modelProduct.id,
            'name': modelProduct.name,
            'description' : modelProduct.description,
            'technicalDescription': modelProduct.technicalDescription,
            'collection': modelProduct.collection,
            'countries': modelProduct.countries,
            'price': modelProduct.price,
            'sizes': modelProduct.sizes,
            'colors': modelProduct.colors,
            'garments': modelProduct.garments,
            'careAndMantainances' : modelProduct.careAndMantainances,
            'files': modelProduct.files,
            'image': modelProduct.image
        }

        if(modelProduct.supplyChainSteps){
            viewProduct.supplyChainSteps = [];
            _.each(modelProduct.supplyChainSteps, (stepId) => {
                const modelStep = getSupplyChainStep(stepId);
                const step = supplyChainStepModelToView(modelStep);
                viewProduct.supplyChainSteps.push(step);
            })
        }else{
            viewProduct.supplyChainSteps = [];
        }

        if(modelProduct.supplyChainLinks){
            viewProduct.supplyChainLinks = modelProduct.supplyChainLinks;
        }else{
            viewProduct.supplyChainLinks = [];
        }
    };

    return viewProduct;
} */

export function supplyChainStepViewToModel(viewStep){
    var modelStep = viewStep;

    if(!_.isEmpty(modelStep.originSupplier)){
        modelStep.originSupplierId = modelStep.originSupplier.id;
    }else{
        modelStep.originSupplierId = null
    }

    if(!_.isEmpty(modelStep.processorSupplier)){
        modelStep.processorSupplierId = modelStep.processorSupplier.id;
    }else{
        modelStep.processorSupplierId = null
    }

    if(!_.isEmpty(modelStep.traderSupplier)){
        modelStep.traderSupplierId = modelStep.traderSupplier.id;
    }else{
        modelStep.traderSupplierId = null
    }

    if(!_.isEmpty(modelStep.owner)){
        modelStep.ownerId = modelStep.owner.id;
    }else{
        modelStep.ownerId = null
    }

    delete modelStep.owner;
    delete modelStep.originSupplier;
    delete modelStep.processorSupplier;
    delete modelStep.traderSupplier;
    delete modelStep.id;

    return modelStep;
}

export function supplyChainStepModelToView(modelStep){
    var viewStep = modelStep;

    if(!_.isEmpty(modelStep.originSupplier)){
        viewStep.originSupplier = getSupplierByID(modelStep.originSupplier);
    }

    if(!_.isEmpty(modelStep.processorSupplier)){
        viewStep.processorSupplier = getSupplierByID(modelStep.processorSupplier);
    }

    if(!_.isEmpty(modelStep.traderSupplier)){
        viewStep.traderSupplier = getSupplierByID(modelStep.traderSupplier);
    }

    if(!_.isEmpty(modelStep.owner)){
        viewStep.owner = getSupplierByID(modelStep.owner);
    }

    return viewStep;
}

export function supplyChainLinkViewToModel(viewLink){
    
    const modelLink = viewLink;

    if(!_.isEmpty(modelLink.fromSupplier)){
        modelLink.fromSupplierId = modelLink.fromSupplier.id;
    }else{
        modelLink.fromSupplierId = null
    }

    if(!_.isEmpty(modelLink.toSupplier)){
        modelLink.toSupplierId = modelLink.toSupplier.id;
    }else{
        modelLink.toSupplierId = null
    }

    delete modelLink.fromSupplier;
    delete modelLink.toSupplier;
    delete modelLink.id;

    return modelLink
}

/*********** SUPPLIER ***********/
export function supplierViewToModel(viewValue){
    var modelValue = {
        "idClient": "",
        "idUser": "",
        "name": viewValue.name,
        "displayName": viewValue.displayName,
        "showDisplayName": viewValue.showDisplayName,
        "description": viewValue.description,
        "company": {
            "name": viewValue.companyName,
            "size": viewValue.companySize === "Small Business" ? "SMALL_BUSINESS" : (viewValue.companySize === "Mid-Market Enterpries" ? "MID_MARKET" : (viewValue.companySize === "Large Enterprise" ? "LARGE" : null)),
            "vatNumber": viewValue.vatNumber
        },
        "address": {
            "address": viewValue.address,
            "city": viewValue.city,
            "placeOnEarth": viewValue.placeOnEarth,
            "province": viewValue.province,
            "showAddress": viewValue.showAddress,
            "showCity": viewValue.showCity,
            "showProvince": viewValue.showProvince
        },
        "mailContacts": {
            "isDefault": true,
            "order": 1,
            "type": "MAIN",
            "value": viewValue.email
        },
        "phoneContacts": {
            "isDefault": true,
            "order": 1,
            "type": "MAIN",
            "value": viewValue.phoneNumber
        },
        "webContacts": {
            "isDefault": true,
            "order": 1,
            "type": "WEBSITE",
            "value": viewValue.website
        },
        "productionInfo": {
            "minOrderQuantity": parseInt(viewValue.minOrderQuantity),
            "minOrderQuantityPerStyle": parseInt(viewValue.minOrderQuantityPerStyle),
            "numberOfFemaleEmployees": parseInt(viewValue.numberOfFemaleEmployees),
            "numberOfMaleEmployees": parseInt(viewValue.numberOfMaleEmployees),
            "numberOfPermanentEmployees": parseInt(viewValue.numberOfPermanentEmployees),
            "standardDelayTimeDays": parseInt(viewValue.standardDelayTimeDays),
            "totProductionQuantity": parseInt(viewValue.totProductionQuantity)
        },
        "supplierGroup": null,
        "main": true,
        "imageId": viewValue.image?.id,
        "media": _.map(viewValue.media, (m) => {
            return m.id;
        }),
        "documents":  _.map(viewValue.documents, (d) => {
            return d.id;
        }),
        "claims":  _.map(viewValue.claims, (c) => {
            return c.id;
        })
    }
    return modelValue;
}

export function supplierModelToView(modelValue) {
    var viewValue = {
        "id": modelValue.id,
        "idClient": modelValue.idClient,
        "idUser": modelValue.idUser,
        "name": modelValue.name,
        "displayName": modelValue.displayName,
        "showDisplayName": modelValue.showDisplayName,
        "companyName": modelValue.company.name,
        "vatNumber": modelValue.company.vatNumber,
        "description": modelValue.description,
        "city": modelValue.address.city,
        "showCity": modelValue.address.showCity,
        "address": modelValue.address.address,
        "showAddress": modelValue.address.showAddress,
        "province": modelValue.address.province,
        "showProvince": modelValue.address.showProvince,
        "placeOnEarth": modelValue.address.placeOnEarth,
        "showPlaceOnEarth": true,
        "email": modelValue.mailContacts.value,
        "phoneNumber": modelValue.phoneContacts.value,
        "website": modelValue.webContacts.value,
        "companySize": modelValue.company.size === "SMALL_BUSINESS" ? "Small Business" : (modelValue.company.size === "MID_MARKET" ? "Mid-Market Enterpries" : (modelValue.company.size === "LARGE" ? "Large Enterprise" : null)),
        "totProductionQuantity": modelValue.productionInfo.totProductionQuantity,
        "minOrderQuantity": modelValue.productionInfo.minOrderQuantity,
        "minOrderQuantityPerStyle": modelValue.productionInfo.minOrderQuantityPerStyle,
        "standardDelayTimeDays": modelValue.productionInfo.standardDelayTimeDays,
        "numberOfPermanentEmployees": modelValue.productionInfo.numberOfPermanentEmployees,
        "numberOfMaleEmployees": modelValue.productionInfo.numberOfMaleEmployees,
        "numberOfFemaleEmployees": modelValue.productionInfo.numberOfFemaleEmployees,
        "media": modelValue.media,
        "documents": modelValue.documents,
        "claims": modelValue.claims,
        "image": modelValue.image,
        "imageId": modelValue.imageId
    }

    const suppMaxScore = 17;

    viewValue.toDo = [];
    viewValue.score = 0;

    if(modelValue.name !== ""){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi un nome");
    }

    if(modelValue.description !== ""){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi una descrizione");
    }

    if(modelValue.city !== ""){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi la città");
    }

    if(modelValue.address !== ""){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi via e numero civico");
    }

    if(modelValue.companySize !== ""){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi la dimensione dell'azienda");
    }

    if(modelValue.numberOfPermanentEmployees !== 0){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi il numero dei dipendenti totali");
    }

    if(modelValue.numberOfMaleEmployees !== 0){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi il numero dei dipendenti uomini");
    }

    if(modelValue.numberOfFemaleEmployees !== 0){
        viewValue.score+=1;
    }else{
        viewValue.toDo.push("Aggiungi il numero delle dipendenti donne");
    }

    if(modelValue.media.length !== 0){
        viewValue.score+=3;
    }else{
        viewValue.toDo.push("Aggiungi almeno un media");
    }

    if(modelValue.documents.length !== 0){
        viewValue.score+=3;
    }else{
        viewValue.toDo.push("Aggiungi almeno un documento");
    }

    const allClaimsHaveConfirmation = _.filter(modelValue.claims, (claim) => {
        return _.isEmpty(claim.confirmationDoc);
    }).length === 0;

    if(modelValue.claims.length !== 0 && allClaimsHaveConfirmation){
        viewValue.score+=3;
    }else if(modelValue.claims.length !== 0 && !allClaimsHaveConfirmation){
        viewValue.score+=1;
        viewValue.toDo.push("Alcuni claim non hanno documento di conferma");
    }else{
        viewValue.toDo.push("Aggiungi almeno un claim");
    }

    viewValue.score = viewValue.score*100/suppMaxScore;

    console.log(viewValue);
    return viewValue;
}

/*********** PRODUCT ***********/
export function productViewToModel(product, steps, links){

    var modelValue =  {
        "idClient": null,
        "idUser": null,
        "name": product.name,
        "description": product.description,
        "collectionId": product.collection?.id,
        "technicalDescription": product.technicalDescription,
        "price": product.price,
        "sizes": product.sizes,
        "colors": product.colors,
        "garments": product.garments,
        "countries": product.countries,
        "careAndMantainance": product.careAndMantainance,
        "supplyChainSteps": _.map(steps, (s) => {
            return s.id
        }),
        "supplyChainLinks": _.map(links, (s) => {
            return s.id
        }),
        "media": _.map(product.media, (m) => {
            return m.id;
        })
    }

    return modelValue;
}

export function productModelToView(modelValue){
    const viewValue = {
        'id': modelValue.id,
        'idClient': modelValue.idClient,
        'idUser': modelValue.idUser,
        'name': modelValue.name,
        'description' : modelValue.description,
        'technicalDescription': modelValue.technicalDescription,
        'collection': modelValue.collection,
        'countries': modelValue.countries,
        'price': modelValue.price,
        'sizes': modelValue.sizes,
        'colors': modelValue.colors,
        'garments': modelValue.garments,
        'careAndMantainance' : modelValue.careAndMantainance,
        'media': modelValue.media === undefined ? [] : modelValue.media,
        supplyChainSteps: _.map(modelValue.supplyChainSteps, (scs) => {
            return scs
        }),
        supplyChainLinks: _.map(modelValue.supplyChainLinks, (scl) => {
            return scl
        }),
    };
    return viewValue;
}

/* ANALYTICS */
export function clickOnClaimModelToView(modelValue){
    console.log(modelValue);

    var viewValue = _.map(modelValue, (row) => {
        return {
            "id": row.id,
            "idClient": row.idClient,
            "idUser": row.idUser,
            "type": row.type,
            "productId": row.object.productId,
            "claimId": row.object.claimId,
            "claimName": row.object.claimName,
            "date": 1681575012609
        }   
    })

    return viewValue
}

export function clickOnDocumentModelToView(modelValue){
    console.log(modelValue);

    var viewValue = _.map(modelValue, (row) => {
        return {
            "id": row.id,
            "idClient": row.idClient,
            "idUser": row.idUser,
            "type": row.type,
            "productId": row.object.productId,
            "documentId": row.object.documentId,
            "documentName": row.object.documentName,
            "date": 1681575012609
        }   
    })

    return viewValue
}

export function getInteractionsByProduct(productId, interactions){
    return {
        'clickOnClaim': _.filter(interactions.clickOnClaim, (int) => {return int.productId === productId}),
        'clickOnDocument': _.filter(interactions.clickOnDocument, (int) => {return int.productId === productId}),
        'stepOpened': _.filter(interactions.stepOpened, (int) => {return int.productId === productId}),
        'tabOpened': _.filter(interactions.tabOpened, (int) => {return int.productId === productId}),
    }
}

