import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

 
const Main = () => {
  const auth = localStorage.getItem('PRISMA_TOKEN')
	const navigate = useNavigate();

	useEffect(() => {
		if (!auth) {
			navigate("/login");
		}
	}, [])

  return(
    <div>
    </div>
  )
}
 
export default Main;