import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getClientById } from '../services/RemoteService';

const PageHeader = () => {

    var items = [];

    const params = useParams();

    if(params['*'].split('/')[0] === 'suppliers'){
        items = [];
        items.push({
            'label': 'Fornitori'
        });
    }else if(params['*'].split('/')[0] === 'products'){
        items = [];
        items.push({
            'label': 'Prodotti'
        });
    }else if(params['*'].split('/')[0] === 'productNewEdit'){
        items = [];
        items.push({
            'label': 'Nuovo prodotto'
        });
    }else if(params['*'].split('/')[0] === 'supplierNewEdit'){
        items = [];
        if(params['*'].split('/')[1]){
            items.push({
                'label': 'Modifica fornitore'
            });
        }else{
            items.push({
                'label': 'Nuovo fornitore'
            });
        }
        
    }else if(params['*'].split('/')[0] === 'analytics'){
        items = [];
        items.push({
            'label': 'Supply Chain Analytics'
        });
    }
    
    const home = { icon: 'pi pi-home', url: '/' }

    const [currentUser, setCurrentUser] = useState('');
    
    useEffect(() => {
        const fetchCurrentUser = async () => {
            const userId = localStorage.getItem('PRISMA_CLIENT');
        
            const userResponse = await getClientById(userId);
            if(userResponse.status === 200){
                setCurrentUser(userResponse.response.data.clients[0].userName)
            }
        }

        fetchCurrentUser();
        
    }, [])

    const signOut = () => {
        confirmDialog({
            message: 'Confermi di voler effettuare il logout?',
            header: 'Logout',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()  => {
                localStorage.removeItem('PRISMA_CLIENT');
                localStorage.removeItem('PRISMA_TOKEN');
                window.location.reload(false);
            },
            reject: () => function(){}
        });
    }

    return (
        <>
            <ConfirmDialog></ConfirmDialog>
            <div className="prisma-blue-bg p-3 mx-0 flex justify-content-between align-items-center">
                <BreadCrumb model={items} home={home} className="prisma-blue-bg" style={{borderRadius: '0px'}}/>
                <div className='flex align-items-center'>
                    {
                        currentUser !== '' &&
                        <>
                            <Chip label={currentUser} icon="pi pi-user" className='prisma-green-bg'/>
                            <Button icon="pi pi-sign-out" className="p-button-rounded p-button-text ml-2" onClick={() => signOut()}/>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default PageHeader
