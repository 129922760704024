import { DataView } from 'primereact/dataview';
import { Image } from 'primereact/image';
import { Chip } from 'primereact/chip';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import {v4 as uuid} from 'uuid'

import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const ProductItemDataView = (props) => {
    const products = props.products;

    const countriesDiv = (data) => {
        const content = [];

        for(var i = 0; i<data.countries.length; i++){
            const country = data.countries[i];
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{country}</div>
                </span>
            )
        }
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const garmentsDiv = (data) => {
        const content = [];

        _.each(data.garments, (g) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{g.percentage+'%'}<div>&nbsp;</div>{g.name}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const colorsDiv = (data) => {
        const content = [];

        _.each(data.colors, (c) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{c}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const sizesDiv = (data) => {
        const content = [];

        _.each(data.sizes, (s) => {
            content.push(
                <span key={uuid()} className='flex my-1 mr-1 p-1 px-2 border-round-xl' style={{backgroundColor: 'lightgrey'}}>
                    <div className='flex'>{s}</div>
                </span>
            )
        })
        
        return(
            <div className='flex' style={{maxWidth: '130px', overflow: 'scroll'}}>
                {content}
            </div>
        )
    }

    const navigate = useNavigate();

    const itemTemplate = (product) => {
        return (
            <>
                <div className="col-12 m-0 p-2 lg:col-6 xl:col-4 flex align-items-stretch">

                    <div className='p-2 flex'>
                        <div className='grid border-round-md shadow-2 align-content-start'>

                            <div className='col-12 flex justify-content-between'>
                                <div className='flex'>
                                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text mr-1" style={{width: '38px', height: '38px'}} onClick={() => navigate("/productNewEdit/"+product.id)} />
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-text mr-1" style={{width: '38px', height: '38px'}} onClick={() => props.onDeleteCurrentProduct(product.id)} />
                                    <Button className="p-button-rounded p-button-text" style={{width: '38px', height: '38px'}} onClick={() => props.onOpenProductUnityModal(product)} >
                                        <FontAwesomeIcon icon="passport" />
                                    </Button>
                                </div>
                                <Button className="p-button-rounded p-button-text" style={{width: '38px', height: '38px'}} onClick={() => {
                                        props.onSetSelectedRow(product);
                                        props.onSetShowRowDetail(true);
                                    }
                                }>
                                    <FontAwesomeIcon icon="search" />
                                </Button>
                            </div>

                            {
                                product.media.length !== 0 &&
                                <div className='col-6 flex mx-0 justify-content-center align-items-center'>
                                    <Image preview className="flex justify-content-center align-items-center" imageStyle={{width: 'auto', height: '150px', borderRadius: '10px'}} src={product.media[0].referredFile.url} alt="Image"/>
                                </div>
                            }
                            {
                                product.media.length === 0 &&
                                <div className='col-6 flex mx-0 justify-content-center align-items-center'>
                                    <small className='text-center'>Non è stata caricata alcuna foto per questo prodotto.</small>
                                </div>
                            }

                            <div className='col col-6 grid p-0 m-0 align-content-start'>

                                {
                                    (product.name || product.description) &&
                                    <div className='col col-12'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pm2vtNCUp"} alt="Image"/>
                                            <div>
                                                <h4 className='m-0 p-0 flex'>{product.name}</h4>
                                                {
                                                    product.description &&
                                                    <small className='flex align-content-center align-items-center mt-1'>{product.description}</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    (product.collection?.name) &&
                                    <div className='col col-12'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pnWDVKj2p"} alt="Image"/>
                                            <div>
                                                <h4 className='m-0 p-0 flex'>{product.collection.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>

                            <Divider className='divider-blue mx-3' align="left" type="dashed">
                                <small className='text-blue'>Specifiche</small>
                            </Divider>

                            <div className='grid col-12 mx-1'>
                                {
                                    product.garments.length !== 0 &&

                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmsfyrVPp"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Materiali</h4>
                                        </div>
                                        <small className='flex align-items-start align-content-start mt-2'>
                                            <div className='flex grid m-0 p-0'>
                                                {garmentsDiv(product)}
                                            </div>
                                        </small>
                                    </div>
                                }

                                {
                                    product.countries.length !== 0 &&
                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmFdASVbp"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Made in</h4>
                                        </div>
                                        <small className='flex align-items-start align-content-start mt-2'>
                                            <div className='flex grid m-0 p-0'>
                                                {countriesDiv(product)}
                                            </div>
                                        </small>
                                    </div>
                                }

                                {
                                    product.colors.length !== 0 &&

                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/poyBscMap"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Colori</h4>
                                        </div>
                                        <small className='flex align-items-start align-content-start mt-2'>
                                            <div className='flex grid m-0 p-0'>
                                                {colorsDiv(product)}
                                            </div>
                                        </small>
                                    </div>
                                }

                                {
                                    product.sizes.length !== 0 &&

                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmsRyidIp"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Taglie</h4>
                                        </div>
                                        <small className='flex align-items-start align-content-start mt-2'>
                                            <div className='flex grid m-0 p-0'>
                                                {sizesDiv(product)}
                                            </div>
                                        </small>
                                    </div>
                                }

                                {
                                    product.price !== null &&
                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pncUTtzep"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Prezzo</h4>
                                        </div>
                                        <small className='flex mt-2'>
                                            <span className='flex align-content-center align-items-center'>{product.price}€</span>
                                        </small>
                                    </div>
                                }
                            </div>

                            <Divider className='divider-blue mx-3' align="left" type="dashed"></Divider>

                            <div className='grid col-12 mx-1'>
                                {
                                    product.supplyChainSteps !== null &&
                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmmjLeXop"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Numero step</h4>
                                        </div>
                                        <small className='flex mt-2'>
                                            <Badge value={product.supplyChainSteps.length} className="mr-2 prisma-green-bg text-blue no-border"></Badge>
                                        </small>
                                    </div>
                                }

                                {
                                    product.supplyChainLinks !== null &&
                                    <div className='col col-6'>
                                        <div className='flex'>
                                            <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmD8lGSXp"} alt="Image"/>
                                            <h4 className='m-0 p-0 flex'>Numero trasporti</h4>
                                        </div>
                                        <small className='flex mt-2 align-items-center'>
                                            <Badge value={product.supplyChainLinks.length} className="mr-2 prisma-green-bg text-blue no-border"></Badge>
                                            (Tot km: <span className='ml-2'>{props.onTotalDistance(product)}</span>)
                                        </small>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="col-12 md:col-4">
                    <div className='grid shadow-2 border-round-md p-3 m-3'>

                        <div className='col col-6 grid p-0 m-0'>

                            {
                                (product.collection.name) &&
                                <div className='col col-12'>
                                    <div className='flex'>
                                        <h4 className='m-0 p-0 flex'>Collezione</h4>
                                        <Image className="flex ml-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/poy9A7R7p"} alt="Image"/>
                                    </div>
                                    <small className='flex mt-2'>
                                        <span className='flex align-content-center align-items-center'>{product.collection.name}</span>
                                    </small>
                                </div>
                            }
                        </div>

                        <div className='grid col-12'>

                            

                            

                        </div>
                    </div>
                </div> */}
            </>
        );
    }

    return(
        <>
            <DataView value={products} layout={'grid'}
                itemTemplate={itemTemplate} paginator rows={9} />
        </>
    )
}

export default ProductItemDataView;