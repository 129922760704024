
/* Components from primereact */
import { Image } from 'primereact/image'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Avatar } from 'primereact/avatar'
import { Toast } from 'primereact/toast';
import { Galleria } from 'primereact/galleria';

/* Imports from react and animations */
import React, { useEffect, useRef, useState } from 'react'

/* Libraries */
import _ from 'underscore'
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from 'react-router-dom'
import { productModelToView } from '../services/DbUtils'
import { getDistanceFromLatLonInKm, getMidPoint, getStepClaimsMediaDocs } from '../services/UtilsService'
import { getNfcTags, getProductUnities, createAnalytic } from '../services/RemoteService'
import { v4 as uuid } from 'uuid';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvY2t2aXNpb25tIiwiYSI6ImNsN3M1eXh1dzAzbm0zcXFucjgycGx6aWIifQ.N30gHRpZicQDW11W2XopZw';

var geojson = {
	'type': 'FeatureCollection',
	'features': [
	]
};


const DigitalProductPassport = (props) => {

	const [selectedTab, setSelectedTab] = useState(2);

	var { id } = useParams();

	const [suppliers, setSuppliers] = useState();

	const [product, setProduct] = useState();
	const [supplyChainSteps, setSupplyChainSteps] = useState();
	const [supplyChainLinks, setSupplyChainLinks] = useState();

	const [supplyChainStepsSupplierDisplayData, setSupplyChainStepsSupplierDisplayData] = useState([]);

	const [lastTabOpenedAt, setLastTabOpenedAt] = useState(Date.now());
	const [lastTabOpenedName, setLastTabOpenedName] = useState('map');

	var body = document.body,
    html = document.documentElement;

	var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );


	useEffect(() => {
		if (id) {
			const fetchProductUnity = async () => {
				if (id.length === 24) {
					if(id=="641ad52ebaea515473de3870"){
						id = "6489eb960b014c5aed7889ed"
					}
					const qrProductUnityResponse = await getProductUnities({
						"nfcTagId": null,
						"id": id
					});

					if (qrProductUnityResponse.status === 200 && qrProductUnityResponse.response.data.productUnities.length > 0) {
						setPassport(qrProductUnityResponse.response.data.productUnities[0].product);
					}
				} else if(id.length === 8) {
					const nfcTagResponse = await getNfcTags({
						"prismaId": id
					});

					if (nfcTagResponse.status === 200) {

						const productUnityResponse = await getProductUnities({
							"nfcTagId": nfcTagResponse.response.data.nfcTags[0].id,
						});

						if (productUnityResponse.status === 200) {
							setPassport(productUnityResponse.response.data.productUnities[0].product);
						}
					}
				} else {
					const nfcTagResponse = await getNfcTags({
						"internalId": id
					});

					if (nfcTagResponse.status === 200) {

						const productUnityResponse = await getProductUnities({
							"nfcTagId": nfcTagResponse.response.data.nfcTags[0].id,
						});

						if (productUnityResponse.status === 200) {
							setPassport(productUnityResponse.response.data.productUnities[0].product);
						}
					}
				}
			}

			fetchProductUnity()
		}
	}, [])

	const setPassport = (modelProduct) => {
		const viewProduct = productModelToView(modelProduct);
		setProduct(viewProduct);
		setSupplyChainSteps(viewProduct.supplyChainSteps);
		setSupplyChainLinks(viewProduct.supplyChainLinks);

		setSupplyChainStepsSupplierDisplayData(_.map(viewProduct.supplyChainSteps, (step) => {
			return {
				'id': step.id,
				'index': 0,
				'showAdditionalContent': false
			}
		}))

		const filteredStepsTypes = _.filter(supplyChainsteps, (type) => {
			return _.any(viewProduct.supplyChainSteps, (step) => {
				return step.type.code === type.code;
			})
		})

		setFilteredsteps(filteredStepsTypes);
		setSelectedStepType(filteredStepsTypes[(filteredStepsTypes.length)-1]);

		var suppliers = [];
		if (viewProduct.supplyChainSteps.length > 0) {

			for (var i = 0; i < viewProduct.supplyChainSteps.length; i++) {
				if (!_.isEmpty(viewProduct.supplyChainSteps[i].originSupplier)) {
					if (!_.any(suppliers, (s) => {
						return s.id === viewProduct.supplyChainSteps[i].originSupplier.id;
					})) {
						suppliers.push(viewProduct.supplyChainSteps[i].originSupplier);
					}
				}
				if (!_.isEmpty(viewProduct.supplyChainSteps[i].processorSupplier)) {
					if (!_.any(suppliers, (s) => {
						return s.id === viewProduct.supplyChainSteps[i].processorSupplier.id;
					})) {
						suppliers.push(viewProduct.supplyChainSteps[i].processorSupplier);
					}
				}
				if (!_.isEmpty(viewProduct.supplyChainSteps[i].traderSupplier)) {
					if (!_.any(suppliers, (s) => {
						return s.id === viewProduct.supplyChainSteps[i].traderSupplier.id;
					})) {
						suppliers.push(viewProduct.supplyChainSteps[i].traderSupplier);
					}
				}
				if (!_.isEmpty(viewProduct.supplyChainSteps[i].owner)) {
					if (!_.any(suppliers, (s) => {
						return s.id === viewProduct.supplyChainSteps[i].owner.id;
					})) {
						suppliers.push(viewProduct.supplyChainSteps[i].owner);
					}
				}
			}
		}

		setSuppliers(suppliers);

		createAnalytic('PASSPORT_OPENED', {
			productId: viewProduct.id,
			date: Date.now()
		})
	}

	/* MAPPA */
	const mapSettings = {
		center: [12.496366, 41.902782],
		zoom: 1,
		projection: 'globe',
		addNavigation: true,
		addGeolocate: true,
		addGeocoder: true,
		addLinesSource: true
	};

	var map = null;

	useEffect(() => {
		if (supplyChainSteps) {
			setTimeout(() => {
				setMap();
			}, 200);
		}
	}, [supplyChainSteps])

	/* useEffect(() => {
		if (selectedTab == 2 && !mapLoaded) {
			setTimeout(() => {
				setMap();
				setMapLoaded(true);
			}, 200);
		}
	}, [selectedTab]) */

	const setMap = () => {
		if (map === null) {
			map = new mapboxgl.Map({
				container: document.querySelector('#mapContainerID'),
				style: "mapbox://styles/mapbox/light-v9",
				center: mapSettings.center,
				zoom: mapSettings.zoom,
				projection: mapSettings.projection //The map is standard displayed as a globe
			});

			//Adding zoom control and navigation
			const nav = new mapboxgl.NavigationControl({
				visualizePitch: true
			});

			//Adding fog
			map.on('load', () => {
				map.setFog({
					'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
					'space-color': 'white', // Background color
					'star-intensity': 0 // Background star brightness (default 0.35 at low zoooms )
				});

				map.addSource('TransportLine', {
					'type': 'geojson',
					'data': geojson
				});

				map.addLayer({
					'id': 'TransportLine',
					'type': 'line',
					'source': 'TransportLine',
					'layout': {
						'line-join': 'round',
						'line-cap': 'round'
					},
					'paint': {
						'line-color': 'grey',
						'line-width': 1,
						"line-dasharray": [5, 5]
					}
				});

				/* setupMapAnimation(); */
				/* document.getElementById('opaque-map-overlay').remove() */
				//document.getElementById('step-info-box').classList.remove('inactive')
				setupMapAfterAnimation();
			});
		}
	}

	const setupMapAnimation = () => {

		const secondPopup = new mapboxgl.Popup({ offset: { 'top': [50, 50] }, className: 'transparent-map-popup', id: "popup-to-remove", closeOnClick: false })
			.setLngLat(map.getCenter())
			.setHTML('<h1 class="text-center" style="line-height: 30px;">Tap to explore this Digital Product Passport!</h1>')
			.addTo(map);

		const newMarkerHtmlElement = document.createElement('div');
		newMarkerHtmlElement.style.background = '#002873';
		newMarkerHtmlElement.style.width = '70px';
		newMarkerHtmlElement.style.height = '70px';
		newMarkerHtmlElement.style.borderRadius = '100%';
		newMarkerHtmlElement.style.backgroundSize = '100%';
		newMarkerHtmlElement.style.backgroundSize = '100%';
		newMarkerHtmlElement.classList.add('transparent-map-popup');
		newMarkerHtmlElement.id = ('marker-to-remove')

		// Add markers to the map.
		const newMarker = new mapboxgl.Marker(newMarkerHtmlElement)
			.setLngLat(map.getCenter())
			.addTo(map);

		newMarker.getElement().addEventListener('click', () => {
			/* document.getElementById('opaque-map-overlay').remove() */
			//document.getElementById('step-info-box').classList.remove('inactive')
			setupMapAfterAnimation();
			newMarker.remove();
			secondPopup.remove();
		})
	}

	const setupMapAfterAnimation = () => {

		_.each(suppliers, (supp) => {
			const coords = supp.address.placeOnEarth.split(', ');
			addMarkerToMap([coords[0], coords[1]], supp.id, 'grey');
		})

		if (supplyChainLinks.length > 0) {
			for (var i = 0; i < supplyChainLinks.length; i++) {
				addLineToMap(supplyChainLinks[i].fromSupplier.address.placeOnEarth, supplyChainLinks[i].toSupplier.address.placeOnEarth, supplyChainLinks[i].id, supplyChainLinks[i].vehicle);
			}
		}

		var longestDistance = 0;

		_.each(suppliers, function (supp) {
			const tmp = supp.address.placeOnEarth.split(', ');
			const coords = [tmp[0], tmp[1]] //Lon and lat

			_.each(suppliers, function (otherSupp) {
				const otherTmp = otherSupp.address.placeOnEarth.split(', ');
				const otherCoords = [otherTmp[0], otherTmp[1]] //Lon and lat

				const newDistance = getDistanceFromLatLonInKm(coords[0], coords[1], otherCoords[0], otherCoords[1]);

				if (newDistance > longestDistance) {
					longestDistance = newDistance;
				}
			})
		})

		setTimeout(() => {
			const tmp = suppliers[0].address.placeOnEarth.split(', ');
			const coords = [tmp[0], tmp[1]] //Lon and lat

			map.flyTo({ center: coords, zoom: longestDistance * 2.8 / 900, duration: 1500 });
		}, 500);
	}

	var highlightMarker = null;

	const [selectedSupplier, setSelectedSupplier] = useState();
	const [displaySelectedSupplierModal, setDisplaySelectedSupplierModal] = useState(false);

	const addMarkerToMap = (lngLat, id, color) => {
		const marker = new mapboxgl.Marker({
			color: color
		}).setLngLat(lngLat)
			.addTo(map);

		marker.getElement().addEventListener('click', () => {
			const clickedSupplier = _.find(suppliers, (supp) => {
				return supp.id === id;
			})
			if (clickedSupplier) {
				setSelectedSupplier(clickedSupplier);
				//setDisplaySelectedSupplierModal(true);
				setSelectedLink({});
				//setDisplaySelectedLinkModal(false);
			}

			if (highlightMarker) {
				highlightMarker.remove();
			}

			highlightMarker = new mapboxgl.Marker({
				color: '#002873'
			}).setLngLat(marker.getLngLat())
				.addTo(map);

			map.flyTo({ center: lngLat, zoom: map.getZoom(), duration: 1500 })

		});

	}

	const [selectedLink, setSelectedLink] = useState();
	const [displaySelectedLinkModal, setDisplaySelectedLinkModal] = useState(false);

	const addLineToMap = (from, to, id, vehicleIcon) => {
		const fromCoords = [from.split(', ')[0], from.split(', ')[1]];
		const toCoords = [to.split(', ')[0], to.split(', ')[1]];
		const line = {
			'type': 'Feature',
			'geometry': {
				'type': 'LineString',
				'properties': {
					id: id
				},
				'coordinates': [
					fromCoords,
					toCoords
				]
			}
		}

		const midPoint = getMidPoint(fromCoords[0], fromCoords[1], toCoords[0], toCoords[1]);

		const el = Object.assign(document.createElement('div'), {
			innerHTML:
				'<div id="point-' + id + '" class="marker text-center flex align-content-center justify-content-center" style="width: 30px; height: 30px; background: white; border: 1px solid grey;">' +
				'<img class="flex justify-content-center m-auto" src="' + vehicleIcon.iconBlack + '" alt="option icon" style="width: 20px;" />' +
				'</div>'
		})

		el.addEventListener('click', () => {

			const clickedLink = _.find(supplyChainLinks, (link) => {
				return link.id === id;
			})
			if (clickedLink) {
				console.log(clickedLink);
				setSelectedLink(clickedLink);
				setSelectedSupplier({});
				//setDisplaySelectedSupplierModal(false);
				//setDisplaySelectedLinkModal(true);
				if (highlightMarker) {
					highlightMarker.remove();
				}
			}
		});

		// Add markers to the map.
		const marker = new mapboxgl.Marker(el)
			.setLngLat(midPoint)
			.addTo(map);

		geojson.features.push(line);

		map.getSource('TransportLine').setData(geojson);
	}

	const [showSupplierAdditionalInfo, setShowSupplierAdditionalInfo] = useState(false);
	const [showLinkAdditionalInfo, setShowLinkAdditionalInfo] = useState(false);

	/* SUPPLY CHAIN STEPS */

	const [supplyChainsteps, setSupplyChainsteps] = useState([
		{
			'name': 'Retailer',
			'code': 1,
			'icon': { label: 'Retailer', iconBlack: 'https://imageshack.com/i/pmi8BZn4p', iconWhite: 'https://imageshack.com/i/pmkiOoa7p' },
		},
		{
			'name': 'Design',
			'code': 2,
			'icon': { label: 'Design', iconBlack: 'https://imageshack.com/i/pn820UV9p', iconWhite: 'https://imageshack.com/i/pmc16Wphp' },
		},
		{
			'name': 'Packaging & Label',
			'code': 3,
			'icon': { label: 'Packaging & Label', iconBlack: 'https://imageshack.com/i/pmbVPLI5p', iconWhite: 'https://imageshack.com/i/pnsLF87Rp' },
		},
		{
			'name': 'Manufacture',
			'code': 4,
			'icon': { label: 'Manufacture', iconBlack: 'https://imageshack.com/i/pmO5domnp', iconWhite: 'https://imageshack.com/i/powaSuzjp' },
		},
		{
			'name': 'Trims & Components',
			'code': 5,
			'icon': { label: 'Trims & Components', iconBlack: 'https://imageshack.com/i/poBb5ZwCp', iconWhite: 'https://imageshack.com/i/pors6ipFp' },
		},
		{
			'name': 'Fabric',
			'code': 6,
			'icon': { label: 'Fabric', iconBlack: 'https://imageshack.com/i/po4FeI8rp', iconWhite: 'https://imageshack.com/i/pmCh3ZI3p' },
		},
		{
			'name': 'Pre-Processing',
			'code': 7,
			'icon': { label: 'Pre-Processing', iconBlack: 'https://imageshack.com/i/poiBewgBp', iconWhite: 'https://imageshack.com/i/pnFxUllMp' },
		},
		{
			'name': 'Raw Materials',
			'code': 8,
			'icon': { label: 'Raw Materials', iconBlack: 'https://imageshack.com/i/pmHD1Hmbp', iconWhite: 'https://imageshack.com/i/poVdGh8Sp'  },
		},
		{
			'name': '',
			'code': 9,
			'icon': {},
		},
	]);

	const [selectedStepType, setSelectedStepType] = useState();
	const [filteredStepsTypes, setFilteredsteps] = useState();

	const customTimelineDiv = () => {
		const content = [];

		content.push(
			<Divider key={uuid()} className='flex my-5 mx-1' type='dashed' />
		);


		_.each(filteredStepsTypes, (stepType) => {
			content.push(
				<Divider key={uuid()} className='flex my-5 mx-1' type='dashed' />
			);

			if (stepType.code === selectedStepType.code) {
				content.push(
					<div key={uuid()} className='justify-content-center text-center'>
						<div className='flex justify-content-center' style={_.contains([3, 5, 7, 8], stepType.code) ? { marginTop: '22px' } : { marginTop: '12px' }}>
							<span className="custom-marker shadow-1 p-3" style={{ backgroundColor: '#002873', cursor: 'pointer', width: "55px", height: "55px" }}>
								<Image className='flex align-items-center' src={stepType.icon.iconWhite} alt="" width="30px" height="30px" />
							</span>
						</div>
						<div className='mt-1' style={{ fontWeight: 'bold', fontSize: '10px' }}>{stepType.name}</div>
					</div>
				);
			} else {
				content.push(
					<div key={uuid()} className='justify-content-center text-center'>
						<div className='flex justify-content-center' style={_.contains([3, 5, 7, 8], stepType.code) ? { marginTop: '22px' } : { marginTop: '12px' }}>
							<span className="custom-marker shadow-1" style={{ backgroundColor: '#555555', cursor: 'pointer', width: "28px", height: "28px" }} onClick={() => setSelectedStepType(stepType)}>
								<Image className='flex align-items-center' src={stepType.icon.iconWhite} alt="" width="12px" height="12px" />
							</span>
						</div>
						<div className='mt-1' style={{ fontWeight: 'bold', fontSize: '10px' }}>{stepType.name}</div>
					</div>
				);
			}
		})

		content.push(
			<Divider key={uuid()} className='flex my-5 mx-1' type='dashed' />
		);

		return (
			<div className='flex align-items-center mx-2'>
				{/* <div className='flex' onClick={() => {
                    document.getElementById('passport-step-timeline-horizzontal').scrollLeft -= 3*scrollWidth;
                }}>
                    <FontAwesomeIcon icon="angle-left"/>
                </div> */}
				<div id="passport-step-timeline-horizzontal" className='flex justify-content-around align-items-center px-3 p-passport-timeline' style={_.contains([3, 5, 7, 8], selectedStepType.code) ? { marginTop: '5px', marginBottom: '8px', overflow: 'scroll' } : { marginTop: '23px', marginBottom: '23px', overflow: 'scroll' }}>
					{content}
				</div>
				{/* <div className='flex' onClick={() => {
                    document.getElementById('passport-step-timeline-horizzontal').scrollLeft += 3*scrollWidth;
                }}>
                    <FontAwesomeIcon icon="angle-right" className='mr-2'/>
                </div> */}
			</div>
		)
	}



	const [selectedClaim, setSelectedClaim] = useState();
	const [showSelectedClaimDetail, setShowSelectedClaimDetail] = useState(false);

	const customTimelineContentDiv = () => {
		const content = [];

		const filteredSteps = _.filter(supplyChainSteps, (step) => {
			return step.type.code === selectedStepType.code;
		})

		_.each(filteredSteps, (step) => {
			const [claims, media, docs] = getStepClaimsMediaDocs(step);
			const uniqueMedia = _.unique(media, (m) => {
				return m.referredFile.id
			})

			const claimsDiv = [];

			_.each(claims, (claim) => {
				claimsDiv.push(
					<div key={uuid()} className="flex align-content-center align-item-center flex-wrap claim-box shadow-4 mr-1 px-2 py-1 mb-2"
						onClick={() => {
							setSelectedClaim(claim);
							setShowSelectedClaimDetail(true);
							createAnalytic('CLICK_ON_CLAIM', {
								productId: product.id,
								claimId: claim.id,
								claimName: claim.name,
								date: Date.now()
							})
						}
						}>
						<Image className='flex align-items-center mr-2' src={claim.type.icon} alt="icon" height="30px" />
						<div className='my-auto claim-text'>
							<div className='claim-text mb-1 font-medium'>{claim.name}</div>
							<div className='font-italic underline' style={{ fontSize: '10px' }}>
								{
									claim.status === 'THIRD_PARTIES' &&
									<div className='flex'>
										<Image className='flex align-items-center mr-2' src={"https://imageshack.com/i/pnBNfH7rp"} alt="icon" height="10px" />
										Verified by third party
									</div>
								}
								{
									claim.status === 'VERIFIED' &&
									<div className='flex'>
										<Image className='flex align-items-center mr-2' src={"https://imageshack.com/i/pnBNfH7rp"} alt="icon" height="10px" />
										Verified
									</div>
								}
								{
									claim.status === 'TO_BE_CONFIRMED' &&
									<div className='flex'>
										<FontAwesomeIcon icon="circle-check" className='my-auto mr-2' />
										Verified
									</div>
								}
							</div>
						</div>
						<FontAwesomeIcon icon="angle-right" className='ml-3 my-auto' />

					</div>
				)
			})

			const mediaDiv = [];
			_.each(uniqueMedia, (m) => {

				mediaDiv.push(
					<Image key={uuid()} preview className="flex align-items-center mr-2" imageStyle={{ width: 'auto', height: '150px', borderRadius: '10px' }} src={m.referredFile.url} alt="Image" />
				)

				/* if(media.file.objectURL.includes('.mp4'))
					mediaDiv.push(
						<video className="flex align-items-center mr-2 mr-3" key={media.name} controls style={{width: '200px', height: '200px', borderRadius: '10px'}}>
							<source src={media.file.objectURL} type="video/mp4"/>
						</video>
				) */
			})

			const suppliersArray = [];
			if (!_.isEmpty(step.originSupplier)) {
				suppliersArray.push({
					'supp': step.originSupplier,
					'label': 'Origin'
				});
			}
			if (!_.isEmpty(step.processorSupplier)) {
				suppliersArray.push({
					'supp': step.processorSupplier,
					'label': 'Processed by'
				});
			}
			if (!_.isEmpty(step.traderSupplier)) {
				suppliersArray.push({
					'supp': step.traderSupplier,
					'label': 'Traded by'
				});
			}
			if (!_.isEmpty(step.owner)) {
				suppliersArray.push({
					'supp': step.owner,
					'label': 'Owned by'
				});
			}


			const suppliersHeader = [];
			const suppliersContent = [];
			const suppliersAdditionalInfo = [];

			_.each(suppliersArray, (supp) => {
				let suppIndex = _.indexOf(suppliersArray, supp);
				suppliersHeader.push(
					<div key={uuid()} className='flex'
						onClick={() => {
							let selectedStepIdx = _.findIndex(supplyChainStepsSupplierDisplayData, (s) => {
								return s.id === step.id
							})
							const supplyChainStepsSupplierDisplayDataCopy = [...supplyChainStepsSupplierDisplayData];
							supplyChainStepsSupplierDisplayDataCopy[selectedStepIdx].index = suppIndex;
							setSupplyChainStepsSupplierDisplayData(supplyChainStepsSupplierDisplayDataCopy)
						}
						}>

						{
							suppIndex === _.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).index ? <h5 className='m-0 p-0 mr-2' style={{ fontSize: '15px !important' }}>{supp.label}</h5> : <small className='mr-2' style={{ fontSize: '13px' }}>{supp.label}</small>
						}
					</div>
				)

				suppliersContent.push(
					<div key={uuid()}>
						{
							suppIndex === _.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).index &&

							<div className="grid m-0 p-0 align-items-center">
								<div className='col col-3 px-0'>
									<div className='flex justify-content-start'>
										<Avatar image={supp.supp.image.referredFile.url} size="xlarge" shape="circle" />
									</div>
								</div>
								<div className='col col-9'>
									<h4 className='my-0'>{supp.supp.name}</h4>
									<small className='flex align-items-center py-0'>
										<FontAwesomeIcon icon="map-pin" className='mr-2' />
										{mapAddressDiv(supp.supp.address)}
									</small>
								</div>
							</div>
						}
					</div>
				)

				suppliersAdditionalInfo.push(
					<div key={uuid()}>
						{
							suppIndex === _.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).index &&

							<div className="grid m-0 p-0 align-items-center">

								<small className='my-2'>{supp.supp.description}</small>

								<div className='col col-12 grid align-items-start'>
									{
										supp.supp.companySize !== "" &&
										<>
											{
												supp.supp.companySize &&
												<div className='col col-12 grid m-0 p-0'>
													<h5 className='my-0 col col-4 pl-0'>Company size</h5>
													<small className='col col-8'>{supp.supp.companySize} {supp.supp.numberOfPermanentEmployees !== 0 ? <FontAwesomeIcon icon="info-circle" className='mr-2 employees-tooltip' data-pr-position="top" /> : ""}</small>
												</div>
											}

											{
												supp.supp.productionInfo.numberOfPermanentEmployees !== 0 &&
												<div className='col col-12 grid m-0 p-0'>
													<h5 className='my-0 col col-4 pl-0'>Employees</h5>

													<small className='col col-8'>{supp.supp.productionInfo.numberOfPermanentEmployees} total{supp.supp.productionInfo.numberOfMaleEmployees !== 0 || supp.supp.productionInfo.numberOfFemaleEmployees !== 0 ? ', of which ' : ""}
														{supp.supp.productionInfo.numberOfMaleEmployees !== 0 ?
															<>{supp.supp.productionInfo.numberOfMaleEmployees} males <FontAwesomeIcon icon="person" className='' /></>
															: ""}
														{supp.supp.productionInfo.numberOfMaleEmployees !== 0 || supp.supp.productionInfo.numberOfFemaleEmployees !== 0 ? 'and ' : ""}
														{supp.supp.productionInfo.numberOfFemaleEmployees !== 0 ?
															<>{supp.supp.productionInfo.numberOfFemaleEmployees} females <FontAwesomeIcon icon="person-dress" className='' /> </>
															: ""}
													</small>
												</div>
											}
										</>
									}
								</div>

							</div>


						}

					</div>
				)
			})

			content.push(
				<div key={uuid()} className="col col-12 shadow-5 border-round-md my-2 px-3" style={{ backgroundColor: "#f4f4f4" }}>
					<h2 className='mb-0 mt-3'>{step.name}</h2>

					{
						claimsDiv.length !== 0 &&
						<div className='grid mx-1 my-3' style={{ marginTop: '15px !important' }}>
							{claimsDiv}
						</div>
					}

					{
						mediaDiv.length !== 0 &&
						<div className='flex justify-content-start' style={{ overflow: 'scroll', borderRadius: '10px' }}>
							{mediaDiv}
						</div>
					}



					<div className='flex align-content-center align-items-center mt-4'>
						{suppliersHeader}
					</div>

					<div className='mt-2'>
						{suppliersContent}
					</div>

					<div className='w-full text-center'>
						{
							!_.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).showAdditionalContent &&

							<FontAwesomeIcon icon="angle-down" className='mr-2' onClick={() => {
								let selectedStepIdx = _.findIndex(supplyChainStepsSupplierDisplayData, (s) => {
									return s.id === step.id
								})
								const supplyChainStepsSupplierDisplayDataCopy = [...supplyChainStepsSupplierDisplayData];
								supplyChainStepsSupplierDisplayDataCopy[selectedStepIdx].showAdditionalContent = !supplyChainStepsSupplierDisplayDataCopy[selectedStepIdx].showAdditionalContent;
								setSupplyChainStepsSupplierDisplayData(supplyChainStepsSupplierDisplayDataCopy)

								createAnalytic('STEP_OPENED', {
									productId: product.id,
									stepId: step.id,
									date: Date.now()
								})
							}
							} />
						}
					</div>

					{
						_.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).showAdditionalContent &&

						<div className=''>
							{suppliersAdditionalInfo}
						</div>
					}

					<div className='w-full text-center mt-1'>
						{
							_.find(supplyChainStepsSupplierDisplayData, (s) => { return s.id === step.id }).showAdditionalContent &&
							<FontAwesomeIcon icon="angle-up" className='mr-2' onClick={() => {
								let selectedStepIdx = _.findIndex(supplyChainStepsSupplierDisplayData, (s) => {
									return s.id === step.id
								})
								const supplyChainStepsSupplierDisplayDataCopy = [...supplyChainStepsSupplierDisplayData];
								supplyChainStepsSupplierDisplayDataCopy[selectedStepIdx].showAdditionalContent = !supplyChainStepsSupplierDisplayDataCopy[selectedStepIdx].showAdditionalContent;
								setSupplyChainStepsSupplierDisplayData(supplyChainStepsSupplierDisplayDataCopy)
							}
							} />
						}
					</div>

				</div>
			)
		});

		return (
			<div className='grid justify-content-center mx-3 my-3'>
				{content}

			</div>
		)
	}

	const garmentsDiv = () => {
		const content = [];

		_.each(product.garments, (garment) => {
			content.push(
				<div key={uuid()} className='flex align-items-center align-content-center col-12 m-0 p-0'>
					<span className='flex'>{garment.percentage}%</span>
					<span className='flex ml-2'>{garment.name}</span>
				</div>
			)
		})

		return (
			content
		)
	}

	const countriesDiv = () => {
		const content = [];

		for (var i = 0; i < product.countries.length; i++) {
			const country = product.countries[i];
			content.push(
				<div key={uuid()} className='flex align-items-center align-content-center col-12 m-0 p-0'>
					{
						country == "IT" ? "Italy" :
						country == "BR" ? "Brazil" :
						country == "CN" ? "China" : ""
					}
				</div>
			)
		}

		return (
			content
		)
	}

	const caresDiv = () => {
		const content = [];
		_.each(product.careAndMantainance, (care) => {
			const cid = uuid();
			content.push(
				<span key={cid} className="flex">
					<Tooltip target={".care-tooltip-" + cid} style={{ fontSize: '10px' }} />
					<Image className={"flex mr-2 care-tooltip-" + cid} imageStyle={{ width: 'auto', height: '20px' }} src={care.iconBlack} alt="Image" data-pr-tooltip={care.label} data-pr-position="top" />
				</span>
			)
		})
		return (
			content
		)
	}

	const getSupplierSteps = (supplier) => {
		let steps = [];

		_.each(supplyChainSteps, (step) => {
			if (_.has(step, 'originSupplier') && !_.isEmpty(step.originSupplier)) {
				if(step.originSupplier.id === supplier.id){
					steps.push(step.type)
				}
			}

			if (_.has(step, 'processorSupplier') && !_.isEmpty(step.processorSupplier)) {
				if(step.processorSupplier.id === supplier.id){
					steps.push(step.type)
				}
			}

			if (_.has(step, 'traderSupplier') && !_.isEmpty(step.traderSupplier)) {
				if(step.traderSupplier.id === supplier.id){
					steps.push(step.type)
				}
			}

			if (_.has(step, 'owner') && !_.isEmpty(step.owner)) {
				if(step.owner.id === supplier.id){
					steps.push(step.type)
				}
			}
		})

		return steps;
	}

	const [displayNfcTagModal, setDisplayNfcTagModal] = useState(false);

	const getStepWithSupplier = (supplier) => {
		var foundSteps = _.filter(supplyChainSteps, (step) => {
			let isOriginSupplier = false;
			let isProcessorSupplier = false;
			let isTraderSupplier = false;
			let isOwner = false;
			if (_.has(step, 'originSupplier') && !_.isEmpty(step.originSupplier)) {
				isOriginSupplier = (step.originSupplier.id === supplier.id)
			}

			if (_.has(step, 'processorSupplier') && !_.isEmpty(step.processorSupplier)) {
				isProcessorSupplier = (step.processorSupplier.id === supplier.id)
			}

			if (_.has(step, 'traderSupplier') && !_.isEmpty(step.traderSupplier)) {
				isTraderSupplier = (step.traderSupplier.id === supplier.id)
			}

			if (_.has(step, 'owner') && !_.isEmpty(step.owner)) {
				isOwner = (step.owner.id === supplier.id)
			}

			return isOriginSupplier || isProcessorSupplier || isTraderSupplier || isOwner;
		})

		const content = [];

		_.each(foundSteps, (step) => {

			const icons = [];
			if (!_.isEmpty(step.originSupplier)) {
				if (step.originSupplier.id === supplier.id) {
					icons.push(
						<span className='flex' key={uuid()} onClick={() => setSelectedTab(3)}>
							<FontAwesomeIcon icon="seedling" className={"mx-1 target-icon-" + step.originSupplier.id + "-seedling"} />
						</span>
					)
				}
			}

			if (!_.isEmpty(step.processorSupplier)) {
				if (step.processorSupplier.id === supplier.id) {
					icons.push(
						<span className='flex' key={uuid()} onClick={() => setSelectedTab(3)}>
							<FontAwesomeIcon icon="industry" className={"mx-1 target-icon-" + step.processorSupplier.id + "-industry"}/>
						</span>
					)
				}
			}

			if (!_.isEmpty(step.traderSupplier)) {
				if (step.traderSupplier.id === supplier.id) {
					icons.push(
						<span className='flex' key={uuid()} onClick={() => setSelectedTab(3)}>
							<FontAwesomeIcon icon="right-left" className={"mx-1 target-icon-" + step.traderSupplier.id + "-right-left"}/>
						</span>
					)
				}
			}

			if (!_.isEmpty(step.owner)) {
				if (step.owner.id === supplier.id) {
					icons.push(
						<span className='flex' key={uuid()} onClick={() => setSelectedTab(3)}> //DEVE APRIRSI SULLO STEP GIUSTO
							<FontAwesomeIcon icon="light-bulb" className={"mx-1 target-icon-" + step.traderSupplier.id + "-bulb"}/>
						</span>
					)
				}
			}

			content.push(
				<div className='col-12 flex justify-content-center' key={uuid()}>
					<span className='mr-3'>{step.name}</span>{icons}
				</div>
			)

		})

		return (
			<div className='w-full'>
				{content}
			</div>
		)
	}

	/* const mapAddressDiv = (address) => {
		let content = [];
		if (address.city !== "" && address.showCity) {
			content.push(<span key={uuid()}>{address.city}</span>);
			if ((address.address !== '' && address.showAddress)) {
				content.push(<span key={uuid()}>,&nbsp;</span>);
			}else if((address.province !== '' && address.showProvince)){
				content.push(<span key={uuid()}>&nbsp;</span>);
			}
		}
		if (address.address !== '' && address.showAddress) {
			content.push(<span key={uuid()}>{address.address}</span>);
			if ((address.province !== '' && address.showProvince)) {
				content.push(<span key={uuid()}>,&nbsp;</span>);
			}
		}
		if (address.province !== '' && address.showProvince) {
			content.push(<span key={uuid()}>({address.province})</span>);
		}
		return content;
	} */

	const mapAddressDiv = (address) => {
		let content = "";
		if (address.address !== '' && address.showAddress) {
			content += address.address;
			if ((address.province !== '' && address.showProvince)) {
				content += ", ";
			}
		}
		if (address.city !== "" && address.showCity) {
			content += address.city;
			if ((address.address !== '' && address.showAddress) || (address.province !== '' && address.showProvince)) {
				content += " ";
			}
		}
		if (address.province !== '' && address.showProvince) {
			content += "("+address.province+")"
		}
		return content;
	}

	const mapAddressDiv2 = (address) => {
		let content = "";
		if (address.city !== "" && address.showCity) {
			content += address.city;
			if (address.province !== '' && address.showProvince) {
				content += " ";
			}
		}
		if (address.province !== '' && address.showProvince) {
			content += "("+address.province+")"
		}
		return content;
	}

	const [toCheckTagId, setToCheckTagId] = useState('');
	const checkTagNFC = async () => {
		const checkResponse = await getNfcTags({
			'internalId': id,
			'prismaId': toCheckTagId
		})

		if (checkResponse.status === 200 && checkResponse.response.data.nfcTags.length > 0) {
			console.log('TAG GIUSTO');
			toast.current.show({ label: "Sticky", life: '1500000', severity: 'success', summary: 'Codice corretto', detail: 'Il prodotto è autentico!' });
		} else {
			toast.current.show({ label: "Sticky", life: '1500000', severity: 'warn', summary: 'Codice errato', detail: 'Il codice digitato non è associato a nessun prodotto. Controlla di averlo digitato correttamente. In caso affermativo il prodotto potrebbe non essere autentico.' });
		}
	}

	const toast = useRef(null);

	/* const productImagesDiv = () => {
		let content = [];
		_.each(product.media, (m) => {

			content.push(
				<Image key={uuid()} preview className="flex align-items-center mr-2" imageStyle={{ width: 'auto', height: '150px', borderRadius: '10px' }} src={m.referredFile.url} alt="Image" />
			)
		})
		return (
			<div className='flex justify-content-start' style={{ overflow: 'scroll', borderRadius: '10px' }}>
				{content}
			</div>
		)
	}; */

	const productImagesDiv = () => {
		return (
			<div>
				<Galleria value={product.media} showThumbnails={false} showIndicators item={productImageTemplate} />
			</div>
		)
	};

	const productImageTemplate = (item) => {
        return <Image key={uuid()} preview className="flex align-items-center" imageStyle={{ width: 'auto', height: '250px', borderRadius: '10px' }} src={item.referredFile.url} alt="Image" />
    };


	return (
		<div className='no-overflow' style={{ height: (height-85)+'px' }}>
			<Toast ref={toast} />
			<div>

				<div className={selectedTab === 1 ? 'active' : 'inactive'}>
					{
						product &&

						<div style={{ overflowY: 'scroll', height: (height-85)+'px'}}>
							<div className='w-full px-3 justify-content-center text-center'>
								<div className='flex justify-content-center mt-3'>
									{productImagesDiv()}
								</div>
							</div>

							<div className='mx-3 justify-content-center'>
								<Divider className='flex mt-0' type='dashed' />
								<div className='flex justify-content-center'>
									{product.description}
								</div>
								<Divider className='flex' type='dashed' />
							</div>

							<div className='grid mx-3 mt-3'>
								{
									id === "641ad52ebaea515473de3870" &&
									<div className='col col-6 mb-3'>
										<div className='flex'>
											<h4 className='m-0 p-0'>Brand</h4>
										</div>
										<small className='flex mt-2'>
											<Image className="flex mr-2" imageStyle={{ width: 'auto', height: '20px' }} src={"https://imagizer.imageshack.com/img924/2088/AoKyRK.png"} alt="Image" />
											<span className='flex align-content-center align-items-center'>Rayss</span>
										</small>
									</div>
								}

								<div className='col col-6 mb-3'>
									<div className='flex'>
										<h4 className='m-0 p-0'>Product</h4>
									</div>
									<small className='flex mt-2'>
										<Image className="flex mr-2" imageStyle={{ width: 'auto', height: '20px' }} src={"https://imageshack.com/i/pmLaNNfCp"} alt="Image" />
										<span className='flex align-content-center align-items-center'>{product.name}</span>
									</small>
								</div>

								<div className='col col-6 mb-3'>
									<div className='flex'>
										<h4 className='m-0 p-0'>Collection</h4>
									</div>
									<small className='flex mt-2'>
										<Image className="flex mr-2" imageStyle={{ width: 'auto', height: '20px' }} src={"https://imageshack.com/i/poQgQOPAp"} alt="Image" />
										<span className='flex align-content-center align-items-center'>{product.collection.name}</span>
									</small>
								</div>

								{/* <div className='col col-6 mb-3'>
									<h4 className='m-0 p-0'>Care instructions</h4>
									<small className='flex align-items-start align-content-start mt-2'>
										<div className='flex grid m-0 p-0'>
											{caresDiv()}
										</div>
									</small>
								</div> */}

								<div className='col col-6 mb-3'>
									<div className='flex'>
										<h4 className='m-0 p-0'>Materials</h4>
									</div>
									<small className='flex align-items-start align-content-start mt-2'>
										<Image className="flex mr-2" imageStyle={{ width: 'auto', height: '20px' }} src={"https://imageshack.com/i/pmjxM1oBp"} alt="Image" />
										<div className='flex grid m-0 p-0'>
											{garmentsDiv()}
										</div>
									</small>
								</div>

								<div className='col col-6 mb-3'>
									<div className='flex'>
										<h4 className='m-0 p-0'>Made in</h4>
									</div>
									<small className='flex align-items-start align-content-start mt-2'>
										<Image className="flex mr-2" imageStyle={{ width: 'auto', height: '20px' }} src={"https://imageshack.com/i/ponFtGFSp"} alt="Image" />
										<div className='flex grid m-0 p-0'>
											{countriesDiv()}
										</div>
									</small>
								</div>

								<div className='col col-6 mb-3'>
									<div className='flex align-items-center'>
										<h4 className='m-0 p-0 flex'>NFC ID</h4>
										<Image className="flex ml-2" imageStyle={{ width: 'auto', height: '12px' }} src={"https://imageshack.com/i/pnRkWKYUp"} onClick={() => setDisplayNfcTagModal(true)} />
									</div>
									<small className='flex align-items-start align-content-start mt-2'>
										{
											id.length < 24 &&
											id
										}
										{
											id.length === 24 &&
											<small>This product may not have an associated NFC tag</small>
										}
									</small>
								</div>

								<div className={'col col-6 mb-5 ' + (id !== "641ad52ebaea515473de3870" ? 'col-offset-6' : '')}>
									<div className='flex mt-2 align-items-start'>
										<FontAwesomeIcon icon="arrow-up-right-from-square" className='' />
										<small className='underline ml-2'><a onClick={() => setSelectedTab(2)} style={{ color: 'black' }}>Product traced using<br/> blockchain technology</a></small>
									</div>
								</div>
							</div>
						</div>
					}
				</div>

				<div className={selectedTab === 2 ? 'active' : 'inactive'}>

					<div className="">
						{/* <div id="opaque-map-overlay" style={{height: '100vh', width: '100vw', background: 'white', position: 'absolute', zIndex: '10', opacity: '.5'}}></div> */}
						<div id="mapContainerID" style={{ height: '100vh', background: 'white' }}></div>
					</div>

					<div id="step-info-box" className='shadow-5 pp-step-info-box close-div'>
						{/* {
							(selectedSupplier && !_.isEmpty(selectedSupplier)) &&
							<div className='mx-2'>
								<div className='col-12 text-right pb-0 pt-3'>
									<FontAwesomeIcon icon="times" className='mb-0' onClick={() => setSelectedSupplier({})} />
								</div>
								<div className='grid pb-2 m-0'>
									<div className='col-12 flex align-items-center mb-0 pt-0'>
										<div className='border-round-md'>
											<Image src={selectedSupplier.image.referredFile.url} className="flex" style={{ height: '65px', width: "auto" }} />
										</div>
										<div className='ml-1'>
											<h3 className='my-0 ml-2'>{selectedSupplier.name}</h3>
											{
												selectedSupplier.city !== "" &&

												<div className='col-12 flex align-items-center py-0 mt-1'>
													<h5 className='my-0'>Located in:</h5>
													<small className='ml-2'>
														{mapAddressDiv(selectedSupplier.address)}
													</small>
												</div>
											}
										</div>
									</div>

									<div className='w-full text-center mt-0'>
										{
											!showSupplierAdditionalInfo &&
											<FontAwesomeIcon icon="angle-down" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(true)} />
										}
									</div>

									{
										showSupplierAdditionalInfo &&

										<div className='w-full'>
											<Divider className='' type='dashed' />

											<div className='col-12 align-items-center py-0 mt-3'>
												<h5 className='my-0'>Selected supplier is involved in the following steps:</h5>
												<div className=''>
													{getStepWithSupplier(selectedSupplier)}
												</div>
											</div>
										</div>
									}

									<div className='w-full text-center mt-0'>
										{
											showSupplierAdditionalInfo &&
											<FontAwesomeIcon icon="angle-up" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(false)} />
										}
									</div>
								</div>
							</div>
						} */}

						{
							selectedSupplier && !_.isEmpty(selectedSupplier) &&
							<div className='mx-1'>
								<div className='col-12 text-right pb-0 pt-3'>
									<FontAwesomeIcon icon="times" className='mb-0' onClick={() => setSelectedSupplier({})} />
								</div>
								<div className='col-12 flex align-items-center mb-0 pt-0'>
									<div className='border-round-md'>
										{/* <Image src="https://imagizer.imageshack.com/img922/1111/bU3HcX.png" className="flex ml-2" style={{ height: '85px', width: "auto" }} /> */}
										<Image src={selectedSupplier.image.referredFile.url} className="flex ml-2" width='85px'/>
									</div>
									<div className='ml-4'>
										<h3 className='my-0'>{selectedSupplier.name}</h3>

										<div className='mt-2'>
											<span className='flex align-items-center text-left'>

												<div>
													<Image src={_.unique(getSupplierSteps(selectedSupplier))[0].iconBlack} className="flex mr-2" style={{ height: '25px', width: "" }} />
												</div>	

												<span className='my-0'>{_.unique(getSupplierSteps(selectedSupplier))[0].label}</span>
													
											</span>
										</div>
										<div className='mt-1'>
											<span className='flex align-items-center text-left'>
												<div>
													<Image src="https://imageshack.com/i/ponFtGFSp" className="flex mr-2" style={{ height: '25px', width: "auto" }} />
												</div>
												<span className='my-0'>{mapAddressDiv2(selectedSupplier.address)}</span>
											</span>
										</div>
										{/* <div className='grid mt-2 align-items-center'>
											<div className='col col-12 py-0'>
												<span className='flex align-items-center'>
													
													<Image src={_.unique(getSupplierSteps(selectedSupplier))[0].iconBlack} className="flex mr-2" style={{ height: '25px', width: "auto" }} />
													<span className='my-0'>{_.unique(getSupplierSteps(selectedSupplier))[0].label}</span>
														
												</span>
											</div>
											<div className='col col-12 py-0 mt-1'>
												<span className='flex align-items-center'>
													
													<Image src="https://imageshack.com/i/ponFtGFSp" className="flex mr-2" style={{ height: '25px', width: "auto" }} />
													<span className='my-0'>{mapAddressDiv2(selectedSupplier.address)}</span>
														
												</span>
											</div>

										</div> */}
										
									</div>
								</div>

								<div className='w-full text-center mt-0'>
									{
										!showSupplierAdditionalInfo &&
										<FontAwesomeIcon icon="angle-down" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(true)} />
									}
								</div>

								{
									showSupplierAdditionalInfo &&

									<div className='w-full'>
										<Divider className='' type='dashed' />

										<div className='grid my-2'>
											<div className='col-12 py-0 justify-content-center text-center'>
												<h5 className='mt-2'>We partnered with {selectedSupplier.name}<br/>for the following steps:</h5>
												<div className='flex text-center'>
													{getStepWithSupplier(selectedSupplier)}
												</div>
											</div>
										</div>
									</div>
								}

								<div className='w-full text-center mt-0 mb-2'>
									{
										showSupplierAdditionalInfo &&
										<FontAwesomeIcon icon="angle-up" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(false)} />
									}
								</div>
							</div>
						}

						{
							selectedLink && !_.isEmpty(selectedLink) &&
							<div className='mx-1' style={{maxWidth: '100vw'}}>
								<div className='col-12 text-right pb-0 pt-3'>
									<FontAwesomeIcon icon="times" className='mb-0' onClick={() => setSelectedLink({})} />
								</div>
								<div className='col-12 flex align-items-start mb-0 pt-0'>
							
									<div className='border-round-md'>
										<Image src="https://imageshack.com/i/pm2iTfh2j" className="flex ml-2" style={{ height: '85px', width: "auto" }} />
									</div>
									<div className='ml-3'>
										<h3 className='my-0'>Transportation</h3>
										{
											!_.isEmpty(selectedLink.vehicle) &&
											<>
												<div className='mt-2'>
													<span className='flex align-items-center text-left'>

														<div>
															<Image src={selectedLink.vehicle.iconBlack} className="flex mr-2" style={{ height: '25px', width: "" }} />
														</div>	

														<span className='my-0'>{selectedLink.vehicle.label === "Treno" ? "Train" : 
														selectedLink.vehicle.label === "Aereo" ? "Airplane" :
														selectedLink.vehicle.label === "Nave" ? "Ship" :
														selectedLink.vehicle.label === "Camion" ? "Truck" : ""}</span>
															
													</span>
												</div>
												<div className='mt-1'>
													<span className='flex align-items-center text-left'>
														<div>
															<Image src="https://imageshack.com/i/pnVYNzOQp" className="flex mr-2" style={{ height: '25px', width: "auto" }} />
														</div>
														<span className='my-0'>{selectedLink.distance}</span>
													</span>
												</div>
											</>
										}
										{/* {
											!_.isEmpty(selectedLink.vehicle) &&
											<div className='flex mt-3 align-items-center'>
												<div className='flex py-0'>
													<span className='flex align-items-center'>
														
														<Image src={selectedLink.vehicle.iconBlack} className="flex mr-2" style={{ height: '25px', width: "auto" }} />

														<span className='my-0'>{selectedLink.vehicle.label === "Treno" ? "Train" : 
														selectedLink.vehicle.label === "Aereo" ? "Airplane" :
														selectedLink.vehicle.label === "Nave" ? "Ship" :
														selectedLink.vehicle.label === "Camion" ? "Truck" : ""}</span>
															
													</span>
												</div>
												<div className='flex pt-1 ml-3'>
													<span className='flex align-items-center'>
														<Image src="https://imagizer.imageshack.com/img923/7171/DP4m3v.png" className="flex mr-2" style={{ height: '25px', width: "auto" }} />
														<span className='my-0'>{selectedLink.distance}</span>
													</span>
												</div>
											</div>
										} */}
										
									</div>
								</div>

								<div className='w-full text-center mt-0'>
									{
										!showLinkAdditionalInfo &&
										<FontAwesomeIcon icon="angle-down" className='mr-2' onClick={() => setShowLinkAdditionalInfo(true)} />
									}
								</div>

								{
									showLinkAdditionalInfo &&

									<div className='w-full'>
										<Divider className='' type='dashed' />

										<div className='grid pb-3'>
											<div className='col-12 py-0 justify-content-center text-center'>
												<h5 className='mb-1 mt-3'>{selectedLink.fromSupplier.name}</h5>
												<small className='align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													{mapAddressDiv(selectedLink.fromSupplier.address)}
												</small>
											</div>
											<div className='col-12 justify-content-center text-center'>
												<FontAwesomeIcon icon="arrow-down" className='' />
											</div>
											<div className='col-12 py-0 justify-content-center text-center'>
												<h5 className='mb-1' style={{marginTop: '0px'}}>{selectedLink.toSupplier.name}</h5>
												<small className='align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													{mapAddressDiv(selectedLink.toSupplier.address)}
												</small>
											</div>
										</div>
										{/* <div className='grid grid-nogutter py-3 mx-1'>
											<div className='col-12 grid justify-content-start'>
												<small className='font-italic col-4'>From:</small>
												<div className='col-8'>
													<div className='flex align-items-center mb-0 pb-0'>
														<h5 className='mb-1 mt-0'>{selectedLink.fromSupplier.name}</h5>
													</div>
													<small className='flex align-items-center py-0'>
														<FontAwesomeIcon icon="map-pin" className='mr-2' />
														{mapAddressDiv(selectedLink.fromSupplier.address)}
													</small>
												</div>
											</div>
											<div className='col-12 grid'>
												<small className='font-italic col-4'>To:</small>
												<div className='col-8'>
													<div className='flex align-items-center mb-0 pb-0'>
														<h5 className='mb-1 mt-0'>{selectedLink.toSupplier.name}</h5>
													</div>
													<small className='flex align-items-center py-0'>
														<FontAwesomeIcon icon="map-pin" className='mr-2' />
														{mapAddressDiv(selectedLink.toSupplier.address)}
													</small>
												</div>
											</div>
										</div> */}
									</div>
								}

								<div className='w-full text-center mt-0 mb-2'>
									{
										showLinkAdditionalInfo &&
										<FontAwesomeIcon icon="angle-up" className='mr-2' onClick={() => setShowLinkAdditionalInfo(false)} />
									}
								</div>

								
								{/* <div className='grid mx-1 pb-2 m-0'>

									{
										!_.isEmpty(selectedLink.vehicle) &&
										<>
											<h5 className='my-0 flex align-items-center'>Transport made by </h5>
											<h3 className='flex align-items-center ml-2 my-0'>
												{selectedLink.vehicle.label === "Treno" ? "Train" : 
												selectedLink.vehicle.label === "Aereo" ? "Airplane" :
												selectedLink.vehicle.label === "Nave" ? "Ship" :
												selectedLink.vehicle.label === "Camion" ? "Truck" : ""}
												<Image src={selectedLink.vehicle.iconBlack} className="flex ml-2" style={{ height: '15px', width: "auto" }} />
											</h3>
										</>
									}

									<div className='grid py-3'>
										<div className='col-12 grid justify-content-start'>
											<small className='font-italic col-4'>From:</small>
											<div className='col-8'>
												<div className='flex align-items-center mb-0 pb-0'>
													<h5 className='mb-1 mt-0'>{selectedLink.fromSupplier.name}</h5>
												</div>
												<small className='flex align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													{mapAddressDiv(selectedLink.fromSupplier.address)}
												</small>
											</div>
										</div>
										<div className='col-12 grid'>
											<small className='font-italic col-4'>To:</small>
											<div className='col-8'>
												<div className='flex align-items-center mb-0 pb-0'>
													<h5 className='mb-1 mt-0'>{selectedLink.toSupplier.name}</h5>
												</div>
												<small className='flex align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													{mapAddressDiv(selectedLink.toSupplier.address)}
												</small>
											</div>
										</div>
									</div>

								</div> */}
							</div>
						}
					</div>
				</div>

				<div className={selectedTab === 3 ? 'active' : 'inactive'}>
					{
						(supplyChainSteps && supplyChainSteps.length != 0) &&

						<>
							{customTimelineDiv()}

							<div className='w-full mt-2' style={{ overflowY: 'scroll', height: '73vh', paddingBottom: '100px' }}>
								{customTimelineContentDiv()}
							</div>
						</>
					}
				</div>

				{/* <div id="tab-selection" className="tab-selection shadow-5 border-round-xl">
					<div className="grid w-full mx-0 px-0 shadow-5 border-round-xl">
						<div className={"col col-4 tab pt-3 pb-3 " + (selectedTab === 1 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('info');
							setLastTabOpenedAt(now);

							setSelectedTab(1);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 1 ? "https://imageshack.com/i/pnh7BXYrp" : "https://imageshack.com/i/pmInX4Omp"} alt="Image" />
								<div className='font-medium'>Passport</div>
							</div>
						</div>
						<div className={"col col-4 tab pt-3 pb-3 " + (selectedTab === 2 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('map');
							setLastTabOpenedAt(now);

							setSelectedTab(2);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 2 ? "https://imageshack.com/i/pmf17jB4p" : "https://imageshack.com/i/poLFN8Trp"} alt="Image" />
								<div className='font-medium'>Journey</div>
							</div>
						</div>
						<div className={"col col-4 tab pt-3 pb-3 " + (selectedTab === 3 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('overview');
							setLastTabOpenedAt(now);

							setSelectedTab(3);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 3 ? "https://imageshack.com/i/poIv7Sp4p"
									: "https://imageshack.com/i/pmjxM1oBp"} alt="Image" />
								<div className='font-medium'>Impact</div>
							</div>
						</div>
					</div>
				</div> */}

			</div>

			<Dialog className="modal-header-no-border modal-header-small p-dialog-no-space-around" visible={displaySelectedSupplierModal} modal={true} style={{ width: '85vw', maxHeight: '70vh', overflowY: 'scroll' }}
				draggable={false} resizable={true} position={'top'} onHide={() => setDisplaySelectedSupplierModal(false)}>
				{
					(selectedSupplier && !_.isEmpty(selectedSupplier)) &&
					<div className='grid pb-2 m-0'>
						<div className='col-12 flex align-items-center mb-0 pt-0'>
							<div className='border-round-md'>
								<Image src={selectedSupplier.image.referredFile.url} className="flex" style={{ height: '65px', width: "auto" }} preview />
							</div>
							<div className='ml-1'>
								<h3 className='my-0 ml-2'>{selectedSupplier.name}</h3>
							</div>
						</div>
						<div>
							{
								selectedSupplier.city !== "" &&

								<div className='col-12 flex align-items-start py-0 mt-1'>
									<h5 className='my-0'>Where?</h5>
									<small className='ml-2'>
										{mapAddressDiv(selectedSupplier.address)}
									</small>
								</div>
							}
						</div>

						<div className='w-full text-center mt-2'>
							{
								!showSupplierAdditionalInfo &&
								<FontAwesomeIcon icon="angle-down" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(true)} />
							}
						</div>

						{
							showSupplierAdditionalInfo &&

							<div className='w-full'>
								<Divider className='' type='dashed' />

								<div className='col-12 align-items-center py-0 mt-4'>
									<h5 className='my-0'>Who is {selectedSupplier.name}?</h5>
									<small className=''>{selectedSupplier.description}</small>
								</div>

								<div className='col-12 align-items-center py-0 mt-4'>
									<h5 className='my-3'>To create our product we collaborated with {selectedSupplier.name} for the following steps:</h5>
									<small className=''>
										{getStepWithSupplier(selectedSupplier)}
									</small>
								</div>
							</div>
						}

						<div className='w-full text-center mt-0'>
							{
								showSupplierAdditionalInfo &&
								<FontAwesomeIcon icon="angle-up" className='mr-2' onClick={() => setShowSupplierAdditionalInfo(false)} />
							}
						</div>
					</div>
				}
			</Dialog>

			<Dialog className="modal-header-no-border modal-header-small p-dialog-no-space-around p-dialog-no-top-padding" visible={displaySelectedLinkModal} modal={true} style={{ width: '85vw', maxHeight: '70vh', overflowY: 'scroll' }}
				draggable={false} resizable={true} position={'top'} onHide={() => setDisplaySelectedLinkModal(false)}>
				{
					
					selectedLink && !_.isEmpty(selectedLink) &&
					<div className='grid m-0'>
						<div className='col-12 flex align-items-center mb-0 pt-0'>
							<div className='border-round-md'>
								<Image src="https://imageshack.com/i/poLFN8Trp" className="flex ml-2" style={{ height: '45px', width: "auto" }} />
							</div>
							<div className='ml-3'>
								<h3 className='my-0'>Transport</h3>
								{
									!_.isEmpty(selectedLink.vehicle) &&
									<div className='grid grid-nogutter mt-2'>
										<div className='col col-12 flex py-0'>
											<h5 className='my-0'>Vehicle: </h5>
											<small className='flex align-items-center ml-3'>
												
												<Image src={selectedLink.vehicle.iconBlack} className="flex mr-1" style={{ height: '10px', width: "auto" }} />

												{selectedLink.vehicle.label === "Treno" ? "Train" : 
												selectedLink.vehicle.label === "Aereo" ? "Airplane" :
												selectedLink.vehicle.label === "Nave" ? "Ship" :
												selectedLink.vehicle.label === "Camion" ? "Truck" : ""}
													
											</small>
										</div>
										<div className='col col-12 flex py-0'>
											<h5 className='my-0'>Total: </h5>
											<small className='flex align-items-center ml-3'>{selectedLink.distance}km.</small>
										</div>
									</div>
								}
								
							</div>
						</div>

						<div className='w-full'>
							<Divider className='' type='dashed' />

							<div className='w-full'>

							</div>
							{/* <div className='grid py-3'>
								<div className='col-12 py-0'>
									<div className='flex align-items-center mb-0 pb-0 ml-5'>
										<h5 className='mb-1'>{selectedLink.fromSupplier.name}</h5>
									</div>
									<small className='flex align-items-center py-0 ml-5'>
										<FontAwesomeIcon icon="map-pin" className='mr-2' />
										{mapAddressDiv(selectedLink.fromSupplier.address)}
									</small>
								</div>
								<div className='col-12 justify-content-center text-center'>
									<FontAwesomeIcon icon="arrow-down" className='' />
								</div>
								<div className='col-12 py-0'>
									<div className='flex align-items-center mb-0 pb-0 ml-5'>
										<h5 className='mb-1' style={{marginTop: '0px'}}>{selectedLink.toSupplier.name}</h5>
									</div>
									<small className='flex align-items-center py-0 ml-5'>
										<FontAwesomeIcon icon="map-pin" className='mr-2' />
										{mapAddressDiv(selectedLink.toSupplier.address)}
									</small>
								</div>
							</div> */}
							<div className='grid grid-nogutter py-3 mx-1'>
								<div className='col-12 grid justify-content-start'>
									<small className='font-italic col-4'>From:</small>
									<div className='col-8'>
										<div className='flex align-items-center mb-0 pb-0'>
											<h5 className='mb-1 mt-0'>{selectedLink.fromSupplier.name}</h5>
										</div>
										<small className='flex align-items-center py-0'>
											<FontAwesomeIcon icon="map-pin" className='mr-2' />
											{mapAddressDiv(selectedLink.fromSupplier.address)}
										</small>
									</div>
								</div>
								<div className='col-12 grid'>
									<small className='font-italic col-4'>To:</small>
									<div className='col-8'>
										<div className='flex align-items-center mb-0 pb-0'>
											<h5 className='mb-1 mt-0'>{selectedLink.toSupplier.name}</h5>
										</div>
										<small className='flex align-items-center py-0'>
											<FontAwesomeIcon icon="map-pin" className='mr-2' />
											{mapAddressDiv(selectedLink.toSupplier.address)}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
						
				}
			</Dialog>

			<Dialog className="modal-header-no-border modal-header-small" visible={displayNfcTagModal} modal={true} style={{ width: '95vw' }}
				draggable={false} resizable={true} onHide={() => setDisplayNfcTagModal(false)}>
				<div className='px-3'>
					<small>
						<span className='font-bold'>Check the authenticity of this product</span>

						<div className='grid m-0 p-0 align-items-center'>
							<div className='col-10 px-0'>
								<InputText type="text" className="block w-full border-round-md" placeholder="Type the NFC ID" value={toCheckTagId} onChange={(e) => setToCheckTagId(e.target.value)} />
							</div>
							<Button icon="pi pi-refresh" className="flex-initial flex prisma-green-bg text-blue no-border-btn ml-2 col-2" onClick={() => checkTagNFC()} />
						</div>

						<Divider className='col-6' style={{ width: '50px' }} />

						<div className='font-medium'>
							What is it?
						</div>
						<div className='mt-1' style={{ fontSize: '10px' }}>
							To guarantee authenticity, integrity, and chain of custody, this brand attached an NFC Tag to your product. NFC Tags are harmless microchips carrying a verified Digital Product Passport.
						</div>

					</small>
				</div>
			</Dialog>

			<Dialog className="modal-header-no-border modal-header-small p-dialog-select-tab shadow-5" visible={true} position='bottom' modal={false} style={{ width: '100vw' }}
				draggable={false} resizable={true} showHeader={false}>
					<div className="flex w-full mx-0 px-0 shadow-5" style={{borderTopRightRadius: '10px', borderTopLeftRadius: '10px'}}>
						<div style={{borderTopLeftRadius: '10px'}} className={"col col-4 tab pb-5 pt-3 " + (selectedTab === 1 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('info');
							setLastTabOpenedAt(now);

							setSelectedTab(1);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 1 ? "https://imageshack.com/i/pnh7BXYrp" : "https://imageshack.com/i/pmInX4Omp"} alt="Image" />
								<div className='font-medium'>Passport</div>
							</div>
						</div>
						<div className={"col col-4 tab pb-5 pt-3 " + (selectedTab === 2 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('map');
							setLastTabOpenedAt(now);

							setSelectedTab(2);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 2 ? "https://imageshack.com/i/poJGr6iSp" : "https://imageshack.com/i/pmiNJlH2p"} alt="Image" />
								<div className='font-medium'>Journey</div>
							</div>
						</div>
						<div style={{borderTopRightRadius: '10px'}} className={"col col-4 tab pb-5 pt-3 " + (selectedTab === 3 ? 'selected-tab' : '')} onClick={() => {
							const now = (Date.now());
							setLastTabOpenedName('overview');
							setLastTabOpenedAt(now);

							setSelectedTab(3);
							createAnalytic('TAB_OPENED', {
								productId: product.id,
								tabName: lastTabOpenedName,
								openedAt: lastTabOpenedAt,
								closedAt: now
							})
						}
						}>
							<div className='text-center'>
								<Image imageStyle={{ width: '20px', height: '20px' }} src={selectedTab === 3 ? "https://imageshack.com/i/pm4y0gV1p"
									: "https://imageshack.com/i/pmb0ClD4p"} alt="Image" />
								<div className='font-medium'>Impact</div>
							</div>
						</div>
					</div>
			</Dialog>

			{
				selectedClaim &&

				<Dialog header={(<span className='flex align-items-center'><img className='flex align-items-center mr-2 border-round-md' src={selectedClaim.type.iconBlack} alt="icon" height="25px" />{selectedClaim.name}</span>)} visible={showSelectedClaimDetail} modal={true} style={{ width: '85vw' }}
					draggable={false} resizable={false} onHide={() => setShowSelectedClaimDetail(false)} className="modal-header-no-border">
					<div className='grid'>
						<div className='col col-12'>
							<h6 className='my-0'>This information is verified by: </h6>
						</div>
						<div className='col col-12 mx-2 mb-2 p-0'>
							<div className='flex mr-2 align-items-center' onClick={
								() => {
									createAnalytic('CLICK_ON_DOCUMENT', {
										productId: product.id,
										documentId: selectedClaim.confirmationDoc.id,
										documentName: selectedClaim.confirmationDoc.name,
										date: Date.now()
									})
								}
							}>
								<small><FontAwesomeIcon icon="arrow-up-right-from-square" className='' /></small>
								<div className='underline ml-2'>{selectedClaim.confirmationDoc.name}</div>
							</div>
							<div className='mt-2' style={{marginLeft: '22px'}}>
								<div style={{fontSize: '8px'}}>Notarizzazione su blockchain: <span style={{fontWeight: 'bold'}}>{'https://www.dgfth.it'}</span></div>
								<div style={{fontSize: '8px'}}>Firmato da: <span style={{fontWeight: 'bold'}}>Aqualfi S.p.a</span></div>
							</div>
						</div>

						<div className='col col-12'>
							<h6 className='my-0'>Section: </h6>
						</div>
						<div className='col col-12 mb-2 mx-2 p-0'>
							<div className='flex justify-content-center'>
								<Image preview className="flex text-center" imageStyle={{ maxHeight: '100px', maxWidth: '80vw' }} src={selectedClaim.referredFile.url} alt="Image" />
							</div>
						</div>

						<div className='col col-12'>
							<h6 className='my-0'>Status: </h6>
						</div>
						<div className='col col-12 mb-2 mx-2 p-0'>
							<div className='flex justify-content-start'>
								{
									!_.isEmpty(selectedClaim.confirmationDoc.referredFile) &&
									<Tag className="mr-2 w-full" icon="pi pi-check" value='Verified by third parties' rounded severity='success'></Tag>
								}
							</div>
							{/* <small className='flex mt-2 align-items-center' style={{fontSize: '10px'}}>
                                    <FontAwesomeIcon icon="arrow-up-right-from-square" className=''/>
                                    <div className='underline ml-2'>Aqualfi S.p.a</div>
                                </small> */}
						</div>
					</div>
				</Dialog>
			}
		</div>
	);
}

export default DigitalProductPassport;