import { InputText } from 'primereact/inputtext'
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar'

import _ from 'underscore';
import {sha256} from 'crypto-hash';

import React, { createRef, useRef } from 'react';

import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useState } from 'react';
import { useEffect } from 'react';

//------------------- Refactored code to upload to s3 8-11-2023
import { uploadToS3, uploadFileToDb, uploadMedia } from '../services/RemoteService';
//------------------- Refactored code to upload to s3 


import FileNewEdit from './fileNewEdit';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvY2t2aXNpb25tIiwiYSI6ImNsN3M1eXh1dzAzbm0zcXFucjgycGx6aWIifQ.N30gHRpZicQDW11W2XopZw';

const companySizes = [
    'Small Business','Mid-Market Enterpries','Large Enterprise'
]

const SupplierForm = (props) => {

    const supplier = props.supplier;
    const supplierPosition = props.supplierPosition;
    
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if(activeTab === 0){
            loadMapFirstTime();
        }
    }, [])

    const tabChanged = (index) => {
        setActiveTab(index);
        if(index==0){
            setTimeout(() => {
                loadMapFirstTime();
            }, 200);
        }
    }

    /************************************ INFORMAZIONI ANAGRAFICHE ************************************/

    const onNameChange = (e) => {
        props.onSupplierChange(e.target.value, 'name');
    }

    const onDisplayNameChange = (e) => {
        props.onSupplierChange(e.target.value, 'displayName');
    }
    const onShowDisplayNameChange = (e) => {
        props.onSupplierChange(e.target.value, 'showDisplayName');
    }

    const onCompanyNameChange = (e) => {
        props.onSupplierChange(e.target.value, 'companyName');
    }

    const onVatNumberChange = (e) => {
        props.onSupplierChange(e.target.value, 'vatNumber');
    }

    const onDescriptionChange = (e) => {
        props.onSupplierChange(e.target.value, 'description');
    }

    const onCityChange = (e) => {
        props.onSupplierChange(e.target.value, 'city');
    }
    const onShowCityChange = (e) => {
        props.onSupplierChange(e.target.value, 'showCity');
    }

    const onAddressChange = (e) => {
        props.onSupplierChange(e.target.value, 'address');
    }
    const onShowAddressChange = (e) => {
        props.onSupplierChange(e.target.value, 'showAddress');
    }

    const onProvinceChange = (e) => {
        props.onSupplierChange(e.target.value, 'province');
    }
    const onShowProvinceChange = (e) => {
        props.onSupplierChange(e.target.value, 'showProvince');
    }

    const onEmailChange = (e) => {
        props.onSupplierChange(e.target.value, 'email');
    }

    const onPhoneNumberChange = (e) => {
        props.onSupplierChange(e.target.value, 'phoneNumber');
    }

    const onWebsiteChange = (e) => {
        props.onSupplierChange(e.target.value, 'website');
    }

    const onCompanySizeChange = (e) => {
        props.onSupplierChange(e.target.value, 'companySize');
    }

    const onTotalProductionQuantityChange = (e) => {
        props.onSupplierChange(e.target.value, 'totProductionQuantity');
    }

    const onMinOrderQuantityChange = (e) => {
        props.onSupplierChange(e.target.value, 'minOrderQuantity');
    }

    const onMinOrderQuantityPerStyleChange = (e) => {
        props.onSupplierChange(e.target.value, 'minOrderQuantityPerStyle');
    }

    const onStandardDelayTimeDaysChange = (e) => {
        props.onSupplierChange(e.target.value, 'standardDelayTimeDays');
    }

    const onNumberOfPermanentEmployeesChange = (e) => {
        props.onSupplierChange(e.target.value, 'numberOfPermanentEmployees');
    }

    const onNumberOfMaleEmployeesChange = (e) => {
        props.onSupplierChange(e.target.value, 'numberOfMaleEmployees');
    }

    const onNumberOfFemaleEmployeesChange = (e) => {
        props.onSupplierChange(e.target.value, 'numberOfFemaleEmployees');
    }

    /************************************ MAPPA ************************************/
    const mapSettings = {
        center: [12.496366, 41.902782], 
        zoom: 5,
        projection: 'globe',
        addNavigation: true,
        addGeolocate: true,
        addGeocoder: true,
        addLinesSource: true
    };

    var map = null;

    const [mapMarker, setMapMarker] = useState();

    const loadMapFirstTime = () => {
        map = new mapboxgl.Map({
            container: document.querySelector('#mapContainerID'),
            style: "mapbox://styles/mapbox/light-v9",
            center: mapSettings.center,
            zoom: mapSettings.zoom,
            projection: mapSettings.projection //The map is standard displayed as a globe
        });

        //Adding zoom control and navigation
        if(mapSettings.addNavigation){
            const nav = new mapboxgl.NavigationControl({
                visualizePitch: true
            });
            map.addControl(nav, 'top-left');
        }

        if(mapSettings.addGeolocate){
            map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: false
                },
                trackUserLocation: true,
                showUserHeading: true
            }));
        }

        if(mapSettings.addGeocoder){
            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
            );
        }

        //Adding fog
        map.on('load', () => {
            map.setFog({
                'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
                'space-color':  'white', // Background color
                'star-intensity': 0 // Background star brightness (default 0.35 at low zoooms )
            });
        });

       
        if(supplierPosition === ''){
            if(props.writeEnabled){
                map.on('click', handleMapClick);
            }
        }else{
            const coords = supplierPosition.split(', ')
            const marker = new mapboxgl.Marker({
                color: "rgba(176,162,231,1)",
                draggable: true
            }).setLngLat([coords[0], coords[1]])
            .addTo(map);
    
            if(props.writeEnabled){
                marker.on('dragend', () => {
                    props.onSupplierPositionChange(marker.getLngLat().lng+', '+marker.getLngLat().lat, props.supplier.id)
                })
            }
    
            setMapMarker(marker);
        }
    }

    const handleMapClick = (e) => {
        props.onSupplierPositionChange((e.lngLat.lng+', '+e.lngLat.lat), props.supplier.id)
        const marker = new mapboxgl.Marker({
            color: "rgba(176,162,231,1)",
            draggable: true
        }).setLngLat(e.lngLat)
        .addTo(map);

        marker.on('dragend', () => {
            props.onSupplierPositionChange(marker.getLngLat().lng+', '+marker.getLngLat().lat, props.supplier.id)
        })

        setMapMarker(marker);

        map.off('click', handleMapClick);
    }

    /************************************ MEDIA ************************************/
    const handleMediaUpload = (media) => {
        props.onSupplierChange(media, 'media');
    }

    const handleMediaRemove = (media) => {
        props.onMediaRemove(media, props.supplier.id);
    }

    const handleChangeMediaName = (media, e) => {
        props.onMediaNameChange(media, e.target.value, props.supplier.id);
    }

    const handleChangeMediaDescription = (media, e) => {
        props.onMediaDescriptionChange(media, e.target.value, props.supplier.id);
    }

    const hadleChangeMediaShow = (media, e) => {
        props.onMediaShowChange(media, e.target.value, props.supplier.id);
    }

    /************************************ DOCUMENTS ************************************/
    const handleDocumentUpload = (doc) => {
        props.onSupplierChange(doc, 'documents');
    }

    const handleDocumentRemove = (document) => {
        props.onDocumentRemove(document, props.supplier.id);
    }

    const handleChangeDocumentName = (document, e) => {
        props.onDocumentNameChange(document, e.target.value, props.supplier.id);
    }

    const handleChangeDocumentDescription = (document, e) => {
        props.onDocumentDescriptionChange(document, e.target.value, props.supplier.id);
    }

    const handleChangeDocumentType = (document, e) => {
        props.onDocumentTypeChange(document, e.target.value, props.supplier.id);
    }

    const handleChangeDocumentExpirationDate = (document, e) => {
        props.onDocumentExpirationDateChange(document, e.target.value, props.supplier.id);
    }
    
    /************************************ CLAIMS ************************************/

    const handleClaimUpload = (claim) => {
        props.onSupplierChange(claim, 'claims')
    }

    const handleClaimRemove = (claim) => {
        props.onClaimRemove(claim, props.supplier.id);
    }

    const handleChangeClaimName = (claim, e) => {
        props.onClaimNameChange(claim, e.target.value, props.supplier.id);
    }

    const handleChangeClaimDescription = (claim, e) => {
        props.onClaimDescriptionChange(claim, e.target.value, props.supplier.id);
    }

    const handleChangeClaimType = (claim, e) => {
        props.onClaimTypeChange(claim, e.target.value, props.supplier.id);
    }

    const handleChangeClaimConfirmationDoc = (claim, e) => {
        props.onClaimConfirmationDocChange(claim, e.target.value, props.supplier.id);
    }

    /* Step */

    const items = [
        {
            label: 'Caratteristiche',
            command: (event) => {
                tabChanged(0);
            }
        },
        {
            label: 'Media',
            command: (event) => {
                tabChanged(1);
            }
        },
        {
            label: 'Documenti',
            command: (event) => {
                tabChanged(2);
            }
        },
        {
            label: 'Claims',
            command: (event) => {
                tabChanged(3);
            }
        }
    ];

    const chooseOptions = {icon: 'pi pi-fw pi-images', label: "Seleziona", className: 'prisma-green-bg text-blue no-border'};
    const uploadOptions = {icon: 'pi pi-fw pi-save', label: "Carica", className: 'prisma-green-bg text-blue no-border'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', label: "Svuota", className: 'prisma-green-bg text-blue no-border'};

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }

    const fileItemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>

                    {
                        props.type === 'media' &&
                        <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    }
                    {
                        props.type === 'document' &&
                        <embed type="application/pdf" src={file.objectURL} width="200" height="200" />
                    }
                    
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <div className="px-3 py-2">{props.formatSize} </div>
                <Button type="button" icon="pi pi-times" className="prisma-green-bg text-blue no-border ml-auto" onClick={props.onRemove} />
            </div>
        )
    }

    const [imageLoader, setImageLoader] = useState(false);

    const handleSupplierImageUpload = async (e) => {
        setImageLoader(true);
        const fr = new FileReader();

        fr.onload = async () => {

            const fileHash = await sha256(fr.result);
            const file = e.files[0];
            const fileMimeType = file.type;
            const buffer = await e.files[0].arrayBuffer();
            const fileName = fileHash + '.' + fileMimeType.split('/')[1];
            const s3Path = 'platform-media';

            const fileUrl = await uploadToS3({
                mime_type: fileMimeType, 
                file_name: fileName, 
                s3_path: s3Path,
                bucket_name: 'blockvision-public-media', 
                buffer: buffer});

            if(fileUrl){
                var fileUploadResponse = await uploadFileToDb(null, 'IMAGE', e.files[0].name, fileHash, fileUrl);

            //------------------- End of refactored code

                if(fileUploadResponse.status === 200){
                    var mediaUploadResponse = await uploadMedia(null, null, null, fileUploadResponse.response.data.createFile.id, e.files[0].name, "Image file", true);

                    if(mediaUploadResponse.status === 200){
                        console.log(mediaUploadResponse.response.data.createMedia);
                        props.onSupplierChange(mediaUploadResponse.response.data.createMedia, 'image');
                        setImageLoader(false);
                    }
                }

            }
        }

        fr.readAsText(e.files[0]);
    }
    
    return(
        <div className={(props.fileLoading || props.loading) ? 'inactive' : ''}>
            <div className="card w-full py-5">
                <Steps model={items} activeIndex={activeTab} readOnly={false} />
            </div>

            {
                activeTab === 0 &&
                <div className='grid mx-0 px-3 col align-content-start md:justify-content-center col-12' style={props.modal ? {} : {height: '70vh', overflow: 'scroll'}}>

                    <div className={'grid mx-0 ' + (props.modal ? 'col-12' : 'col-12 lg:col-6')}>
                        {
                            imageLoader &&
                            <div className='px-3 col-12 grid align-content-center justify-content-center'>
                                <div className='col-9'>
                                    <h3>E' in corso il caricamento dell'immagine. Non ricaricare la pagina.</h3>
                                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                </div>
                            </div>
                        }
                        {
                            !imageLoader &&
                            <>
                                {
                                    supplier.image &&
                                    <div className='flex col col-4 justify-content-center align-items-center'>
                                        <Image preview className="flex justify-content-center align-items-center" imageStyle={{width: 'auto', height: 'auto', borderRadius: '10px', maxWidth: '150px', maxHeight: '150px'}} src={supplier.image.referredFile.url} alt="Image"/>
                                    </div>
                                }
                                {
                                    !supplier.image &&
                                    <div className='flex col col-4 justify-content-center align-items-center'>
                                        <small className='text-center'>Questo fornitore non ha ancora un'immagine profilo.</small>
                                    </div>
                                }

                                <div className='col col-8'>
                                    <Tooltip target=".target-icon-descr" style={{fontSize: '10px'}}/>
                                    <FileUpload accept={props.type === 'document' ? ".pdf" : ".png, .jpeg, .jpg, .mp4"} maxFileSize={10000000}
                                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                                        emptyTemplate={<p className="m-0">Scegli una immagine per il fornitore.</p>} headerTemplate={headerTemplate} itemTemplate={fileItemTemplate}
                                        customUpload uploadHandler={handleSupplierImageUpload}/>
                                    <small>{props.instructions}</small>
                                </div>
                            </>
                        }

                        <Divider className='divider-blue ml-2 mr-5' align="left" type="dashed">
                            <small className='text-blue'>Generali</small>
                        </Divider>

                        <div className='col col-6'>
                            <h5 className='mb-1'>Nome *</h5>
                            <InputText type="text" className="block w-full" placeholder="Nome" value={supplier.name} onChange={onNameChange} disabled={!props.writeEnabled}/>
                        </div>
                        
                        <div className='col col-4'>
                            <Tooltip target=".target-icon-displayName" style={{fontSize: '10px'}}/>
                            <h5 className='mb-1 flex justify-content-between flex-wrap'>Nome mostrato<i className="pi pi-info-circle target-icon-displayName" data-pr-tooltip="Il nome che verrà mostrato all'utente finale" data-pr-position="top" ></i></h5>
                            <InputText type="text" className="block w-full" placeholder="Nome mostrato" value={supplier.displayName} onChange={onDisplayNameChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-2 justify-content-center mt-5 mx-0 px-0'>
                            <div className='mt-3 flex align-items-center'>
                                <InputSwitch className='mr-2' checked={supplier.showDisplayName} onChange={onShowDisplayNameChange}  disabled={!props.writeEnabled}/>

                                {
                                    supplier.showDisplayName &&
                                    <div>
                                        <Tooltip target=".target-icon-showDisplayName" style={{fontSize: '10px'}}/>
                                        <i className="pi pi-eye target-icon-showDisplayName" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                    </div>
                                }
                                {
                                    !supplier.showDisplayName &&
                                    <div>
                                        <Tooltip target=".target-icon-hideDisplayName" style={{fontSize: '10px'}}/>
                                        <i className="pi pi-eye-slash target-icon-hideDisplayName" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='col col-6'>
                            <h5 className='mb-1'>Ragione sociale</h5>
                            <InputText type="text" className="block w-full" placeholder="Ragione sociale" value={supplier.companyName} onChange={onCompanyNameChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6'>
                            <h5 className='mb-1'>P.IVA</h5>
                            <InputText type="text" className="block w-full" placeholder="P.IVA" value={supplier.vatNumber} onChange={onVatNumberChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-12'>
                            <h5 className='mb-1'>Descrizione *</h5>
                            <InputTextarea rows={5} type="text" className="block w-full" placeholder="Descrizione" value={supplier.description} onChange={onDescriptionChange} disabled={!props.writeEnabled}/>
                        </div>

                    </div> 

                    <div className={'px-0 ' + (props.modal? 'col-10 col-offset-1 text-center' : 'col-12 lg:col-6')}>
                    
                        <div id="mapContainerID" style={props.modal ? {height: '50vh'} : {height: '50vh'}} className="mt-3 mx-auto"></div>
                        {
                            props.writeEnabled &&
                            
                            <Message className="mt-2 w-full justify-content-start" severity="info" text="Clicca sulla mappa per posizionare il puntatore." />
                        }
                    </div>

                    <div className='grid col-12'>
                        <div className='col col-4 grid mx-0 px-0'>
                            <div className='col col-9 pr-2'>
                                <h5 className='mb-1'>Città</h5>
                                <InputText type="text mb-2" className="block w-full" placeholder="Città" value={supplier.city} onChange={onCityChange} disabled={!props.writeEnabled}/>
                            </div>

                            <div className='col col-3 mt-5 mx-0 px-0'>
                                <div className='mt-3 flex align-items-center'>
                                    <InputSwitch className='mr-2' checked={supplier.showCity} onChange={onShowCityChange}  disabled={!props.writeEnabled}/>

                                    {
                                        supplier.showCity &&
                                        <div>
                                            <Tooltip target=".target-icon-showCity" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye target-icon-showCity" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                    {
                                        !supplier.showCity &&
                                        <div>
                                            <Tooltip target=".target-icon-hideCity" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye-slash target-icon-hideCity" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col col-4 grid mx-0 px-0'>
                            <div className='col col-9 pr-2'>
                                <h5 className='mb-1'>Via e numero</h5>
                                <InputText type="text mb-2" className="block w-full" placeholder="Via e numero" value={supplier.address} onChange={onAddressChange} disabled={!props.writeEnabled}/>
                            </div>

                            <div className='col col-3 mt-5 mx-0 px-0'>
                                <div className='mt-3 flex align-items-center'>
                                    <InputSwitch className='mr-2' checked={supplier.showAddress} onChange={onShowAddressChange}  disabled={!props.writeEnabled}/>

                                    {
                                        supplier.showAddress &&
                                        <div>
                                            <Tooltip target=".target-icon-showAddress" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye target-icon-showAddress" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                    {
                                        !supplier.showAddress &&
                                        <div>
                                            <Tooltip target=".target-icon-hideAddress" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye-slash target-icon-hideAddress" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col col-4 grid mx-0 px-0'>
                            <div className='col col-9 pr-2'>
                                <h5 className='mb-1'>Provincia (sigla)</h5>
                                <InputText type="text mb-2" className="block w-full" placeholder="Provincia" value={supplier.province} onChange={onProvinceChange} disabled={!props.writeEnabled}/>
                            </div>

                            <div className='col col-3 mt-5 mx-0 px-0'>
                                <div className='mt-3 flex align-items-center'>
                                    <InputSwitch className='mr-2' checked={supplier.showProvince} onChange={onShowProvinceChange}  disabled={!props.writeEnabled}/>

                                    {
                                        supplier.showProvince &&
                                        <div>
                                            <Tooltip target=".target-icon-showProvince" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye target-icon-showProvince" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                    {
                                        !supplier.showProvince &&
                                        <div>
                                            <Tooltip target=".target-icon-showProvince" style={{fontSize: '10px'}}/>
                                            <i className="pi pi-eye-slash target-icon-showProvince" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid col-12'>
                        <Divider className='divider-blue ml-2' align="left" type="dashed">
                            <small className='text-blue'>Contatti</small>
                        </Divider>

                        <div className='col col-6 lg:col-4 pr-5'>
                            <h5 className='mb-1'>Email *</h5>
                            <InputText type="text" className="block w-full" placeholder="Email" value={supplier.email} onChange={onEmailChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4 pr-5'>
                            <h5 className='mb-1'>Numero di telefono *</h5>
                            <InputText type="text" className="block w-full" placeholder="Numero" value={supplier.phoneNumber} onChange={onPhoneNumberChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4 pr-5'>
                            <h5 className='mb-1'>Sito web *</h5>
                            <InputText type="text" className="block w-full" placeholder="Sito" value={supplier.website} onChange={onWebsiteChange} disabled={!props.writeEnabled}/>
                        </div>
                    </div>

                    <div className='grid  col-12'>
                        <Divider className='divider-blue ml-2' align="left" type="dashed">
                            <small className='text-blue'>Specifiche</small>
                        </Divider>
                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Dimensione dell'azienda</h5>
                            <Dropdown className="w-full"  value={supplier.companySize} options={companySizes} onChange={onCompanySizeChange} placeholder="Seleziona una dimensione" disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Produzione totale</h5>
                            <InputText type="number" className="block w-full" placeholder="Produzione totale" value={supplier.totProductionQuantity} onChange={onTotalProductionQuantityChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Qta minima ordinabile</h5>
                            <InputText type="number" className="block w-full" placeholder="Quantità minima ordinabile" value={supplier.minOrderQuantity} onChange={onMinOrderQuantityChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Qta minima ordinabile per tipo</h5>
                            <InputText type="number" className="block w-full" placeholder="Quantità minima ordinabile per tipo" value={supplier.minOrderQuantityPerStyle} onChange={onMinOrderQuantityPerStyleChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <Tooltip target=".target-icon-standardDelayTimeDays" style={{fontSize: '10px'}}/>
                            <h5 className='mb-1 flex justify-content-between flex-wrap'>Tempo di attesa<i className="pi pi-info-circle target-icon-standardDelayTimeDays" data-pr-tooltip="Tempo standard di attesa, in giorni, prima che l'ordine venga soddisfatto" data-pr-position="top" ></i></h5>
                            <InputText type="number" className="block w-full" placeholder="Tempo di attesa" value={supplier.standardDelayTimeDays} onChange={onStandardDelayTimeDaysChange} disabled={!props.writeEnabled}/>
                        </div>

                        
                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Dipendenti totali</h5>
                            <InputText type="number" className="block w-full" placeholder="Dipendenti totali" value={supplier.numberOfPermanentEmployees} onChange={onNumberOfPermanentEmployeesChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Dipendenti uomini</h5>
                            <InputText type="number" className="block w-full" placeholder="Dipendenti uomini" value={supplier.numberOfMaleEmployees} onChange={onNumberOfMaleEmployeesChange} disabled={!props.writeEnabled}/>
                        </div>

                        <div className='col col-6 lg:col-4'>
                            <h5 className='mb-1'>Dipendenti donne</h5>
                            <InputText type="number" className="block w-full" placeholder="Dipendenti donne" value={supplier.numberOfFemaleEmployees} onChange={onNumberOfFemaleEmployeesChange} disabled={!props.writeEnabled}/>
                        </div>

                        
                    </div>

                </div>
            }

            {
                activeTab === 1 &&
                <div className='col col-12 px-5 grid mx-0' style={{height: '70vh', overflow: 'scroll'}}>
                    <FileNewEdit 
                        file={supplier.media}
                        type="media"
                        writeEnabled={props.writeEnabled}
                        onNameChange={() => handleChangeMediaName}
                        onDescriptionChange={() => handleChangeMediaDescription}
                        onShowChange={() => hadleChangeMediaShow}
                        onUpload={handleMediaUpload}
                        onRemove={() => handleMediaRemove}
                        onFileLoaderActive={(value) => props.onFileLoaderActive(value)}
                        instructions={<span>Cliccando il tasto <b>Seleziona</b> è possibile selezionare uno o più media dal file system. Una volta selezionati
                        cliccare il tasto <b>Carica</b> per aggiungerli ai media correnti. Se si desidera aggiungere altri media cliccare il tasto <b>Svuota</b> e poi ripetere la procedura.</span>}
                    ></FileNewEdit>
                </div> 
            }
        
            {
                activeTab === 2 &&
                <div className='col col-12 px-5 grid mx-0' style={{height: '70vh', overflow: 'scroll'}}>

                    <FileNewEdit 
                        file={supplier.documents}
                        type="document"
                        writeEnabled={props.writeEnabled}
                        onNameChange={() => handleChangeDocumentName}
                        onDescriptionChange={() => handleChangeDocumentDescription}
                        onTypeChange={() => handleChangeDocumentType}
                        onExpirationDateChange={() => handleChangeDocumentExpirationDate}
                        onUpload={handleDocumentUpload}
                        onRemove={() => handleDocumentRemove}
                        onFileLoaderActive={(value) => props.onFileLoaderActive(value)}
                        instructions={<span>Cliccando il tasto <b>Seleziona</b> è possibile selezionare uno o più documenti dal file system. Una volta selezionati
                        cliccare il tasto <b>Carica</b> per aggiungerli ai file correnti. Se si desidera aggiungere altri file cliccare il tasto <b>Svuota</b> e poi ripetere la procedura.</span>}
                    ></FileNewEdit>
                    
                </div> 
            }

            {
                activeTab === 3 &&
                <div className='col col-12 px-5 grid mx-0' style={{height: '70vh', overflow: 'scroll'}}>

                    <FileNewEdit 
                        file={supplier.claims}
                        documents={supplier.documents}
                        type="claim"
                        writeEnabled={props.writeEnabled}
                        onNameChange={() => handleChangeClaimName}
                        onDescriptionChange={() => handleChangeClaimDescription}
                        onTypeChange={() => handleChangeClaimType}
                        onConfirmationDocChange={() => handleChangeClaimConfirmationDoc}
                        onUpload={handleClaimUpload}
                        onRemove={() => handleClaimRemove}
                        onFileLoaderActive={(value) => props.onFileLoaderActive(value)}
                        instructions={<span>Una volta caricato lo screenshot e compilati i campi usare il pulsante <b>Carica</b> per aggiungere il claim. 
                        Per aggiungere i successivi usare il pulsante <b>X</b> per rimuovere il file corrente e ripetere l'operazione.</span>}
                    ></FileNewEdit>

                    
                </div> 
            }
        </div>
    )
}

export default SupplierForm


