import { v4 as uuid } from 'uuid';

import GeneralInformationForm from '../components/generalInformationForm'
import SupplyChainStepForm from '../components/supplyChainStepForm'
import TransportFlowGraph from '../components/flow/transportFlowGraph';

import { SplitButton } from 'primereact/splitbutton'
import { Button } from 'primereact/button'
import { Timeline } from 'primereact/timeline';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Badge } from 'primereact/badge';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { Steps } from 'primereact/steps';
import FileNewEdit from '../components/fileNewEdit';


import { deleteProduct, saveSupplyChain, getSupplyChains } from '../services/StateService';
import {
	deleteProductMedia, getProducts, updateMedia, updateProduct, uploadProduct,
	getSuppliers, uploadSupplyChainStep, updateSupplyChainStep, deleteProductSupplyChainStep,
	uploadSupplyChainLink, updateSupplyChainLink, deleteProductSupplyChainLink
} from '../services/RemoteService';
import { productViewToModel, productModelToView, supplierModelToView, supplyChainStepViewToModel, supplyChainLinkViewToModel } from '../services/DbUtils';
import { getMidPoint } from '../services/UtilsService';

import React from 'react'
import _ from 'underscore'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmxvY2t2aXNpb25tIiwiYSI6ImNsN3M1eXh1dzAzbm0zcXFucjgycGx6aWIifQ.N30gHRpZicQDW11W2XopZw';

var geojson = {
	'type': 'FeatureCollection',
	'features': [
	]
};

const ProductNewEdit = () => {

	const auth = localStorage.getItem('PRISMA_TOKEN')
	const navigate = useNavigate();

	useEffect(() => {
		if (!auth) {
			navigate("/login");
		}
	}, [])

	const { id } = useParams();

	/* Tab selection */
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	/* const changeTabIndex = (e) => {
		setActiveTabIndex(e.index);
		if(e.index === 3){
			setTimeout(() => {
				loadMapFirstTime();
			}, 200);
		}
	} */

	const changeTabIndex = (index) => {
		setActiveTabIndex(index);
		if (index === 4) {
			setTimeout(() => {
				loadMapFirstTime();
			}, 200);
		}
	}

	const [suppliers, setSuppliers] = useState([]);
	const [supplyChains, setSupplyChains] = useState([]);

	useEffect(() => {
		const supplyChains = getSupplyChains();
		setSupplyChains(supplyChains);

		/* const links = JSON.parse(getConnectionLinks());
		if(links){
			setSupplyChainLinks(links);
		} */
	}, []);

	useEffect(() => {
		if (id) {

			const fetchProduct = async () => {
				const productByIdResponse = await getProducts({ "id": id });
				if (productByIdResponse.status === 200) {
					const viewProduct = productModelToView(productByIdResponse.response.data.products[0]);
					setGeneralInformations({
						'id': viewProduct.id,
						'idClient': viewProduct.idClient,
						'idUser': viewProduct.idUser,
						'name': viewProduct.name,
						'description': viewProduct.description,
						'technicalDescription': viewProduct.technicalDescription,
						'collection': viewProduct.collection,
						'countries': viewProduct.countries,
						'price': viewProduct.price,
						'sizes': viewProduct.sizes,
						'colors': viewProduct.colors,
						'garments': viewProduct.garments,
						'careAndMantainance': viewProduct.careAndMantainance,
						'media': viewProduct.media,
					});
					setSupplyChainSteps(viewProduct.supplyChainSteps);
					setSupplyChainLinks(viewProduct.supplyChainLinks);
				}
			};

			const fetchSuppliers = async () => {
				const suppliersResponse = await getSuppliers(null);
				if (suppliersResponse.status === 200) {

					var viewSuppliers = _.map(suppliersResponse.response.data.suppliers, (supp) => {
						return supplierModelToView(supp);
					})
					setSuppliers(viewSuppliers);
				}
			};

			fetchProduct();
			fetchSuppliers();
		} else {
			setGeneralInformations(
				{
					'name': "",
					'description': "",
					'technicalDescription': "",
					'collection': {},
					'countries': [],
					'price': null,
					'sizes': [],
					'colors': [],
					'garments': [],
					'careAndMantainance': [],
					'media': [],
				}
			)
		}
	}, [id])

	/******************************* INFORMAZIONI GENERALI *******************************/
	const [generalInformations, setGeneralInformations] = useState({})

	/* Handles changes in the general information form tab */
	const handleGeneralInformationChange = (value, propKey) => {
		if (propKey === 'media') {
			const generalInformationsCopy = { ...generalInformations };
			generalInformationsCopy[propKey].push(value);
			setGeneralInformations(generalInformationsCopy);
			setFileLoading(false);
		} else {
			setGeneralInformations(prevState => ({
				...prevState,
				[propKey]: value
			}));
		}
	}

	/******************************* STEP DI FILIERA *******************************/

	const [supplyChainStepTypes, setSupplyChainStepTypes] = useState([
		{
			'code': 1,
			'label': 'Retailer',
			'iconBlack': 'https://imageshack.com/i/pmi8BZn4p',
			'iconWhite': 'https://imageshack.com/i/pmkiOoa7p'
		},
		{
			'code': 2,
			'label': 'Design',
			'iconBlack': 'https://imageshack.com/i/pmEdwEW0p',
			'iconWhite': 'https://imageshack.com/i/poiU9BQ3p'
		},
		{
			'code': 3,
			'label': 'Packaging & Label',
			'iconBlack': 'https://imageshack.com/i/pmbVPLI5p',
			'iconWhite': 'https://imageshack.com/i/pnsLF87Rp'
		},
		{
			'code': 4,
			'label': 'Manufacture',
			'iconBlack': 'https://imageshack.com/i/pmO5domnp',
			'iconWhite': 'https://imageshack.com/i/powaSuzjp'
		},
		{
			'code': 5,
			'label': 'Trims & Components',
			'iconBlack': 'https://imageshack.com/i/poBb5ZwCp',
			'iconWhite': 'https://imageshack.com/i/pors6ipFp'
		},
		{
			'code': 6,
			'label': 'Fabric',
			'iconBlack': 'https://imageshack.com/i/po4FeI8rp',
			'iconWhite': 'https://imageshack.com/i/pmCh3ZI3p'
		},
		{
			'code': 7,
			'label': 'Pre-Processing',
			'iconBlack': 'https://imageshack.com/i/poqjiWzWp',
			'iconWhite': 'https://imageshack.com/i/pmva4qX7p'
		},
		{
			'code': 8,
			'label': 'Raw Materials',
			'iconBlack': 'https://imageshack.com/i/pnO3GVxMp',
			'iconWhite': 'https://imageshack.com/i/po9BZcDOp'
		},
		{
			'name': '',
			'code': 9,
			'icon': {},
		},
	]);

	const [supplyChainSteps, setSupplyChainSteps] = useState([]);

	const [supplyChainLinks, setSupplyChainLinks] = useState([]);

	const getSupplyChainStepByType = (code) => {
		return _.filter(supplyChainSteps, (step) => {
			return step.type.code === code;
		})
	}

	const addSupplyChainStep = async (typeCode, name) => {
		const type = _.find(supplyChainStepTypes, (type) => {
			return type.code === typeCode;
		})

		const newStep = {
			"description": "",
			"name": name,
			"type": {
				"iconBlack": type.iconBlack,
				"iconWhite": type.iconWhite,
				"label": type.label,
				"code": type.code
			}
		}

		const uploadStepResponse = await uploadSupplyChainStep(newStep);
		if (uploadStepResponse.status === 200) {
			const supplyChainStepsCopy = [...supplyChainSteps];
			const viewStep = { ...uploadStepResponse.response.data.createSupplyChainStep };
			viewStep.owner = {};
			viewStep.processorSupplier = {};
			viewStep.originSupplier = {};
			viewStep.traderSupplier = {};
			supplyChainStepsCopy.push(viewStep);
			setSupplyChainSteps(supplyChainStepsCopy);
		}

	}

	const [removedSupplyChainSteps, setRemovedSupplyChainSteps] = useState([]);
	const removeSupplyChainStep = (id) => {
		const supplyChainStepsCopy = _.reject(supplyChainSteps, (step) => {
			return step.id === id;
		})

		const removedSupplyChainStepsCopy = [...removedSupplyChainSteps];
		removedSupplyChainStepsCopy.push(id);
		setRemovedSupplyChainSteps(removedSupplyChainStepsCopy);

		setSupplyChainSteps(supplyChainStepsCopy);
	}

	const [showSupplyChainStepEditDialog, setShowSupplyChainStepEditDialog] = useState();
	const [selectedSupplyChainStep, setSelectedSupplyChainStep] = useState({});

	const editSupplyChainStep = (id) => {
		const step = _.find(supplyChainSteps, (step) => {
			return step.id === id;
		})
		setSelectedSupplyChainStep(step);
		setShowSupplyChainStepEditDialog(true);
	}

	const [displayEditSupplyChainStepName, setDisplayEditSupplyChainStepName] = useState(false);
	const [selectedType, setSelectedType] = useState({});
	const [newStepName, setNewStepName] = useState('');

	const addSupplyChainStepWithType = () => {
		addSupplyChainStep(selectedType, newStepName)
		setDisplayEditSupplyChainStepName(false);
		setNewStepName('');
	}

	const supplyChainTypeMarkerTemplate = (item, index) => {
		const content = !_.isEmpty(item.iconBlack) ? (
			<div className='justify-content-center text-center mt-3' >
				<div className='' style={_.contains([1, 2, 4, 6, 7, 8], item.code) ? { marginTop: '25px' } : { marginTop: '8px' }}>
					<span style={{ fontWeight: 'bold', fontSize: '10px' }}>{item.label}</span>
					<span className="custom-marker shadow-1 p-3 mx-3 mb-5" style={{ backgroundColor: _.includes([8, 7, 6, 5], item.code) ? "#829356" : (_.includes([4, 3], item.code) ? "#c02f1d" : "#0c374d"), cursor: 'pointer' }}>
						<Image className='flex align-items-center' src={item.iconWhite} alt="" width="25px" height="25px" />
					</span>
				</div>
			</div>
		) : (<span></span>)
		return (
			content
		);
	}

	const supplyChainTypeItemTemplate = (item) => {
		const steps = getSupplyChainStepByType(item.code);

		let content = [];

		for (let step of steps) {
			content.push(
				<div key={step.id} className="mb-5 px-1 py-2">
					<div className='shadow-2 border-round-md grid px-2 py-3 mb-3 mt-1'>
						<div className='col-12 justify-content-between w-full flex p-0'>
							<Button className="p-button-rounded p-button-text" style={{ color: 'black' }} icon="pi pi-pencil" onClick={() => editSupplyChainStep(step.id)} />
							<Button className="p-button-rounded p-button-text" style={{ color: 'black' }} icon="pi pi-times" onClick={() => removeSupplyChainStep(step.id)} />
						</div>
						<span className='col-12'>{step.name}</span>
					</div>
					{
						!_.isEmpty(step.originSupplier) &&
						<div className='px-0 grid mx-2'>
							<div className='col-1 p-0' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
							<div className='col-11 pl-2 p-0 pt-3'>
								<FontAwesomeIcon icon="seedling" className='mr-3' />
								<small>{step.originSupplier.name}</small>
							</div>
						</div>
					}
					{
						!_.isEmpty(step.processorSupplier) &&
						<div className='px-0 grid mx-2'>
							<div className='col-1 p-0' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
							<div className='col-11 pl-2 p-0 pt-3'>
								<FontAwesomeIcon icon="industry" className='mr-3' />
								<small>{step.processorSupplier.name}</small>
							</div>
						</div>
					}
					{
						!_.isEmpty(step.traderSupplier) &&
						<div className='px-0 grid mx-2'>
							<div className='col-1 p-0' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
							<div className='col-11 pl-2 p-0 pt-3'>
								<FontAwesomeIcon icon="right-left" className='mr-3' />
								<small>{step.traderSupplier.name}</small>
							</div>
						</div>
					}
					{
						!_.isEmpty(step.owner) &&
						<div className='px-0 grid mx-2'>
							<div className='col-1 p-0' style={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}></div>
							<div className='col-11 pl-2 p-0 pt-3'>
								<FontAwesomeIcon icon="lightbulb" className='mr-3' />
								<small>{step.owner.name}</small>
							</div>
						</div>
					}
				</div>
			);
		}

		return (
			<div className='grid p-0 mx-1 justify-content-center' style={{ height: '50vh', overflow: 'scroll' }}>
				{
					steps.length !== 0 &&

					<div className='col col-12'>
						{content}
					</div>
				}

			</div>
		)
	}

	const addStepSplitButtonItems = [
		{
			label: 'Retailer',
			command: () => {
				setSelectedType(1);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Design',
			command: () => {
				setSelectedType(2);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Packaging & Label',
			command: () => {
				setSelectedType(3);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Manufacture',
			command: () => {
				setSelectedType(4);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Trims & Components',
			command: () => {
				setSelectedType(5);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Fabric',
			command: () => {
				setSelectedType(6);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Pre-Processing',
			command: () => {
				setSelectedType(7);
				setDisplayEditSupplyChainStepName(true);
			}
		},
		{
			label: 'Raw Materials',
			command: () => {
				setSelectedType(8);
				setDisplayEditSupplyChainStepName(true);
			}
		}
	];

	const orderSteps = (steps) => {
		return _.sortBy(steps, (step) => {
			return step.code;
		})
	}


	const [displayEditSupplyChainName, setDisplayEditSupplyChainName] = useState(false);
	const [currentSupplyChainName, setCurrentSupplyChainName] = useState('');
	const [currentSupplyChain, setCurrentSupplyChain] = useState();

	const [showChooseSupplyChain, setShowChooseSupplyChain] = useState(false);
	const showHideChooseSupplyChain = () => {
		if (showChooseSupplyChain && !_.isEmpty(currentSupplyChain)) {
			setCurrentSupplyChain({})
			setSupplyChainSteps([]);
		}
		setShowChooseSupplyChain(!showChooseSupplyChain);
	}

	useEffect(() => {
		if (!_.isEmpty(currentSupplyChain)) {
			setSupplyChainSteps(currentSupplyChain.steps);
		}
	}, [currentSupplyChain])

	const saveCurrentSupplyChain = () => {
		const currentSupplyChainCopy = { ...currentSupplyChain };
		currentSupplyChainCopy.name = currentSupplyChainName !== '' ? currentSupplyChainName : currentSupplyChainCopy.name;
		currentSupplyChainCopy.steps = supplyChainSteps;
		setCurrentSupplyChain(currentSupplyChainCopy);

		saveSupplyChain(currentSupplyChainCopy);
		setDisplayEditSupplyChainName(false);
	}

	const [loading, setLoading] = useState(false);

	const saveProduct = async () => {
		const modelProduct = productViewToModel(generalInformations, supplyChainSteps, supplyChainLinks);
		console.log("Model product: ", modelProduct);
		var response = null;

		_.each(removedMedia, async (rm) => {
			await deleteProductMedia(rm, id);
		})

		_.each(removedSupplyChainSteps, async (rscs) => {
			await deleteProductSupplyChainStep(rscs, id);
		})

		_.each(removedSupplyChainLinks, async (rscl) => {
			await deleteProductSupplyChainLink(rscl, id);
		})

		_.each(supplyChainSteps, async (scs) => {
			let tmp = { ...scs }
			const modelStep = supplyChainStepViewToModel(tmp);
			await updateSupplyChainStep(modelStep, scs.id)
		})

		_.each(supplyChainLinks, async (scl) => {
			let tmp = { ...scl }
			const modelLink = supplyChainLinkViewToModel(tmp);
			await updateSupplyChainLink(modelLink, scl.id)
		})

		if (id) {
			response = await updateProduct(modelProduct, id);
		} else {
			response = await uploadProduct(modelProduct);
		}
		if (response.status === 200) {
			navigate('/products')
		}

		//const modelProduct = productViewToModel(generalInformations, supplyChainSteps, supplyChainLinks);
	}

	const deleteCurrentProduct = () => {
		if (id) {
			confirmDialog({
				message: 'Sicuro di voler cancellare il prodotto? L\'operazione non sarà reversibile',
				header: 'Cancella prodotto',
				icon: 'pi pi-info-circle',
				acceptClassName: 'p-button-danger',
				accept,
				reject
			});

		}
	}

	const accept = () => {
		deleteProduct(id);
	}

	const reject = () => {
		/* ROUTING ALLA LISTA PRODOTTI */
	}

	const supplyChainToolbarRightContent = (
		<React.Fragment>
			{/* DA SOSTITUIRE CON LA SCELTA DI UN ALTRO PRODOTTO */}
			{/* <h5 className='flex mr-5' onClick={() => showHideChooseSupplyChain()}>Scegli una filiera già salvata<FontAwesomeIcon icon="file-import" className='ml-2'/></h5>
            <div className="flex">
                <Dropdown value={currentSupplyChain} options={supplyChains} optionLabel="name" onChange={(e) => setCurrentSupplyChain(e.target.value)} placeholder=""/>
            </div> */}
		</React.Fragment>
	)

	/******************************* MAPPA DI FILIERA *******************************/
	const mapSettings = {
		center: [12.496366, 41.902782],
		zoom: 5,
		projection: 'globe',
		addNavigation: true,
		addGeolocate: true,
		addGeocoder: true,
		addLinesSource: true
	};

	var map = null;

	const [mapMarker, setMapMarker] = useState();

	const loadMapFirstTime = () => {
		map = new mapboxgl.Map({
			container: document.querySelector('#mapContainerID'),
			style: "mapbox://styles/mapbox/light-v9",
			center: mapSettings.center,
			zoom: mapSettings.zoom,
			projection: mapSettings.projection //The map is standard displayed as a globe
		});

		//Adding zoom control and navigation
		if (mapSettings.addNavigation) {
			const nav = new mapboxgl.NavigationControl({
				visualizePitch: true
			});
			map.addControl(nav, 'top-left');
		}

		if (mapSettings.addGeolocate) {
			map.addControl(new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: false
				},
				trackUserLocation: true,
				showUserHeading: true
			}));
		}

		if (mapSettings.addGeocoder) {
			map.addControl(
				new MapboxGeocoder({
					accessToken: mapboxgl.accessToken,
					mapboxgl: mapboxgl
				})
			);
		}

		//Adding fog
		map.on('load', () => {
			map.setFog({
				'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
				'space-color': 'white', // Background color
				'star-intensity': 0 // Background star brightness (default 0.35 at low zoooms )
			});

			map.addSource('TransportLine', {
				'type': 'geojson',
				'data': geojson
			});

			map.addLayer({
				'id': 'TransportLine',
				'type': 'line',
				'source': 'TransportLine',
				'layout': {
					'line-join': 'round',
					'line-cap': 'round'
				},
				'paint': {
					'line-color': 'grey',
					'line-width': 1
				}
			});

			if (supplyChainSteps.length > 0) {

				for (var i = 0; i < supplyChainSteps.length; i++) {
					var suppliers = [];
					if (!_.isEmpty(supplyChainSteps[i].originSupplier)) {
						if (!_.any(suppliers, (s) => {
							return s.id === supplyChainSteps[i].originSupplier.id;
						})) {
							suppliers.push(supplyChainSteps[i].originSupplier);
						}
					}
					if (!_.isEmpty(supplyChainSteps[i].processorSupplier)) {
						if (!_.any(suppliers, (s) => {
							return s.id === supplyChainSteps[i].processorSupplier.id;
						})) {
							suppliers.push(supplyChainSteps[i].processorSupplier);
						}
					}
					if (!_.isEmpty(supplyChainSteps[i].traderSupplier)) {
						if (!_.any(suppliers, (s) => {
							return s.id === supplyChainSteps[i].traderSupplier.id;
						})) {
							suppliers.push(supplyChainSteps[i].traderSupplier);
						}
					}

					_.each(suppliers, (supp) => {
						const coords = supp.address.placeOnEarth.split(', ');
						addMarkerToMap([coords[0], coords[1]], supp.id, 'grey');
					})
				}
			}

			if (supplyChainLinks.length > 0) {
				for (var i = 0; i < supplyChainLinks.length; i++) {
					addLineToMap(supplyChainLinks[i].fromSupplier.address.placeOnEarth, supplyChainLinks[i].toSupplier.address.placeOnEarth, supplyChainLinks[i].id, supplyChainLinks[i].vehicle);
				}
			}

		});
	}

	var highlightMarker = null;

	const [selectedSupplier, setSelectedSupplier] = useState();

	const addMarkerToMap = (lngLat, id, color) => {
		const marker = new mapboxgl.Marker({
			color: color
		}).setLngLat(lngLat)
			.addTo(map);

		marker.getElement().addEventListener('click', () => {
			const clickedSupplier = _.find(suppliers, (supp) => {
				return supp.id === id;
			})
			if (clickedSupplier) {
				setSelectedSupplier(clickedSupplier);
				setSelectedLink({});
			}

			if (highlightMarker) {
				highlightMarker.remove();
			}

			highlightMarker = new mapboxgl.Marker({
				color: 'rgba(176,162,231,1)'
			}).setLngLat(marker.getLngLat())
				.addTo(map);

			map.flyTo({ center: lngLat, zoom: map.getZoom(), duration: 1500 })

			/* setTimeout(() => {
				this.setState({displaySupplyChainStep: true})
			}, 200); */
		});

	}

	const [selectedLink, setSelectedLink] = useState();

	const addLineToMap = (from, to, id, vehicleIcon) => {
		const fromCoords = [from.split(', ')[0], from.split(', ')[1]];
		const toCoords = [to.split(', ')[0], to.split(', ')[1]];
		const line = {
			'type': 'Feature',
			'geometry': {
				'type': 'LineString',
				'properties': {
					id: id
				},
				'coordinates': [
					fromCoords,
					toCoords
				]
			}
		}

		const midPoint = getMidPoint(fromCoords[0], fromCoords[1], toCoords[0], toCoords[1]);

		const el = Object.assign(document.createElement('div'), {
			innerHTML:
				'<div id="point-' + id + '" class="marker text-center flex align-content-center justify-content-center" style="width: 30px; height: 30px; background: white; border: 1px solid grey;">' +
				'<img class="flex justify-content-center m-auto" src="' + vehicleIcon.iconBlack + '" alt="option icon" style="width: 20px;" />' +
				'</div>'
		})

		el.addEventListener('click', () => {

			const clickedLink = _.find(supplyChainLinks, (link) => {
				return link.id === id;
			})
			if (clickedLink) {
				setSelectedLink(clickedLink);
				setSelectedSupplier({});
				if (highlightMarker) {
					highlightMarker.remove();
				}
			}
		});

		// Add markers to the map.
		const marker = new mapboxgl.Marker(el)
			.setLngLat(midPoint)
			.addTo(map);

		geojson.features.push(line);

		map.getSource('TransportLine').setData(geojson);
	}

	const getStepWithSupplier = (id) => {
		var foundSteps = _.filter(supplyChainSteps, (step) => {
			let isOriginSupplier = false;
			let isProcessorSupplier = false;
			let isTraderSupplier = false;
			let isOwner = false;
			if (_.has(step, 'originSupplier') && !_.isEmpty(step.originSupplier)) {
				isOriginSupplier = (step.originSupplier.id === id)
			}

			if (_.has(step, 'processorSupplier') && !_.isEmpty(step.processorSupplier)) {
				isProcessorSupplier = (step.processorSupplier.id === id)
			}

			if (_.has(step, 'traderSupplier') && !_.isEmpty(step.traderSupplier)) {
				isTraderSupplier = (step.traderSupplier.id === id)
			}

			if (_.has(step, 'owner') && !_.isEmpty(step.owner)) {
				isOwner = (step.owner.id === id)
			}

			return isOriginSupplier || isProcessorSupplier || isTraderSupplier || isOwner;
		})

		const content = [];

		_.each(foundSteps, (step) => {

			const icons = [];
			if (!_.isEmpty(step.originSupplier)) {
				if (step.originSupplier.id === id) {
					icons.push(
						<span key={uuid()} className='flex'>
							<Tooltip target={".target-icon-" + step.originSupplier.id + "-seedling"} style={{ fontSize: '10px' }} />
							<FontAwesomeIcon icon="seedling" className={"mx-1 target-icon-" + step.originSupplier.id + "-seedling"} data-pr-tooltip="Origin" data-pr-position="top" />
						</span>
					)
				}
			}

			if (!_.isEmpty(step.processorSupplier)) {
				if (step.processorSupplier.id === id) {
					icons.push(
						<span key={uuid()} className='flex'>
							<Tooltip target={".target-icon-" + step.processorSupplier.id + "-industry"} style={{ fontSize: '10px' }} />
							<FontAwesomeIcon icon="industry" className={"mx-1 target-icon-" + step.processorSupplier.id + "-seedling"} data-pr-tooltip="Producer" data-pr-position="top" />
						</span>
					)
				}
			}

			if (!_.isEmpty(step.traderSupplier)) {
				if (step.traderSupplier.id === id) {
					icons.push(
						<span key={uuid()} className='flex'>
							<Tooltip target={".target-icon-" + step.traderSupplier.id + "-right-left"} style={{ fontSize: '10px' }} />
							<FontAwesomeIcon icon="right-left" className={"mx-1 target-icon-" + step.traderSupplier.id + "-seedling"} data-pr-tooltip="Trader" data-pr-position="top" />
						</span>
					)
				}
			}
			content.push(
				<div className='col-12 flex'>
					<span className='mr-3'>{step.name}</span>({icons})
				</div>
			)

		})

		return (
			<div className='w-full'>
				{
					foundSteps.length > 0 &&
					<h5 className='mb-0'>Il fornitore è presente nei seguenti step:</h5>
				}
				{content}
			</div>
		)
	}
	/******************************* HANDLERS *******************************/

	const handleSupplyChainStepChange = (value, propKey, id) => {
		const supplyChainStepsCopy = [...supplyChainSteps];
		const index = _.findIndex(supplyChainStepsCopy, (step) => {
			return step.id === id;
		})

		if (index !== -1) {
			if (propKey === 'media') {
				_.each(value, function (elem) {
					const id = uuid();

					supplyChainStepsCopy[index][propKey].push({
						'id': id,
						'file': elem,
						'name': elem.name,
						'show': true
					});
				})
			} else {
				supplyChainStepsCopy[index][propKey] = value;
			};

			setSupplyChainSteps(supplyChainStepsCopy);
		}
	}

	const handleMediaNameChange = (media, newName, stepId) => {
		const supplyChainStepsCopy = [...supplyChainSteps];
		const index = _.findIndex(supplyChainStepsCopy, (step) => {
			return step.id === stepId;
		})

		const mediaToChangeIndex = _.findIndex(supplyChainStepsCopy[index].media, (m) => {
			return m.id === media.id;
		});

		supplyChainStepsCopy[index].media[mediaToChangeIndex].name = newName;

		setSupplyChainSteps(supplyChainStepsCopy);
	}

	const handleMediaShowChange = (media, newShow, stepId) => {
		const supplyChainStepsCopy = [...supplyChainSteps];
		const index = _.findIndex(supplyChainStepsCopy, (step) => {
			return step.id === stepId;
		})

		const mediaToChangeIndex = _.findIndex(supplyChainStepsCopy[index].media, (m) => {
			return m.id === media.id;
		});

		supplyChainStepsCopy[index].media[mediaToChangeIndex].show = newShow;

		setSupplyChainSteps(supplyChainStepsCopy);
	}

	const handleAddLink = async (fromSupplierId, toSupplierId, fromType, toType, fromHandler, toHandler, fromStepTypeID, toStepTypeID, vehicle, distance) => {

		const newLink = {
			'fromStepTypeID': fromStepTypeID,
			'fromSupplierId': fromSupplierId,
			'fromTypeCode': fromType,
			'fromHandler': fromHandler,
			'toStepTypeID': toStepTypeID,
			'toSupplierId': toSupplierId,
			'toTypeCode': toType,
			'toHandler': toHandler,
			'vehicle': vehicle,
			'distance': distance
		}

		const uploadLinkResponse = await uploadSupplyChainLink(newLink);
		if (uploadLinkResponse.status === 200) {

			const supplyChainLinksCopy = [...supplyChainLinks];
			const viewLink = { ...uploadLinkResponse.response.data.createSupplyChainLink };
			supplyChainLinksCopy.push(viewLink);
			setSupplyChainLinks(supplyChainLinksCopy);
		}
	}

	const [removedSupplyChainLinks, setRemovedSupplyChainLinks] = useState([]);
	const handleRemoveLink = (fromSupplierId, toSupplierId) => {
		const link = _.find(supplyChainLinks, (step) => {
			return step.fromSupplierId === fromSupplierId && step.toSupplierId === toSupplierId;
		})

		const supplyChainLinksCopy = _.reject(supplyChainLinks, (step) => {
			return step.fromSupplierId === fromSupplierId && step.toSupplierId === toSupplierId;
		})

		const removedSupplyChainLinksCopy = [...removedSupplyChainLinks];
		removedSupplyChainLinksCopy.push(link?.id);
		setRemovedSupplyChainLinks(removedSupplyChainLinksCopy);

		setSupplyChainLinks(supplyChainLinksCopy);
	}

	const handleFlowSave = (flow) => {
		/* saveProductFlow(flow, id);
		const modelProduct = productViewToModel(generalInformations, supplyChainSteps, supplyChainLinks);
		saveProduct(modelProduct); */
	}

	const items = [
		{
			label: 'Caratteristiche',
			command: (event) => {
				changeTabIndex(0);
			}
		},
		{
			label: 'Media',
			command: (event) => {
				changeTabIndex(1);
			}
		},
		{
			label: 'Filiera',
			command: (event) => {
				changeTabIndex(2);
			}
		},
		{
			label: 'Trasporti',
			command: (event) => {
				changeTabIndex(3);
			}
		},
		{
			label: 'Mappa',
			command: (event) => {
				changeTabIndex(4);
			}
		}
	];

	const [fileLoading, setFileLoading] = useState(false);

	const handleProductMediaUpload = (media) => {
		handleGeneralInformationChange(media, 'media');
	}

	const [removedMedia, setRemovedMedia] = useState([])
	const handleProductMediaRemove = (media) => {
		const generalInformationsCopy = { ...generalInformations };

		generalInformationsCopy.media = _.reject(generalInformationsCopy.media, function (m) {
			return m.id === media.id;
		});

		const removedMediaCopy = [...removedMedia];
		removedMediaCopy.push(media.id);
		setRemovedMedia(removedMediaCopy);
		setGeneralInformations(generalInformationsCopy);
	}

	const handleProductMediaNameChange = (media, e) => {
		const generalInformationsCopy = { ...generalInformations };
		const mediaToChange = _.find(generalInformationsCopy.media, function (m) {
			return m.id === media.id
		});

		mediaToChange.name = e.target.value;

		setGeneralInformations(generalInformationsCopy);
	}

	const handleProductMediaDescriptionChange = (media, e) => {
		const generalInformationsCopy = { ...generalInformations };
		const mediaToChange = _.find(generalInformationsCopy.media, function (m) {
			return m.id === media.id
		});

		mediaToChange.description = e.target.value;

		setGeneralInformations(generalInformationsCopy);
	}


	const handleProductMediaShowChange = (media, e) => {
		const generalInformationsCopy = { ...generalInformations };

		const mediaToChange = _.find(generalInformationsCopy.media, function (m) {
			return m.id === media.id
		});

		mediaToChange.show = e.target.value;

		setGeneralInformations(generalInformationsCopy);
	}


	return (
		<>
			<ConfirmDialog />

			<div className="w-full" style={{ height: '90vh', overflow: 'scroll' }}>

				<div className='mx-3 mt-3'>
					<Button label="Salva prodotto" icon="pi pi-save" className='prisma-green-bg text-blue no-border mr-2' onClick={() => saveProduct()}></Button>
					<Button label="Elimina prodotto" icon="pi pi-trash" className='prisma-green-bg text-blue no-border mr-2' onClick={() => deleteCurrentProduct()}></Button>
				</div>

				<div className="card w-full py-5">
					<Steps model={items} activeIndex={activeTabIndex} readOnly={false} />
				</div>

				{
					(!_.isEmpty(generalInformations) && activeTabIndex === 0) &&

					<div className=''>
						<GeneralInformationForm generalInformations={generalInformations} onGeneralInformationChange={handleGeneralInformationChange} />
					</div>

				}

				{
					activeTabIndex === 1 &&
					<>
						{
							fileLoading &&

							<div className='px-3 col-12 grid align-content-center justify-content-center'>
								<div className='col-9'>
									<h3>E' in corso il caricamento del file. In base alla dimensione del file, l'operazione potrebbe richiedere qualche decina di secondi.</h3>
									<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
								</div>
							</div>
						}
						{
							!fileLoading &&
							<div className='col col-12 px-5 grid mx-0' style={{ height: '70vh', overflow: 'scroll' }}>
								<FileNewEdit
									file={generalInformations.media}
									type="media"
									writeEnabled={true}
									onNameChange={() => handleProductMediaNameChange}
									onDescriptionChange={() => handleProductMediaDescriptionChange}
									onShowChange={() => handleProductMediaShowChange}
									onUpload={handleProductMediaUpload}
									onRemove={() => handleProductMediaRemove}
									onFileLoaderActive={(value) => setFileLoading(value)}
									instructions={<span>Cliccando il tasto <b>Seleziona</b> è possibile selezionare uno o più media dal file system. Una volta selezionati
										cliccare il tasto <b>Carica</b> per aggiungerli ai media correnti. Se si desidera aggiungere altri media cliccare il tasto <b>Svuota</b> e poi ripetere la procedura.</span>}
								></FileNewEdit>
							</div>
						}
					</>

				}

				{
					activeTabIndex === 2 &&

					<div className='p-3'>
						<div className='w-full mb-3'>
							<SplitButton label="Aggiungi step" className='prisma-green-bg text-blue no-border mr-2' model={addStepSplitButtonItems}></SplitButton>

							<div className='border-round-md shadow-2 mr-2 ml-1 my-3' style={{ overflowX: 'scroll' }}>
								<div className='px-5 pt-3 flex'>
									<div className='flex mr-5 align-items-center'>
										<span style={{ height: '15px', width: '30px', backgroundColor: '#0c374d', display: 'block', borderRadius: '2px' }}></span>
										<span className='ml-2 font-medium'>Tier 1</span>
									</div>
									<div className='flex mr-5 align-items-center'>
										<span style={{ height: '15px', width: '30px', backgroundColor: '#c02f1d', display: 'block', borderRadius: '2px' }}></span>
										<span className='ml-2 font-medium'>Tier 2</span>
									</div>
									<div className='flex mr-5 align-items-center'>
										<span style={{ height: '15px', width: '30px', backgroundColor: '#829356', display: 'block', borderRadius: '2px' }}></span>
										<span className='ml-2 font-medium'>Tier 3</span>
									</div>
								</div>
								<Timeline className='p-passport-timeline p-timeline-productnewedit mx-4' value={orderSteps(supplyChainStepTypes)} layout="horizontal" content={supplyChainTypeItemTemplate} marker={supplyChainTypeMarkerTemplate} />
							</div>

						</div>
					</div>
				}

				{
					activeTabIndex === 3 &&

					<div className='p-3'>
						<TransportFlowGraph
							supplyChainSteps={supplyChainSteps}
							links={supplyChainLinks}
							onAddLink={handleAddLink}
							onRemoveLink={handleRemoveLink}
							suppliers={suppliers}
							onFlowSave={handleFlowSave}
						/>
					</div>
				}

				{
					activeTabIndex === 4 &&
					<div className='p-3'>
						<div className='grid p-3'>
							<div className='col col-12 lg:col-7 lg:py-0 border-round-md'>
								<div id="mapContainerID" style={{ height: '60vh' }} className="mx-auto border-round-md shadow-2"></div>
							</div>
							<div className='col-12 mt-3 lg:mt-0 lg:col-5'>
								<div className='grid justify-content-around shadow-1 border-round-md p-3'>
									<div className='col col-4 grid justify-content-center'>
										<h4 className='w-full text-center'>Numero step</h4>
										<h2 className=''>
											<Badge value={supplyChainSteps.length} size="large" className='prisma-green-bg text-blue no-border ml-2'></Badge>
										</h2>
									</div>

									<div className='col col-4 grid justify-content-center'>
										<h4 className='w-full text-center'>Numero collegamenti</h4>
										<h2 className=''>
											<Badge value={supplyChainLinks.length} size="large" className='prisma-green-bg text-blue no-border ml-2'></Badge>
										</h2>
									</div>

									<div className='col col-4 grid justify-content-center'>
										<h4 className='w-full text-center'>Km totali</h4>
										<h2 className=''>
											<Badge value="997" size="large" className='prisma-green-bg text-blue no-border ml-2'></Badge>
										</h2>
									</div>
								</div>
								{
									(selectedSupplier && !_.isEmpty(selectedSupplier)) &&
									<div>
										<h3 className='mb-0 col col-12'>Fornitore</h3>
										<div className='grid shadow-1 border-round-md p-3 mt-2'>
											<div className='col-12 flex align-items-center mb-0 pb-0'>
												<h5 className='mb-1'>{selectedSupplier.name}</h5>
											</div>
											<div className='col-12 flex align-items-center py-0'>
												<FontAwesomeIcon icon="map-pin" className='mr-2' />
												<span>{selectedSupplier.address} ({selectedSupplier.city})</span>
											</div>

											<div className='col-12 align-items-center py-0'>
												{getStepWithSupplier(selectedSupplier.id)}
											</div>
										</div>
									</div>
								}

								{
									(selectedLink && !_.isEmpty(selectedLink)) &&
									<div >
										{
											!_.isEmpty(selectedLink.vehicle) &&
											<h3 className='mb-0 col col-12'>Trasporto di materiale via {selectedLink.vehicle.label}</h3>

										}

										<div className='grid shadow-1 border-round-md p-3 mt-2'>
											<div className='mr-2 mt-3 col'>
												<small>Da:</small>
												<div className='flex align-items-center mb-0 pb-0'>
													<h5 className='mb-1'>{selectedLink.fromSupplier.name}</h5>
												</div>
												<div className='flex align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													<span>{selectedLink.fromSupplier.address.address} ({selectedLink.fromSupplier.address.city})</span>
												</div>
											</div>
											<div className='mt-3 col'>
												<small>A:</small>
												<div className='flex align-items-center mb-0 pb-0'>
													<h5 className='mb-1'>{selectedLink.toSupplier.name}</h5>
												</div>
												<div className='flex align-items-center py-0'>
													<FontAwesomeIcon icon="map-pin" className='mr-2' />
													<span>{selectedLink.toSupplier.address.address} ({selectedLink.toSupplier.address.city})</span>
												</div>
											</div>
										</div>

									</div>
								}

							</div>
						</div>
					</div>

				}
			</div>

			<Dialog header='Aggiungi nuovo step' visible={displayEditSupplyChainStepName} modal={true} style={{ width: '30vw' }}
				draggable={false} resizable={true} onHide={() => setDisplayEditSupplyChainStepName(false)}>
				<div className='grid'>
					<div className='col col-10'>
						<InputText autoFocus type="text" className="block w-full" placeholder="Nome dello step" value={newStepName} onChange={(e) => setNewStepName(e.target.value)} />
					</div>
					<div className='col col-2 text-right'>
						<Button icon="pi pi-arrow-right" className="prisma-green-bg text-blue no-border mr-2" onClick={addSupplyChainStepWithType} />
					</div>
				</div>
			</Dialog>

			{
				(suppliers && suppliers.length) &&
				<Dialog header={<span className='flex'>{selectedSupplyChainStep.name}{selectedSupplyChainStep.type ? <Image className='flex align-items-center ml-2' src={selectedSupplyChainStep.type.iconBlack} alt="" width="25px" height="25px" /> : ""}</span>} className="supply-chain-step-modal" visible={showSupplyChainStepEditDialog} modal={true} position={'right'}
					draggable={false} resizable={true} onHide={() => setShowSupplyChainStepEditDialog(false)}>
					<SupplyChainStepForm
						supplyChainStep={selectedSupplyChainStep} suppliers={suppliers}
						onSupplyChainStepChange={handleSupplyChainStepChange}
						onMediaNameChange={handleMediaNameChange} onMediaShowChange={handleMediaShowChange}
					/>
				</Dialog>
			}

			<Dialog header='Nuova filiera' visible={displayEditSupplyChainName} modal={true} style={{ width: '30vw' }}
				draggable={false} resizable={true} onHide={() => setDisplayEditSupplyChainName(false)}>
				<div className='grid'>
					<h5 className='px-2'>Salvando la filiera sarà possibile utilizzarla come template per i prossimi prodotti.</h5>
					<div className='col col-10'>
						<InputText autoFocus type="text" className="block w-full" placeholder="Nome della filiera" value={currentSupplyChainName} onChange={(e) => setCurrentSupplyChainName(e.target.value)} />
					</div>
					<div className='col col-2 text-right'>
						<Button icon="pi pi-arrow-right" className="prisma-green-bg text-blue no-border mr-2" onClick={() => saveCurrentSupplyChain(true)} />
					</div>
				</div>
			</Dialog>


		</>
	)

}

export default ProductNewEdit;


