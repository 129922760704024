import { DataView } from 'primereact/dataview';
import { Image } from 'primereact/image';
import { Chip } from 'primereact/chip';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';


import {v4 as uuid} from 'uuid'

import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { deleteSupplier } from '../services/RemoteService';

const SupplyItemDataView = (props) => {
    const suppliers = props.suppliers;

    const mediaTemplate = (rowData) => {
        let content = [];

        _.each(rowData.media, (m) => {
            content.push(
                <span key={uuid()} className="flex mr-2">
                    <Image src={m.referredFile.url} className="flex" style={{height: '35px', width: "auto"}} preview/>
                </span>
            )
        })

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.media.length !== 0 &&
                    <div>
                        <h4 className='w-full'>
                            Numero media:  {rowData.media.length}
                        </h4>
                        <div className='flex justify-content-start align-items-center mt-3' style={{maxWidth: '100%', overflow: "scroll"}}>
                            {content}
                        </div>
                    </div>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun media</span>
                }
            </div>
        )
    }

    const claimTemplate = (rowData) => {
        let content = [];

        _.each(rowData.claims, (c) => {
            content.push(
                <span key={uuid()} className="flex mr-2">
                    <Image src={c.referredFile.url} className="flex" style={{height: '35px', width: "auto"}} preview/>
                </span>
            )
        })

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.media.length !== 0 &&
                    <div>
                        <h4 className='w-full'>
                            Numero claim:  {rowData.claims.length}
                        </h4>
                        <div className='flex justify-content-start align-items-center mt-3' style={{maxWidth: 'auto', overflow: "scroll"}}>
                            {content}
                        </div>
                    </div>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun claim</span>
                }
            </div>
        )
    }

    const docsTemplate = (rowData) => {

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.documents.length !== 0 &&
                    <h4 className='w-full'>
                        Numero documenti:  {rowData.documents.length}
                    </h4>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun documento</span>
                }
            </div>
        )
    }

    const navigate = useNavigate();

    const handleDeleteSupplier = async(id) => {
        const response = await deleteSupplier(id);
        window.location.reload(false);
    }

    const itemTemplate = (supplier) => {
        console.log("Fornitore: ", supplier);
        return (
            <div className="col-12 m-0 p-2 lg:col-6 xl:col-4 flex align-items-stretch">

                <div className='p-2 flex'>
                    <div className='grid border-round-md shadow-2 align-content-start'>

                        <div className='col-12 flex justify-content-between'>
                            <div>
                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text mr-2" style={{width: '38px', height: '38px'}} onClick={() => navigate("/supplierNewEdit/"+supplier.id)} />
                                <Button icon="pi pi-trash" className="p-button-rounded p-button-text" style={{width: '38px', height: '38px'}} onClick={() => handleDeleteSupplier(supplier.id)} />
                            </div>
                            {props.onScoreTemplate(supplier)}
                        </div>

                        <div className='col-6 flex justify-content-center align-items-center mx-0'>
                            <Image preview className="flex" imageStyle={{width: 'auto', height: 'auto', borderRadius: '10px', maxWidth: '150px', maxHeight: '150px'}} src={supplier.image?.referredFile.url} alt="Image"/>
                        </div>

                        <div className='col col-6 px-2 grid p-0 m-0 align-content-center align-items-center'>
                            {
                                (supplier.name) &&
                                <div className='col col-12'>
                                    <div className='flex'>
                                        <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pnwg3km8p"} alt="Image"/>
                                        <div className=''>
                                            <h4 className='m-0 p-0 flex'>{supplier.name}</h4>
                                            {
                                                supplier.companyName &&
                                                <small className='flex align-content-center align-items-center mt-1'>({supplier.companyName})</small>
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            }

                            {
                                (supplier.address || supplier.city) &&

                                <div className='col col-12'>
                                    <div className='flex'>
                                        <Image className="flex mr-2" imageStyle={{width: 'auto', height: '20px'}} src={"https://imageshack.com/i/pmFdASVbp"} alt="Image"/>
                                        <div className=''>
                                            <h4 className='m-0 p-0 flex'>{supplier.city}</h4>
                                            {
                                                supplier.address &&
                                                <small className='flex align-content-center align-items-center mt-1'>({supplier.address})</small>
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            }
                            
                        </div>

                        <Divider className='divider-blue mx-3' align="left" type="dashed">
                            <small className='text-blue'>Descrizione</small>
                        </Divider>

                        <div className='grid col-12 mx-1'>
                            {
                                (supplier.description) &&

                                <div className='col col-12'>
                                    <small className='flex'>
                                        <span className='flex align-content-center align-items-center'>{supplier.description}</span>
                                    </small>
                                </div>
                            }
                        </div>

                        <Divider className='divider-blue mx-3' align="left" type="dashed"></Divider>

                        <div className='mx-2 my-0 grid'>
                            <div className='col-6 flex justify-content-start'>
                                {mediaTemplate(supplier)}
                            </div>

                            <div className='col-6 flex justify-content-start'>
                                {claimTemplate(supplier)}
                            </div>
                            
                            <div className='col-6 flex justify-content-start'>
                                {docsTemplate(supplier)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <>
            <DataView value={suppliers} layout={'grid'}
                itemTemplate={itemTemplate} paginator rows={9} className="align-items-stretch"/>
        </>
    )
}

export default SupplyItemDataView;