import { useState } from 'react';
import { v4 as uuid } from 'uuid';


import {Button} from 'primereact/button'
import {ProgressBar} from 'primereact/progressbar'

import SupplierForm from '../components/supplierForm'

import { deleteSupplierClaim, deleteSupplierDocument, deleteSupplierMedia, getSuppliers, updateClaim, updateDocument, updateMedia, updateSupplier } from '../services/RemoteService'
import { uploadSupplier } from '../services/RemoteService'

import _ from 'underscore';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { supplierModelToView, supplierViewToModel } from '../services/DbUtils';

const SupplierNewEdit = () => {
    const auth = localStorage.getItem('PRISMA_TOKEN')
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!auth) {
            navigate("/login");
        }    
    }, [])

    const { id } = useParams();

    const [supplier, setSupplier] = useState({});
    
    useEffect(() => {
        if(id){

            const fetchSupplier = async () => {
                const supplierByIdResponse = await getSuppliers({"id": id});
                if(supplierByIdResponse.status === 200){
                    const viewSupplier = supplierModelToView(supplierByIdResponse.response.data.suppliers[0]);
                    setSupplier(viewSupplier);
                    setSupplierPosition(viewSupplier.placeOnEarth);
                }
            };
        
            fetchSupplier();
        }else{
            setSupplier({
                'name': '',
                'displayName': '', 
                'showDisplayName': true,
                'companyName': '',
                'vatNumber': '',
                'description': '',
                'city': '',
                'showCity': true,
                'address': '',
                'showAddress': true,
                'province': '',
                'showProvince': true,
                'placeOnEarth': '',
                'showPlaceOnEarth': true,
                'email': '',
                'phoneNumber': '',
                'website': '',
                'companySize': '',
                'totProductionQuantity': 0,
                'minOrderQuantity': 0,
                'minOrderQuantityPerStyle': 0,
                'standardDelayTimeDays': 0,
                'numberOfPermanentEmployees': 0,
                'numberOfMaleEmployees': 0,
                'numberOfFemaleEmployees': 0,
                'media': [],
                'documents': [],
                'claims': []
            })
        }
    }, [])

    const [supplierPosition, setSupplierPosition] = useState('');

    document.body.style.overflow = 'hidden';

    const [fileLoading, setFileLoading] = useState(false);

    const handleSupplierChange = (value, propKey) => {
        const supplierCopy = {...supplier};
        if(propKey === 'claims' || propKey === 'documents' || propKey === 'media'){
            supplierCopy[propKey].push(value);
                
        }else{
            supplierCopy[propKey] = value;
        }

        if(propKey === "media" || propKey === "documents" || propKey === "claims"){
            setTimeout(() => {
                setSupplier(supplierCopy);
                setFileLoading(false);
            }, 200);
        }else{
            setSupplier(supplierCopy);
        }
        
    }

    const handleSupplierPositionChange = (value) => {
        setSupplierPosition(value);
        /* handleSupplierChange(value, 'placeOnEarth') */
    }

    const [removedMedia, setRemovedMedia] = useState([])
    const handleSupplierMediaRemove = (media) => {
        const supplierCopy = {...supplier};

        supplierCopy.media = _.reject(supplierCopy.media, function(m){
            return m.id === media.id;
        });

        const removedMediaCopy = [...removedMedia];
        removedMediaCopy.push(media.id);
        setRemovedMedia(removedMediaCopy);
        setSupplier(supplierCopy);
    }

    const handleSupplierMediaNameChange = (media, newName) => {
        const supplierCopy = {...supplier};

        const mediaToChange = _.find(supplierCopy.media, function(m){
            return m.id === media.id
        });

        mediaToChange.name = newName;

        setSupplier(supplierCopy);
    }

    const handleSupplierMediaDescriptionChange = (media, newDescr) => {
        const supplierCopy = {...supplier};

        const mediaToChange = _.find(supplierCopy.media, function(m){
            return m.id === media.id
        });

        mediaToChange.description = newDescr;

        setSupplier(supplierCopy);
    }


    const handleMediaShowChange = (media, newShow) => {
        const supplierCopy = {...supplier};

        const mediaToChange = _.find(supplierCopy.media, function(m){
            return m.id === media.id
        });

        mediaToChange.show = newShow;

        setSupplier(supplierCopy);
    }

    const [removedDocuments, setRemovedDocuments] = useState([])
    const handleSupplierDocumentRemove = (document) => {
        const supplierCopy = {...supplier};

        supplierCopy.documents = _.reject(supplierCopy.documents, function(doc){
            return doc.id === document.id;
        });

        const removedDocumentsCopy = [...removedDocuments];
        removedDocumentsCopy.push(document.id);
        setRemovedDocuments(removedDocumentsCopy);

        setSupplier(supplierCopy);
    }

    const handleSupplierDocumentNameChange = (document, newName) => {
        const supplierCopy = {...supplier};

        const documentToChange = _.find(supplierCopy.documents, function(doc){
            return doc.id === document.id
        });

        documentToChange.name = newName;

        setSupplier(supplierCopy);
    }

    const handleSupplierDocumentTypeChange = (document, newType) => {
        const supplierCopy = {...supplier};

        const documentToChange = _.find(supplierCopy.documents, function(doc){
            return doc.id === document.id
        });

        documentToChange.type = newType;

        setSupplier(supplierCopy);
    }

    const handleSupplierDocumentExpirationDateChange = (document, newExpDate) => {
        const supplierCopy = {...supplier};

        const documentToChange = _.find(supplierCopy.documents, function(doc){
            return doc.id === document.id
        });

        documentToChange.expirationDate = newExpDate;

        setSupplier(supplierCopy);
    }

    const handleDocumentDescriptionChange = (document, newDescr) => {
        const supplierCopy = {...supplier};

        const documentToChange = _.find(supplierCopy.documents, function(doc){
            return doc.id === document.id
        });

        documentToChange.description = newDescr;

        setSupplier(supplierCopy);
    }

    const [removedClaims, setRemovedClaims] = useState([])
    const handleClaimRemove = (claim) => {
        const supplierCopy = {...supplier};

        supplierCopy.claims = _.reject(supplierCopy.claims, function(c){
            return c.id === claim.id;
        });

        const removedClaimsCopy = [...removedClaims];
        removedClaimsCopy.push(claim.id);
        setRemovedClaims(removedClaimsCopy);

        setSupplier(supplierCopy);
    }

    const handleClaimNameChange = (claim, newName) => {
        const supplierCopy = {...supplier};

        const claimToChange = _.find(supplierCopy.claims, function(c){
            return c.id === claim.id
        });

        claimToChange.name = newName;

        setSupplier(supplierCopy);
    }

    const handleClaimDescriptionChange = (claim, newDescr) => {
        const supplierCopy = {...supplier};

        const claimToChange = _.find(supplierCopy.claims, function(c){
            return c.id === claim.id
        });

        claimToChange.description = newDescr;

        setSupplier(supplierCopy);
    }

    const handleClaimTypeChange = (claim, newType) => {
        const supplierCopy = {...supplier};

        const claimToChange = _.find(supplierCopy.claims, function(c){
            return c.id === claim.id
        });

        claimToChange.type = newType;

        setSupplier(supplierCopy);
    }

    const handleClaimConfirmationDocChange = (claim, newConfirmationDoc) => {
        const supplierCopy = {...supplier};

        const claimToChange = _.find(supplierCopy.claims, function(c){
            return c.id === claim.id
        });

        claimToChange.confirmationDoc = newConfirmationDoc;

        setSupplier(supplierCopy);
    }

    const [loading, setLoading] = useState(false);

    const save = async () => {
        setLoading(true);

        const supplierCopy = {...supplier};
        supplierCopy.placeOnEarth = supplierPosition;

        const modelSupplier = supplierViewToModel(supplierCopy);
        var response = null;

        _.each(removedMedia, async (rm) => {
            await deleteSupplierMedia(rm, id);
        })
        _.each(removedDocuments, async (rd) => {
            await deleteSupplierDocument(rd, id);
        })
        _.each(removedClaims, async (rc) => {
            await deleteSupplierClaim(rc, id);
        })

        if(id && !duplicateSupplier){
            response = await updateSupplier(modelSupplier, id);
            _.each(supplierCopy.media, async (m) => {
                response = await updateMedia(m)
            })
            _.each(supplierCopy.documents, async (d) => {
                console.log(d);
                response = await updateDocument(d)
            })
            _.each(supplierCopy.claims, async (c) => {
                response = await updateClaim(c)
            })
        }else{
            response = await uploadSupplier(modelSupplier);
        }
        if(response.status === 200){
            navigate('/suppliers')
        }else{
            setLoading(false);
        }
    }

    var duplicateSupplier = false;
    const duplicate = () => {
        duplicateSupplier = true;
        save()
    }

    const toolbarRightContent = (
        <React.Fragment>
        </React.Fragment>
    );
    
    
    return(
        <>
            <div className="w-full">
                <div className='mx-3 mt-3'>
                    <Button icon="pi pi-save" className="flex prisma-green-bg text-blue no-border-btn mr-5" label="Salva" onClick={save} />
                </div>

                {
                    fileLoading &&

                    <div className='px-3 col-12 grid align-content-center justify-content-center'>
                        <div className='col-9'>
                            <h3>E' in corso il caricamento del file. In base alla dimensione del file, l'operazione potrebbe richiedere qualche decina di secondi.</h3>
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        </div>
                    </div>
                }

                {
                    loading &&

                    <div className='px-3 col-12 grid align-content-center justify-content-center'>
                        <div className='col-9'>
                            <h3>E' in corso il salvataggio del fornitore. L'operazione potrebbe richiedere qualche decina di secondi.</h3>
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        </div>
                    </div>
                }

                {
                    !_.isEmpty(supplier) &&
                    <SupplierForm fileLoading={fileLoading} loading={loading} supplier={supplier} supplierPosition={supplierPosition} writeEnabled={true} modal={false}
                        onSupplierChange={handleSupplierChange} onSupplierPositionChange={handleSupplierPositionChange}
                        onMediaRemove={handleSupplierMediaRemove} onMediaNameChange={handleSupplierMediaNameChange} onMediaDescriptionChange={handleSupplierMediaDescriptionChange}  onMediaShowChange={handleMediaShowChange}
                        onDocumentRemove={handleSupplierDocumentRemove} onDocumentNameChange={handleSupplierDocumentNameChange} onDocumentTypeChange={handleSupplierDocumentTypeChange} onDocumentExpirationDateChange={handleSupplierDocumentExpirationDateChange} onDocumentDescriptionChange={handleDocumentDescriptionChange} 
                        onClaimRemove={handleClaimRemove} onClaimNameChange={handleClaimNameChange} onClaimDescriptionChange={handleClaimDescriptionChange} onClaimTypeChange={handleClaimTypeChange} onClaimConfirmationDocChange={handleClaimConfirmationDocChange}
                        onFileLoaderActive={() => setFileLoading(true)}/>
                }
            </div>
            
        </>
    )
    
}

export default SupplierNewEdit;


