import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Chart } from 'primereact/chart';
import { Badge } from 'primereact/badge';
import { SelectButton } from 'primereact/selectbutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Image } from 'primereact/image';

import React, { useEffect } from 'react';
import { useState } from 'react';

import _ from 'underscore';
import randomColor from "randomcolor";
import {v4 as uuid} from 'uuid';

import { useNavigate } from 'react-router-dom';
import { getInteractionsByProductWithType } from '../services/RemoteService';
import { getProducts } from '../services/RemoteService';
import { productModelToView, clickOnClaimModelToView, clickOnDocumentModelToView } from '../services/DbUtils';

const Analytics = () => {
    const auth = localStorage.getItem('PRISMA_TOKEN')
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!auth) {
            navigate("/login");
        }    
    }, [])

    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchProducts = async () => {
            const productsResponse = await getProducts(null);
            if(productsResponse.status === 200){

                var viewProducts = _.map(productsResponse.response.data.products, (prod) => {
                    return productModelToView(prod);
                })
                
                setProducts(viewProducts);
            }
        };
    
        fetchProducts();
    }, [])

    const [selectedProduct, setSelectedProduct] = useState();

    useEffect(() => {
        if(selectedProduct){
            generateClaimChartData();
            generateDocumentChartData();
        }
    }, [selectedProduct])

    const claimAnalyticsHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <Image className="flex mr-2" imageStyle={{width: '25px', height: '25px'}} src={"https://imageshack.com/i/poCMw7N9p"} alt="Image"/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Claim</span>
            </a>
        )
    }

    const documentAnalyticsHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <Image className="flex mr-2" imageStyle={{width: '25px', height: '25px'}} src={"https://imageshack.com/i/pnEcgPJip"} alt="Image"/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Documenti</span>
            </a>
        )
    }

    
    /* Click on claim chart */
    
    const [loadingClaimData, setLoadingClaimData] = useState(false);
    var [claimChartData, setClaimChartData] = useState();

    var tClaimChartData = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                ],
                hoverBackgroundColor: [
                ]
            }
        ]
    };

    var [claimBarData, setClaimBarData] = useState()

    var tClaimBarData = {
        labels: [],
        datasets: []
    };
    
    const clickOnClaimGroupOptions = [
        {
            'label': 'Per giorno',
            'code': 1,
            'groupFunction': (date) => {return date},
        },
        {
            'label': 'Per mese',
            'code': 2,
            'groupFunction': (date) => {return date.split('/')[1]},
        },
        {
            'label': 'Per anno',
            'code': 3,
            'groupFunction': (date) => {return date.split('/')[2]},
        }
    ]
    const [selectedClickOnClaimGroup, setSelectedClickOnClaimGroup] = useState(clickOnClaimGroupOptions[0]);

    useEffect(() => {
        if(selectedClickOnClaimGroup && selectedProduct){
            generateClaimChartData();
        }
    }, [selectedClickOnClaimGroup])

    const [totClaimClick, setTotClaimClick] = useState(0);
    const [mappedClickOnClaimDiv,setMappedClickOnClaimDiv] = useState([]);
    const [mappedClickOnClaim, setMappedClickOnClaim] = useState([]);

    const [mappedClickOnClaimByDate, setMappedClickOnClaimByDate] = useState([]);
    
    const showClickOnClaimOptions = [
        { label: 'Per claim', icon: '', code: 1},
        { label: 'Per data', icon: '', code: 2}
    ];

    const [selectedShowClickOnClaim, setSelectedShowClickOnClaim] = useState(showClickOnClaimOptions[0])

    const generateClaimChartData = async () => {
        setLoadingClaimData(true);

        const claimColors = [];

        const interactionsResponse = await getInteractionsByProductWithType("CLICK_ON_CLAIM", selectedProduct.id);
        if(interactionsResponse.status === 200){

            var data = clickOnClaimModelToView(interactionsResponse.response.data.analytics)
            setTotClaimClick(data.length);

            const groupedClickOnClaim = _.toArray(_.groupBy(data, (interaction) => {
                return interaction.claimId;
            }));
            
            let tClick = _.map(groupedClickOnClaim, (interaction) => {
                var rColor = randomColor();
                claimColors.push({
                    'claimId': interaction[0].claimId,
                    'color': rColor
                })
                return {
                    'num': interaction.length,
                    'name': interaction[0].claimName,
                    'id': interaction[0].claimId,
                    'color': rColor
                }
            })

            setMappedClickOnClaim(tClick);

            const tClickDiv = []

            _.each(tClick, (interaction) => {
                
                tClaimChartData.labels.push(interaction.name);
                tClaimChartData.datasets[0].data.push(interaction.num);
                tClaimChartData.datasets[0].backgroundColor.push(interaction.color);
                tClaimChartData.datasets[0].hoverBackgroundColor.push(interaction.color);
        
                tClickDiv.push(
                    <div key={uuid()} className="mb-1">
                        <Badge value={interaction.num} className="mr-2" style={{backgroundColor: interaction.color}}></Badge>{interaction.name}
                    </div>
                )
            })

            setMappedClickOnClaimDiv(tClickDiv);

            /* Grouping by date for bar chart - TO BE REDESIGNED */

            const groupedClickByDate = _.toArray(_.groupBy(data, (interaction) => {
                return selectedClickOnClaimGroup.groupFunction((new Date(interaction.date).toLocaleDateString()+""));
            }));

            var groupedClickByDateAndClaim = [];
            
            _.each(groupedClickByDate, (clickGroup) => {
                let t = _.toArray(_.groupBy(clickGroup, (interaction) => {
                    return interaction.claimId;
                }));
                groupedClickByDateAndClaim.push(t)
            })

            groupedClickByDateAndClaim = _.map(groupedClickByDateAndClaim, (clickGroup) => {
                return _.map(clickGroup, (interaction) => {
                    return {
                        'num': interaction.length,
                        'claimName': interaction[0].claimName,
                        'claimId': interaction[0].claimId,
                        'color': _.find(claimColors, (color) => {
                            return color.claimId === interaction[0].claimId
                        }).color,
                        'date': new Date(interaction[0].date).toLocaleDateString()+""
                    }
                })

            })

            /* Each elements is an array of click divided by day. Each element of this array is a 
            ** click group for a specific claim in that specific day 
            */

            const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
            _.each(groupedClickByDateAndClaim, (group) => {
                /* Adding a label to chart for each day */
                if(selectedClickOnClaimGroup.code === 2){
                    tClaimBarData.labels.push(months[parseInt(selectedClickOnClaimGroup.groupFunction(group[0].date))-1])
                }else{
                    tClaimBarData.labels.push(selectedClickOnClaimGroup.groupFunction(group[0].date));
                }
            })

            setMappedClickOnClaimByDate(groupedClickByDateAndClaim);

            _.each(groupedClickOnClaim, (group) => {
                tClaimBarData.datasets.push(
                    {
                        type: 'bar',
                        label: group[0].claimName,
                        backgroundColor: _.find(claimColors, (color) => {
                            return color.claimId === group[0].claimId
                        }).color,
                        data: [
                        ]
                    }
                )
            })
            for(var i = 0; i<groupedClickByDateAndClaim.length; i++){
                var day = groupedClickByDateAndClaim[i];

                for(var j = 0; j<groupedClickOnClaim.length; j++){
                    const claim = groupedClickOnClaim[j];

                    var claimId = claim[0].claimId;
                    let claimForThatDay = _.find(day, (dayClaim) => {
                    return dayClaim.claimId === claimId
                    });
    
                    if(claimForThatDay){
                        tClaimBarData.datasets[j].data.push(
                        claimForThatDay.num
                    )
                    }else{
                        tClaimBarData.datasets[j].data.push(0)
                    }
                }
            }

            setMappedClickOnClaimByDate(groupedClickByDateAndClaim);
            setClaimBarData(tClaimBarData);
            setClaimChartData(tClaimChartData);

            setTimeout(() => {
                setLoadingClaimData(false);
            }, 100); 
        }
        
    }

    /* Click on documents chart */

    const [loadingDocumentData, setLoadingDocumentData] = useState(false);
    var [documentChartData, setDocumentChartData] = useState();

    var tDocumentChartData = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                ],
                hoverBackgroundColor: [
                ]
            }
        ]
    };

    var [documentBarData, setDocumentBarData] = useState()

    var tDocumentBarData = {
        labels: [],
        datasets: []
    };
    
    const clickOnDocumentGroupOptions = [
        {
            'label': 'Per giorno',
            'code': 1,
            'groupFunction': (date) => {return date},
        },
        {
            'label': 'Per mese',
            'code': 2,
            'groupFunction': (date) => {return date.split('/')[1]},
        },
        {
            'label': 'Per anno',
            'code': 3,
            'groupFunction': (date) => {return date.split('/')[2]},
        }
    ]
    const [selectedClickOnDocumentGroup, setSelectedClickOnDocumentGroup] = useState(clickOnDocumentGroupOptions[0]);

    useEffect(() => {
        if(selectedClickOnDocumentGroup && selectedProduct){
            generateDocumentChartData();
        }
    }, [selectedClickOnDocumentGroup])

    const [totDocumentClick, setTotDocumentClick] = useState(0);
    const [mappedClickOnDocumentDiv,setMappedClickOnDocumentDiv] = useState([]);
    const [mappedClickOnDocument, setMappedClickOnDocument] = useState([]);

    const [mappedClickOnDocumentByDate, setMappedClickOnDocumentByDate] = useState([]);
    
    const showClickOnDocumentOptions = [
        { label: 'Per documento', icon: '', code: 1},
        { label: 'Per data', icon: '', code: 2}
    ];

    const [selectedShowClickOnDocument, setSelectedShowClickOnDocument] = useState(showClickOnDocumentOptions[0])

    const generateDocumentChartData = async () => {
        setLoadingDocumentData(true);
        const documentColors = [];

        const interactionsResponse = await getInteractionsByProductWithType("CLICK_ON_DOCUMENT", selectedProduct.id);
        if(interactionsResponse.status === 200){

            var data = clickOnDocumentModelToView(interactionsResponse.response.data.analytics)
            setTotDocumentClick(data.length);

            const groupedClickOnDocument = _.toArray(_.groupBy(data, (interaction) => {
                return interaction.documentId;
            }));
            
            let tClick = _.map(groupedClickOnDocument, (interaction) => {
                var rColor = randomColor();
                documentColors.push({
                    'documentId': interaction[0].documentId,
                    'color': rColor
                })
                return {
                    'num': interaction.length,
                    'name': interaction[0].documentName,
                    'id': interaction[0].documentId,
                    'color': rColor
                }
            })

            setMappedClickOnDocument(tClick);

            const tClickDiv = []

            _.each(tClick, (interaction) => {
                
                tDocumentChartData.labels.push(interaction.name);
                tDocumentChartData.datasets[0].data.push(interaction.num);
                tDocumentChartData.datasets[0].backgroundColor.push(interaction.color);
                tDocumentChartData.datasets[0].hoverBackgroundColor.push(interaction.color);
        
                tClickDiv.push(
                    <div key={uuid()} className="mb-1">
                        <Badge value={interaction.num} className="mr-2" style={{backgroundColor: interaction.color}}></Badge>{interaction.name}
                    </div>
                )
            })

            setMappedClickOnDocumentDiv(tClickDiv);

            /* Grouping by date for bar chart - TO BE REDESIGNED */

            const groupedClickByDate = _.toArray(_.groupBy(data, (interaction) => {
                return selectedClickOnDocumentGroup.groupFunction((new Date(interaction.date).toLocaleDateString()+""));
            }));

            var groupedClickByDateAndDocument = [];
            
            _.each(groupedClickByDate, (clickGroup) => {
                let t = _.toArray(_.groupBy(clickGroup, (interaction) => {
                    return interaction.documentId;
                }));
                groupedClickByDateAndDocument.push(t)
            })

            groupedClickByDateAndDocument = _.map(groupedClickByDateAndDocument, (clickGroup) => {
                return _.map(clickGroup, (interaction) => {
                    return {
                        'num': interaction.length,
                        'documentName': interaction[0].documentName,
                        'documentId': interaction[0].documentId,
                        'color': _.find(documentColors, (color) => {
                            return color.documentId === interaction[0].documentId
                        }).color,
                        'date': new Date(interaction[0].date).toLocaleDateString()+""
                    }
                })

            })

            /* Each elements is an array of click divided by day. Each element of this array is a 
            ** click group for a specific document in that specific day 
            */

            const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
            _.each(groupedClickByDateAndDocument, (group) => {
                /* Adding a label to chart for each day */
                if(selectedClickOnDocumentGroup.code === 2){
                    tDocumentBarData.labels.push(months[parseInt(selectedClickOnDocumentGroup.groupFunction(group[0].date))-1])
                }else{
                    tDocumentBarData.labels.push(selectedClickOnDocumentGroup.groupFunction(group[0].date));
                }
            })

            setMappedClickOnDocumentByDate(groupedClickByDateAndDocument);

            _.each(groupedClickOnDocument, (group) => {
                tDocumentBarData.datasets.push(
                    {
                        type: 'bar',
                        label: group[0].documentName,
                        backgroundColor: _.find(documentColors, (color) => {
                            return color.documentId === group[0].documentId
                        }).color,
                        data: [
                        ]
                    }
                )
            })
            for(var i = 0; i<groupedClickByDateAndDocument.length; i++){
                var day = groupedClickByDateAndDocument[i];

                for(var j = 0; j<groupedClickOnDocument.length; j++){
                    const document = groupedClickOnDocument[j];

                    var documentId = document[0].documentId;
                    let documentForThatDay = _.find(day, (dayDocument) => {
                    return dayDocument.documentId === documentId
                    });
    
                    if(documentForThatDay){
                        tDocumentBarData.datasets[j].data.push(
                        documentForThatDay.num
                    )
                    }else{
                        tDocumentBarData.datasets[j].data.push(0)
                    }
                }
            }

            setMappedClickOnDocumentByDate(groupedClickByDateAndDocument);
            setDocumentBarData(tDocumentBarData);
            setDocumentChartData(tDocumentChartData);

            setTimeout(() => {
                setLoadingDocumentData(false);
            }, 100); 
        }
    }

    return(
        <>
            <div className='w-full' style={{height: '90vh', overflow: 'scroll'}}>

                <div className='px-2 lg:px-5' style={{overflow: "scroll"}}>
                    {
                        products.length !== 0 &&
                        <div className='col col-12 lg:col-6 mt-3'>
                            <h3 className=''>Scegli un prodotto</h3>
                            <Dropdown className='w-full' value={selectedProduct} options={products} optionLabel='name' onChange={(e) => setSelectedProduct(e.target.value)} placeholder="Prodotto scelto"/>
                        </div>
                    }

                    {
                        !_.isEmpty(selectedProduct) &&
                        <TabView className="tabview-header-icon">

                            <TabPanel headerTemplate={claimAnalyticsHeaderTemplate} className='p-2'>
                                <div className='col col-12'>
                                    <div className='grid justify-content-center lg:justify-content-between'>
                                        <h2 className='flex col-12 my-0'>Click per claim</h2>
                                        <div className='py-3 px-0 col-12 grid flex align-items-stretch justify-content-center lg:justify-content-between'>
                                            {
                                                mappedClickOnClaimDiv.length !== 0 &&
                                                <div className='col-12 px-1 lg:px-3 lg:col-3'>
                                                    <h5 className='mb-1'>Numero totale di click</h5>
                                                    <Badge value={totClaimClick} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                                    <div className='my-3'>
                                                        <small>
                                                            di cui: <br/>
                                                        </small>
                                                    </div>
                                                    {mappedClickOnClaimDiv}
                                                </div>
                                            }

                                            {
                                                (mappedClickOnClaim.length !== 0 && !loadingClaimData) &&
                                                <>
                                                    <div className='grid col-12 lg:col-4 p-3 align-content-start'>
                                                        <div className='col-12 border-round-md shadow-2'>
                                                            <ClickOnClaimChart chartData={claimChartData}></ClickOnClaimChart>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                            {
                                                (mappedClickOnClaimByDate.length !==0 && !loadingClaimData) &&
                                                <>
                                                    <div className='grid col-12 lg:col-5 p-3 align-content-start'>
                                                        <div className='col-12 grid p-0 m-0 border-round-md shadow-2'>
                                                            <div className='col-12 lg:col-8'>
                                                                <ClickOnClaimChartByDate chartData={claimBarData}></ClickOnClaimChartByDate>
                                                            </div>
                                                            <div className='col-12 mt-2px-3 lg:col-4'>
                                                                <div>
                                                                    {
                                                                        clickOnClaimGroupOptions.map((option) => {
                                                                            return (
                                                                                <div key={option.code} className="field-radiobutton">
                                                                                    <RadioButton inputId={option.code} name="groupOption" value={option} onChange={(e) => setSelectedClickOnClaimGroup(e.value)}  checked={selectedClickOnClaimGroup.code === option.code}/>
                                                                                    <label>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel headerTemplate={documentAnalyticsHeaderTemplate} className='p-2'>
                                <div className='col col-12'>
                                    <div className='grid justify-content-center lg:justify-content-between'>
                                        <h2 className='flex col-12 my-0'>Click per documento</h2>
                                        <div className='py-3 px-0 col-12 grid flex align-items-stretch justify-content-center lg:justify-content-between'>
                                            {
                                                mappedClickOnDocumentDiv.length !== 0 &&
                                                <div className='col-12 px-1 lg:px-3 lg:col-3'>
                                                    <h5 className='mb-1'>Numero totale di click</h5>
                                                    <Badge value={totDocumentClick} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                                    <div className='my-3'>
                                                        <small>
                                                            di cui: <br/>
                                                        </small>
                                                    </div>
                                                    {mappedClickOnDocumentDiv}
                                                </div>
                                            }

                                            {
                                                (mappedClickOnDocument.length !== 0 && !loadingDocumentData) &&
                                                <>
                                                    <div className='grid col-12 lg:col-4 p-3 align-content-start'>
                                                        <div className='col-12 border-round-md shadow-2'>
                                                            <ClickOnDocumentChart chartData={documentChartData}></ClickOnDocumentChart>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                            {
                                                (mappedClickOnDocumentByDate.length !==0 && !loadingDocumentData) &&
                                                <>
                                                    <div className='grid col-12 lg:col-5 p-3 align-content-start'>
                                                        <div className='col-12 grid p-0 m-0 border-round-md shadow-2'>
                                                            <div className='col-12 lg:col-8'>
                                                                <ClickOnDocumentChartByDate chartData={documentBarData}></ClickOnDocumentChartByDate>
                                                            </div>
                                                            <div className='col-12 px-3 mt-2 lg:col-4'>
                                                                <div>
                                                                    {
                                                                        clickOnDocumentGroupOptions.map((option) => {
                                                                            return (
                                                                                <div key={option.code} className="field-radiobutton">
                                                                                    <RadioButton inputId={option.code} name="groupOption" value={option} onChange={(e) => setSelectedClickOnDocumentGroup(e.value)}  checked={selectedClickOnDocumentGroup.code === option.code}/>
                                                                                    <label>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </TabPanel>
                        
                        </TabView>
                    }
                </div>

            </div>
        </>
    )
}

export default Analytics

const ClickOnClaimChart = (props) => {
    const [chartData] = useState(props.chartData);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
        <div className='flex text-center align-content-center align-items-center'>
            <Chart className='my-auto flex' type="pie" data={chartData} options={lightOptions} style={{ width: '100%' }}/>
        </div>
    );
}

const ClickOnClaimChartByDate = (props) => {
    const [chartData] = useState(props.chartData);

    let stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    

    return (
        <div className='flex text-center align-content-center align-items-center'>
            <Chart className='my-auto flex' type="bar" data={chartData} options={stackedOptions} style={{ width: '100%' }}/>
        </div>
    );
}

const ClickOnDocumentChart = (props) => {
    const [chartData] = useState(props.chartData);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
        <div className='flex text-center align-content-center align-items-center'>
            <Chart className='my-auto flex' type="pie" data={chartData} options={lightOptions} style={{ width: '100%' }}/>
        </div>
    );
}

const ClickOnDocumentChartByDate = (props) => {
    const [chartData] = useState(props.chartData);

    let stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    

    return (
        <div className='flex text-center align-content-center align-items-center'>
            <Chart className='my-auto flex' type="bar" data={chartData} options={stackedOptions} style={{ width: '100%' }}/>
        </div>
    );
}
