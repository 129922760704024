import { DataView } from 'primereact/dataview';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import _ from 'underscore';

const MediaDataView = (props) => {
    const media = props.media;

    const itemTemplate = (media) => {
        console.log(media);
        return (
            <>
                <div className={"col-12 m-0 py-2 lg:px-2 flex align-items-stretch " + (props.rows === 2 ? 'lg:col-6' : 'lg:col-6 xl:col-4')}>

                    <div className='p-2 flex'>
                        <div className='grid border-round-md shadow-2 align-content-start'>


                            <div className='col-12 flex justify-content-end'>
                                <div className='flex'>
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-text mr-1" style={{width: '38px', height: '38px'}} onClick={() => props.onRemoveMedia(media)} />
                                </div>
                            </div>

                            <div className='col-4 flex justify-content-start align-items-center mx-0'>
                                <Image preview className="" imageStyle={{width: '100%', borderRadius: '10px'}} src={media.referredFile.url} alt="Image"/>
                            </div>

                            <div className='col col-8 grid p-0 m-0 align-content-start'>

                                <div className='col col-12'>
                                    <div className='col col-12 pt-0 mt-0'>
                                        <h5 className='mb-1 mt-0'>Nome</h5>
                                        <InputText type="text" className="block w-full" placeholder="Nome" value={media.name} onChange={(e) => props.onChangeMediaName(media, e)}/>
                                    </div>
                                    <div className='col col-12'>
                                        <h5 className='mb-1'>Descrizione</h5>
                                        <InputTextarea rows="3" className="block w-full" placeholder="Descrizione" value={media.description} onChange={(e) => props.onChangeMediaDescription(media, e)}/>
                                    </div>
                                </div>

                                <div className='col col-12 pl-3'>
                                    <h5 className='mb-1'>Mostra nel passaporto</h5>
                                    <div className='flex align-items-center'>
                                        <InputSwitch className='mr-3' checked={media.show} onChange={(e) => props.onChangeMediaShow(media, e)}/>
                                        {
                                            media.show &&
                                            <div>
                                                <Tooltip target=".target-icon-showDisplayName" style={{fontSize: '10px'}}/>
                                                <i className="pi pi-eye target-icon-showDisplayName" data-pr-tooltip="Il media sarà visibile nel passaporto del prodotto" data-pr-position="top" ></i>
                                            </div>
                                        }
                                        {
                                            !media.show &&
                                            <div>
                                                <Tooltip target=".target-icon-hideDisplayName" style={{fontSize: '10px'}}/>
                                                <i className="pi pi-eye-slash target-icon-hideDisplayName" data-pr-tooltip="Il media non sarà visibile nel passaporto del prodotto" data-pr-position="top" ></i>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return(
        <>
            <DataView value={media} layout={'grid'}
                itemTemplate={itemTemplate} paginator rows={props.rows} />
        </>
    )
}

export default MediaDataView;