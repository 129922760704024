import { Chart } from 'primereact/chart';
import { useState } from "react";

const SupplyChainChart = (props) => {
    const [chartData] = useState(props.chartData);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
        <div className='flex text-center align-content-center align-items-center'>
            <Chart className='my-auto flex' type="pie" data={chartData} options={lightOptions} style={{ width: '100%' }} />
        </div>
    );
}

export default SupplyChainChart