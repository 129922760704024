import { DataView } from 'primereact/dataview';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';

import {v4 as uuid} from 'uuid'

import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

const ClaimDataView = (props) => {
    const claims = props.claims;
    const documents = props.documents;
    const isList = props.isList;
    const navigate = useNavigate();

    const itemTemplate = (claim) => {
        console.log("claim: ", claim);
        return (
            <>
                <div className={"col-12 m-0 py-2 lg:px-2 flex align-items-stretch " + (props.rows === 2 ? 'lg:col-6' : 'lg:col-6 xl:col-4')}>

                    <div className='p-2 flex'>
                        <div className='grid border-round-md shadow-2 align-content-start'>

                            <div className='col-12 flex justify-content-end'>
                                <div className='flex'>
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-text mr-1" style={{width: '38px', height: '38px'}} onClick={() => props.onRemoveClaim(claim)} />
                                </div>
                            </div>

                            <div className='col-4 flex justify-content-start align-items-center mx-0'>
                                <Image preview className="" imageStyle={{width: '100%', borderRadius: '10px'}} src={claim.referredFile.url} alt="Image"/>
                            </div>

                            <div className='col col-8 grid p-0 m-0 align-content-start'>

                                <div className='col col-12 grid'>
                                    <div className='col col-12 pt-0 mt-0'>
                                        <h5 className='mb-1 mt-0'>Nome</h5>
                                        <InputText type="text" className="block w-full" placeholder="Nome" value={claim.name} onChange={(e) => props.onChangeClaimName(claim, e)}/>
                                    </div>
                                    <div className='col col-12'>
                                        <h5 className='mb-1'>Descrizione</h5>
                                        <InputTextarea rows="3" className="block w-full" placeholder="Descrizione" value={claim.description} onChange={(e) => props.onChangeClaimDescription(claim, e)}/>
                                    </div>
                                </div>

                                <div className='col col-12 grid'>
                                    <div className='col col-6'>
                                        <h5 className='mb-1 mt-0'>Tipologia</h5>
                                        <Dropdown className="w-full"  value={claim.type} options={props.claimTypes} onChange={(e) => props.onChangeClaimType(claim, e)}/>
                                    </div>
                                    <div className='col col-6'>
                                        {claim.confirmationDoc &&
                                            <>
                                                <h5 className='mb-1 mt-0'>Confermato in</h5>
                                                <Dropdown className="w-full"  value={claim.confirmationDoc} options={documents} optionLabel="name" onChange={(e) => props.onChangeClaimConfirmationDoc(claim, e)} filter showClear filterBy="name" />
                                            </>
                                        }
                                    </div>
                                </div>

                                {/* <div className='col col-12 mb-3'>
                                    {
                                        claim.status.code === 1 &&
                                        <Tag className="mr-2" icon="pi pi-check" value={claim.status.label} rounded severity='success'></Tag>
                                    }
                                    {
                                        claim.status.code === 2 &&
                                        <Tag className="mr-2" icon="pi pi-warn" value={claim.status.label} rounded severity='warning'></Tag>
                                    }
                                    {
                                        claim.status.code === 3 &&
                                        <Tag className="mr-2" icon="pi pi-times" value={claim.status.label} rounded severity='danger'></Tag>
                                    }
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return(
        <>
            <DataView value={claims} layout={'grid'}
                itemTemplate={itemTemplate} paginator rows={props.rows} />
        </>
    )
}

export default ClaimDataView;