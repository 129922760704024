
import StepFlowNode from './stepFlowNode'
import StepSupplierFlowNode from './stepSupplierFlowNode';
import SimpleFloatingEdge from './simpleFloatingEdge';
import TransportFloatingEdge from './transportFloatingEdge';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';


import React, { useEffect } from 'react';

import _ from  'underscore'
import ReactFlow, { Background, Controls, addEdge, useNodesState, useEdgesState, MarkerType, useReactFlow, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { useState } from 'react';
import { getDistanceFromLatLonInKm } from '../../services/UtilsService';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

const nodeTypes = { step: StepFlowNode, stepSupplier: StepSupplierFlowNode };
const edgeTypes = { simple:  SimpleFloatingEdge, transport: TransportFloatingEdge};

const transportTypes = [
    { label: 'Camion', iconBlack: 'https://imageshack.com/i/pmwOGuX0p', iconWhite: 'https://imageshack.com/i/pnbYWgFMp' },
    { label: 'Treno', iconBlack: 'https://imageshack.com/i/pmH3QZP3p', iconWhite: 'https://imageshack.com/i/pnBIVXOap' },
    { label: 'Aereo', iconBlack: 'https://imageshack.com/i/pmtMqeMRp', iconWhite: 'https://imageshack.com/i/pob0xXxgp' },
    { label: 'Nave', iconBlack: 'https://imageshack.com/i/pnpKHnGkp', iconWhite: 'https://imageshack.com/i/pme4amXip' },
    {}
]

const connectionTypes = [
    { label: 'Rapporto commerciale', code: 1 },
    { label: 'Trasporto', code: 2 },
    {}
]

const TransportFlowGraph = (props) => {

    const [displayAddEdge, setDisplayAddEdge] = useState();

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [newEdgeParams, setNewEdgeParams] = useState({});

    const onConnect = (params) => {
        setNewEdgeParams(params);

        setDisplayAddEdge(true);
    };

    const [rfInstance, setRfInstance] = useState(null);
    const { setViewport } = useReactFlow();

    const onSave = useCallback(() => {
        if (rfInstance) {
          const flow = rfInstance.toObject();
          props.onFlowSave({
            'id': uuid(),
            'schema': flow
          })
          //localStorage.setItem('transport-flow', JSON.stringify(flow));
        }
      }, [rfInstance]);
    
    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            const flow = JSON.parse(localStorage.getItem('transport-flow'));

            if (flow) {
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
            }/* else{
                loadFlowFirstTime();
            } */
        };

        restoreFlow();
    }, [setNodes, setViewport]);

    useEffect(() => {
        /* onRestore(); */
        loadFlowFirstTime();
    }, [])

    const [selectedConnectionType, setSelectedConnectionType] = useState();
    const selectedConnectionTypeTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-content-center flex-wrap care-item-value">
                    <div className='flex align-items-center'>{option.label}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const connectionTypesOptionTemplate = (option) => {
        return (
            <div className="flex align-content-center flex-wrap care-item-value">
                <Tooltip target=".target-icon-selectConnectionType" style={{fontSize: '10px'}}/>
                <div className='mb-1 justify-content-between flex-wrap'>{option.label}
                {
                    option.tooltip &&
                    <i className="pi pi-info-circle target-icon-selectConnectionType ml-3" data-pr-tooltip={option.tooltip} data-pr-position="top" ></i>
                }
                </div>
            </div>
        );
    }

    const [selectedTransportType, setSelectedTransportType] = useState();
    const selectedTransportTypeTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-content-center flex-wrap care-item-value">
                    <div className='flex align-items-center'>{option.label}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const transportTypesOptionTemplate = (option) => {
        return (
            <div>
                {
                    !_.isEmpty(option) &&

                    <div className='flex align-content-center flex-wrap care-item-value'>
                        <img className='flex align-items-center mr-3' src={option.iconBlack} alt="option icon" width="15px" height="15px"/>
                        <div className='flex align-items-center'>{option.label}</div>
                    </div>
                }
            </div>
        );
    }

    const handleEdgeChange = (edgeChanges) => {
        if(!_.any(edgeChanges, (change) => {
            return change.type === 'select';
        })){

            if(edgeChanges[0].id.split('---').length !== 1){
                const sourceID = (edgeChanges[0].id).split('---')[1].substring(0, 24);
                const targetID = (edgeChanges[0].id).split('---')[2].substring(0, 24);
                props.onRemoveLink(
                    sourceID,
                    targetID,
                );
                onEdgesChange(edgeChanges);
            }else{
                console.log("Non può essere rimosso");
            }
        }else{
            onEdgesChange(edgeChanges);
        }        
    };

    const addNewEdge = () => {
        const newEdgeParamsCopy = {...newEdgeParams};
        if(selectedConnectionType.code === 2){
            newEdgeParamsCopy.markerEnd = {
                type: MarkerType.ArrowClosed,
            };
        }

        const sourceID = (newEdgeParamsCopy.source).split('---')[1];
        const targetID = (newEdgeParamsCopy.target).split('---')[1];

        const fromSupplier = _.find(props.suppliers, (s) => {
            return s.id === sourceID.substring(0, sourceID.length - 2);
        });
        const toSupplier = _.find(props.suppliers, (s) => {
            return s.id === targetID.substring(0, targetID.length - 2);
        });
        
        var distance = 0;

        if(!_.isEmpty(fromSupplier) && !_.isEmpty(toSupplier)){
            const fromCoords = fromSupplier.placeOnEarth.split(', ');
            const toCoords = toSupplier.placeOnEarth.split(', ');
            distance = Math.round(getDistanceFromLatLonInKm(fromCoords[1],fromCoords[0],toCoords[1],toCoords[0]));
        }

        newEdgeParamsCopy.data = {
            vehicle: selectedTransportType,
            distance: distance
        };

        if(selectedConnectionType.code === 2){
            newEdgeParamsCopy.type = 'transport';
            props.onAddLink(
                sourceID.substring(0, sourceID.length - 2),
                targetID.substring(0, targetID.length - 2),
                sourceID.substring(sourceID.length - 1, sourceID.length),
                targetID.substring(targetID.length - 1, targetID.length),
                newEdgeParamsCopy.sourceHandle,
                newEdgeParamsCopy.targetHandle,
                (newEdgeParamsCopy.source).split('---')[0],
                (newEdgeParamsCopy.target).split('---')[0],
                selectedTransportType,
                distance
            )
        }else{
            newEdgeParamsCopy.type = 'simple';
            props.onAddLink(
                sourceID.substring(0, sourceID.length - 2),
                targetID.substring(0, targetID.length - 2),
                sourceID.substring(sourceID.length - 1, sourceID.length),
                targetID.substring(targetID.length - 1, targetID.length),
                newEdgeParamsCopy.sourceHandle,
                newEdgeParamsCopy.targetHandle,
                (newEdgeParamsCopy.source).split('---')[0],
                (newEdgeParamsCopy.target).split('---')[0],
                null,
                null
            )
        }

        setEdges((els) => addEdge(newEdgeParamsCopy, els));

        setDisplayAddEdge(false);
        setSelectedConnectionType(null);
        setSelectedTransportType(null);
    }

    const loadFlowFirstTime = () => {
        var mappedSteps = [];
        var mappedEdges = [];
        var Xidx = 0;
        var Yidx = 0;

        const stdOffsetRight = 20;
        const stdOffsetTop = 20;

        var lastTypeCode = 1;

        const orderedSupplyChainSteps = _.sortBy(props.supplyChainSteps, (s) => {
            return s.type.code;
        })

        _.each(orderedSupplyChainSteps, (step) => {
            if(step.type.code !== lastTypeCode){
                lastTypeCode = step.type.code;
                Xidx += 1;
                Yidx = 0;
            }

            mappedSteps.push({
                id: step.id,
                position: {x: (Xidx*340)+stdOffsetRight, y: (Yidx*300)+stdOffsetTop},
                type: 'step',
                data: step,
            })

            let suppCount = 0;

            if(!_.isEmpty(step.originSupplier)){
                suppCount+=1;
                mappedSteps.push({
                    id: step.id+'---'+step.originSupplier.id+'-1',
                    position: {x: ((suppCount-1)*80), y: 80+stdOffsetTop},
                    type: 'stepSupplier',
                    data: {originSupplier: step.originSupplier},
                    parentNode: step.id
                })

                mappedEdges.push({ 
                    id: step.id+'-'+step.originSupplier.id+'-origin', 
                    source: step.id, 
                    target: step.id+'---'+step.originSupplier.id+'-1',
                    type: "simple"
                });
            }

            if(!_.isEmpty(step.processorSupplier)){
                suppCount+=1;
                mappedSteps.push({
                    id: step.id+'---'+step.processorSupplier.id+'-2',
                    position: {x: ((suppCount-1)*80), y: 80+stdOffsetTop},
                    type: 'stepSupplier',
                    data: {processorSupplier: step.processorSupplier},
                    parentNode: step.id
                })
                mappedEdges.push({ 
                    id: step.id+'-'+step.processorSupplier.id+'-producer', 
                    source: step.id,
                    target: step.id+'---'+step.processorSupplier.id+'-2',
                    type: "simple"
                });
            }

            if(!_.isEmpty(step.traderSupplier)){
                suppCount+=1;
                mappedSteps.push({
                    id: step.id+'---'+step.traderSupplier.id+'-3',
                    position: {x: ((suppCount-1)*80), y: 80+stdOffsetTop},
                    type: 'stepSupplier',
                    data: {traderSupplier: step.traderSupplier},
                    parentNode: step.id
                })
                mappedEdges.push({ 
                    id: step.id+'-'+step.traderSupplier.id+'-trader', 
                    source: step.id, 
                    target: step.id+'---'+step.traderSupplier.id+'-3',
                    type: "simple"
                });
            }

            if(!_.isEmpty(step.owner)){
                suppCount+=1;
                mappedSteps.push({
                    id: step.id+'---'+step.owner.id+'-3',
                    position: {x: ((suppCount-1)*80), y: 80+stdOffsetTop},
                    type: 'stepSupplier',
                    data: {owner: step.owner},
                    parentNode: step.id
                })
                mappedEdges.push({ 
                    id: step.id+'-'+step.owner.id+'-owner', 
                    source: step.id, 
                    target: step.id+'---'+step.owner.id+'-3',
                    type: "simple"
                });
            }
            
            Yidx = Yidx+1;
        });

        _.each(props.links, (step) => {
            console.log((step));
            var newEdge = { 
                id: "reactflow__edge-"+step.fromStepTypeID+'---'+step.fromSupplierId+'-'+step.fromTypeCode+step.fromHandler+'-'+step.toStepTypeID+'---'+step.toSupplierId+'-'+step.toTypeCode+step.toHandler, 
                source: step.fromStepTypeID+'---'+step.fromSupplierId+'-'+step.fromTypeCode, 
                sourceHandle: step.fromHandler,
                target: step.toStepTypeID+'---'+step.toSupplierId+'-'+step.toTypeCode, 
                targetHandle: step.toHandler,
                type: step.vehicle !== null ? "transport" : 'simple', 
            }

            if(step.vehicle !== null){
                newEdge.data = {
                    vehicle: step.vehicle,
                    distance: step.distance
                };
                newEdge.markerEnd = {
                    type: MarkerType.ArrowClosed,
                };
            }

            mappedEdges.push(newEdge);
        })

        setEdges(mappedEdges);
        setNodes(mappedSteps);
        
    };

    const toolbarLeftContent = (
        <React.Fragment>

        </React.Fragment>
        
    );

    const toolbarRightContent = (
        <React.Fragment>
            <Button icon="pi pi-save" label='Salva' className="prisma-blue-bg no-border flex mr-3" onClick={onSave}/>
            <Button icon="pi pi-save" label='Ripristina' className="prisma-blue-bg no-border flex" onClick={onRestore}/>
        </React.Fragment>
    );

    return (
        <>
            <div>
                {/* <Toolbar left={toolbarLeftContent} right={toolbarRightContent} /> */}
            </div>
            <div className='my-3 mr-2 border-round-md shadow-2 p-2'>
                <div className='' style={{ height: '55vh', width: '100%' }}>
                    <ReactFlow 
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={handleEdgeChange}
                        onConnect={onConnect}
                        fitView
                        connectionMode='loose'
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onInit={setRfInstance}
                    >
                        <Background />
                        <Controls />
                    </ReactFlow>
                </div>
            </div>

            <Dialog header='Aggiungi collegamento' visible={displayAddEdge} modal={true} style={{width: '30vw'}}
                draggable={false} resizable={true} onHide={() => setDisplayAddEdge(false)}>

                    <h5 className='mb-1 flex justify-content-between flex-wrap'>Tipologia</h5>
                    <Dropdown className="w-full"  value={selectedConnectionType} options={connectionTypes} optionLabel="label" onChange={(e) => setSelectedConnectionType(e.target.value)} placeholder="Seleziona una tipologia"
                        valueTemplate={selectedConnectionTypeTemplate} itemTemplate={connectionTypesOptionTemplate}/>

                    {
                        (!_.isEmpty(selectedConnectionType) && selectedConnectionType.code === 2) &&
                        <div>
                            <Tooltip target=".target-icon-selectTransport" style={{fontSize: '10px'}}/>
                            <h5 className='mb-1 flex justify-content-between flex-wrap'>Veicolo<i className="pi pi-info-circle target-icon-selectTransport" data-pr-tooltip="Selezionare il mezzo di trasporto" data-pr-position="top" ></i></h5>
                            <Dropdown className="w-full"  value={selectedTransportType} options={transportTypes} optionLabel="label" onChange={(e) => setSelectedTransportType(e.target.value)} placeholder="Seleziona il veicolo usato per il trasporto"
                                valueTemplate={selectedTransportTypeTemplate} itemTemplate={transportTypesOptionTemplate}/>
                        </div>
                    }

                    <div className='w-full justify-content-end flex mt-2'>
                        <Button icon="pi pi-arrow-right" className="prisma-green-bg text-blue no-border" onClick={addNewEdge} disabled={_.isEmpty(selectedConnectionType) || (selectedConnectionType.code === 2 && _.isEmpty(selectedTransportType))}/>
                    </div>
            </Dialog>
        </>
    )
}

export default (props) => (
    <ReactFlowProvider>
      <TransportFlowGraph 
        supplyChainSteps={props.supplyChainSteps} 
        links={props.links} 
        onAddLink={props.onAddLink} 
        onRemoveLink={props.onRemoveLink}
        suppliers={props.suppliers}
        onFlowSave={props.onFlowSave}
      />
    </ReactFlowProvider>
);