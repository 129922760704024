import _ from 'underscore';
import { v4 as uuid } from 'uuid';
import { supplyChainStepModelToView } from './DbUtils'

export function setCurrentLocalState(newState){
    localStorage.setItem("general_info_state", JSON.stringify(newState.generalInformations));
    localStorage.setItem("supply_chain_steps_state", JSON.stringify(newState.supplyChainSteps));
    localStorage.setItem("suppliers_db", JSON.stringify(newState.suppliers));
}

export function getCurrentLocalState(){
    var state = {
        'generalInformations': JSON.parse(localStorage.getItem('general_info_state')),
        'supplyChainSteps': JSON.parse(localStorage.getItem('supply_chain_steps_state')),
        'suppliers': JSON.parse(localStorage.getItem('suppliers_db')),
    }
    return state;
}

export function saveSupplier(supplier){
    /* if(localStorage.getItem('suppliers_db')){
        let suppliers = JSON.parse(localStorage.getItem('suppliers_db'));
        let supplierIndex = _.findIndex(suppliers, (supp) => {
            return supp.id === supplier.id;
        });

        if(supplierIndex !== -1){
            suppliers[supplierIndex] = supplier;
        }else{
            suppliers.push(supplier);
        }
        localStorage.setItem("suppliers_db", JSON.stringify(suppliers))
    }else{
        let suppliers = [];
        suppliers.push(supplier);
        localStorage.setItem("suppliers_db", JSON.stringify(suppliers))
    } */
}

/* Filter has type {'prop', 'value'} */
export function getSuppliers(filter){
    const suppliers = JSON.parse(localStorage.getItem('suppliers_db'));
    if(filter){
        return [];
    }else{
        return suppliers;   
    }
}

export function getSupplierByID(id){
    const suppliers = JSON.parse(localStorage.getItem('suppliers_db'))
    return _.find(suppliers, (s) => {
        return s.id === id;
    });
}

export function saveSupplyChainStep(step){
    if(localStorage.getItem('supply_chain_steps_db')){
        let supplyChainSteps = JSON.parse(localStorage.getItem('supply_chain_steps_db'));

        var index =_.findIndex(supplyChainSteps, (s) => {
            return s.id === step.id;
        })
        if(index !== -1){ //if exists the save is an update
            supplyChainSteps[index] = step;
        }else{ //otherwise is an insert and ID must be created
            supplyChainSteps.push(step);
        }
        localStorage.setItem("supply_chain_steps_db", JSON.stringify(supplyChainSteps))
    }else{
        let supplyChainSteps = [];
        supplyChainSteps.push(step);
        localStorage.setItem("supply_chain_steps_db", JSON.stringify(supplyChainSteps))
    }
}

export function getSupplyChainStep(id){
    const supplyChainSteps = JSON.parse(localStorage.getItem('supply_chain_steps_db'));
    if(id){
        return _.find(supplyChainSteps, (s) => {
            return s.id === id;
        });
    }else{
        return supplyChainSteps;   
    }
}

export function saveFile(file){
    const fileId = uuid();

    localStorage.setItem("db-"+fileId, JSON.stringify({
        'file': file
    }));

    return fileId;

}

export function getFile(fileId){
    return JSON.parse(localStorage.getItem("db-"+fileId))
}

export function saveProduct(product){
    if(product.supplyChainSteps.length !== 0){
        _.each(product.supplyChainSteps, (step) => {
            saveSupplyChainStep(step);
        });
        product.supplyChainSteps = _.map(product.supplyChainSteps, (s) => {
            return s.id;
        })
    }

    if(localStorage.getItem('products_db')){
        let products = JSON.parse(localStorage.getItem('products_db'));

        if(_.has(product, 'id')){ //if exists the save is an update
            let productIndex = _.findIndex(products, (prod) => {
                return prod.id === product.id;
            });

            if(productIndex !== -1){
                products[productIndex] = product;
            }
        }else{ //otherwise is an insert and ID must be created
            product.id = uuid();
            products.push(product);
        }
        
        localStorage.setItem("products_db", JSON.stringify(products))
    }else{
        let products = [];
        product.id = uuid();
        products.push(product);
        localStorage.setItem("products_db", JSON.stringify(products))
    }
}

/* Filter has type {'prop', 'value'} */
export function getProducts(filter){
    const products = JSON.parse(localStorage.getItem('products_db'));
    if(filter){
        return [];
    }else{
        return products;   
    }
}

export function deleteProduct(id){
    const products = JSON.parse(localStorage.getItem('products_db'));
    var productsCopy = _.reject(products, (p) => {
        return p.id === id;
    })

    localStorage.setItem("products_db", JSON.stringify(productsCopy));
}

export function generateProductUnity(productId){
    const id = uuid();
    const unity = {
        id: id,
        supplyChainSteps: [],
        supplyChainLink: [],
        flow: {},
        product: productId,
        nfcTagID: ""
    }
    if(localStorage.getItem('product_unity_db')){
        let productUnities = JSON.parse(localStorage.getItem('product_unity_db'));
        productUnity.push(unity)
        
        localStorage.setItem("product_unity_db", JSON.stringify(productUnities))
    }else{
        let productUnities = [];
        productUnities.push(unity);
        localStorage.setItem("product_unity_db", JSON.stringify(productUnities))
    }

    return id;
}

export function getProductUnityById(id){
    const productUnities = JSON.parse(localStorage.getItem('product_unity_db'));
    return _.filter(productUnities, (pu) => {
        return pu.id === id;
    })
}

export function getProductUnityByProduct(id){
    const productUnities = JSON.parse(localStorage.getItem('product_unity_db'));
    return _.filter(productUnities, (pu) => {
        return pu.product === id;
    })
}

export function saveSupplyChain(supplyChain){
    var modelValue = supplyChain;
    modelValue.steps = _.map(supplyChain.steps, (s) => {
        return s.id;
    })

    if(localStorage.getItem('supply_chains_db')){
        let supplyChains = JSON.parse(localStorage.getItem('supply_chains_db'));

        supplyChain.id = uuid();
        supplyChains.push(modelValue);
        localStorage.setItem("supply_chains_db", JSON.stringify(supplyChains))
    }else{
        let supplyChains = [];
        supplyChain.id = uuid();
        supplyChains.push(modelValue);
        localStorage.setItem("supply_chains_db", JSON.stringify(supplyChains))
    }
}

/* Filter has type {'prop', 'value'} */
export function getSupplyChains(filter){
    const supplyChains = JSON.parse(localStorage.getItem('supply_chains_db'));
    var viewValue = [];

    _.each(supplyChains, (sc) => {
        var supplyChainSteps = [];

        _.each(sc.steps, (stepId) => {
            supplyChainSteps.push(supplyChainStepModelToView(getSupplyChainStep(stepId)));
        })

        viewValue.push({
            'name': sc.name,
            'steps': supplyChainSteps
        })
    });

    if(filter){
        return [];
    }else{
        return viewValue;   
    }
}

export function saveProductFlow(flow, prodId){
    if(localStorage.getItem('products_db')){
        let products = JSON.parse(localStorage.getItem('products_db'));

        let productIndex = _.findIndex(products, (prod) => {
            return prod.id === prodId;
        });

        if(productIndex !== -1){
            products[productIndex].flow = flow.id;
            localStorage.setItem("products_db", JSON.stringify(products))
        }
    }
    localStorage.setItem(flow.id, JSON.stringify(flow.schema))
}

export function getConnectionLinks(){
    return localStorage.getItem("supply_chains_links")
}

export function saveConnectionLinks(links){
    localStorage.setItem("supply_chains_links", JSON.stringify(links))
}

export function saveInteraction(type, interaction){
    interaction.id === uuid();
    if(type === 'click_on_claim'){
        var clickOnClaim = JSON.parse(localStorage.getItem("click_on_claim_stats"));
        if(clickOnClaim){
            clickOnClaim.push(interaction)
            localStorage.setItem("click_on_claim_stats", JSON.stringify(clickOnClaim))
        }else{
            let clickOnClaim = [];
            clickOnClaim.push(interaction);
            localStorage.setItem("click_on_claim_stats", JSON.stringify(clickOnClaim))
        }
    }

    if(type === 'click_on_document'){
        var clickOnDoc = JSON.parse(localStorage.getItem("click_on_document_stats"));
        if(clickOnDoc){
            clickOnDoc.push(interaction)
            localStorage.setItem("click_on_document_stats", JSON.stringify(clickOnDoc))
        }else{
            let clickOnDoc = [];
            clickOnDoc.push(interaction);
            localStorage.setItem("click_on_document_stats", JSON.stringify(clickOnDoc))
        }
    }

    if(type === 'step_opened'){
        var stepOpened = JSON.parse(localStorage.getItem("step_opened_stats"));
        if(stepOpened){
            stepOpened.push(interaction)
            localStorage.setItem("step_opened_stats", JSON.stringify(stepOpened))
        }else{
            let stepOpened = [];
            stepOpened.push(interaction);
            localStorage.setItem("step_opened_stats", JSON.stringify(stepOpened))
        }
    }

    if(type === 'tab_opened'){
        var tabOpened = JSON.parse(localStorage.getItem("tab_opened_stats"));
        if(tabOpened){
            tabOpened.push(interaction)
            localStorage.setItem("tab_opened_stats", JSON.stringify(tabOpened))
        }else{
            let tabOpened = [];
            tabOpened.push(interaction);
            localStorage.setItem("tab_opened_stats", JSON.stringify(tabOpened))
        }
    }

    if(type === 'passport_opened'){
        var passportOpened = JSON.parse(localStorage.getItem("passport_opened_stats"));
        if(passportOpened){
            passportOpened.push(interaction)
            localStorage.setItem("passport_opened_stats", JSON.stringify(passportOpened))
        }else{
            let passportOpened = [];
            passportOpened.push(interaction);
            localStorage.setItem("passport_opened_stats", JSON.stringify(passportOpened))
        }
    }
}

export function getInteractions(){
    var clickOnClaim = JSON.parse(localStorage.getItem("click_on_claim_stats"));
    var clickOnDoc = JSON.parse(localStorage.getItem("click_on_document_stats"));
    var stepOpened = JSON.parse(localStorage.getItem("step_opened_stats"));
    var tabOpened = JSON.parse(localStorage.getItem("tab_opened_stats"));

    return {
        'clickOnClaim': clickOnClaim,
        'clickOnDocument': clickOnDoc,
        'stepOpened': stepOpened,
        'tabOpened': tabOpened
    }
}

export function getInteractionsByProduct(productId){
    var interactions = getInteractions();

    return {
        'clickOnClaim': _.filter(interactions.clickOnClaim, (int) => {return int.productId === productId}),
        'clickOnDocument': _.filter(interactions.clickOnDocument, (int) => {return int.productId === productId}),
        'stepOpened': _.filter(interactions.stepOpened, (int) => {return int.productId === productId}),
        'tabOpened': _.filter(interactions.tabOpened, (int) => {return int.productId === productId}),
    }
}


