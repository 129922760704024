import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Rating } from 'primereact/rating';
import { SelectButton } from 'primereact/selectbutton';
import { Divider } from 'primereact/divider';

import { classNames } from 'primereact/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNavigate } from "react-router-dom";
import _ from 'underscore';
import { v4 as uuid } from 'uuid';

import SupplierItemDataView from './supplierItemDataView';
import { useState } from 'react';


const SuppliersList = (props) => {
    const suppliers = props.suppliers;

    const paginatorTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Precedente</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Successiva</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        'CurrentPageReport': (options) => {
            return (
                <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    Vai a <InputText size="2" className="ml-1" value={props.currentPage} tooltip={props.pageInputTooltip}
                        onKeyDown={(e) => props.onPageInputKeyDown(e, options)} onChange={props.onPageInputChange}/>
                </span>
            )
        }
    };

    const nameTemplate = (rowData) => {
        return (
            <div className='flex align-items-center'>
                <div className='flex align-items-center'>
                    <Image src={"https://imageshack.com/i/poR1pn6Pj"} className="flex" style={{height: '75px', width: "auto"}} preview/>
                    <div className='ml-3'>
                        <div>{rowData.name}</div>
                        <div>({rowData.companyName})</div>
                    </div>
                </div>
                <div className='ml-3'>
                    {
                        rowData.showDisplayName &&
                        <div>
                            <Tooltip target=".target-icon-showDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye target-icon-showDisplayName" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                    {
                        !rowData.showDisplayName &&
                        <div>
                            <Tooltip target=".target-icon-hideDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye-slash target-icon-hideDisplayName" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const addressTemplate = (rowData) => {
        return (
            <div className='flex align-items-center'>
                <div>
                    <div><FontAwesomeIcon icon="road" className='mr-2'/>{rowData.address}</div>
                    <div><FontAwesomeIcon icon="city" className='mr-2'/>{rowData.city}</div>
                </div>
                <div className='ml-3'>
                    {
                        (rowData.address != '' && rowData.showAddress) &&
                        <div>
                            <Tooltip target=".target-icon-showDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye target-icon-showDisplayName" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                    {
                        (rowData.address != '' && !rowData.showAddress) &&
                        <div>
                            <Tooltip target=".target-icon-hideDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye-slash target-icon-hideDisplayName" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                    {
                        (rowData.city != '' && rowData.showCity) &&
                        <div>
                            <Tooltip target=".target-icon-showDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye target-icon-showDisplayName" data-pr-tooltip="Il campo sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                    {
                        (rowData.city != '' && !rowData.showCity) &&
                        <div>
                            <Tooltip target=".target-icon-hideDisplayName" style={{fontSize: '10px'}}/>
                            <i className="pi pi-eye-slash target-icon-hideDisplayName" data-pr-tooltip="Il campo non sarà visibile per l'utente finale" data-pr-position="top" ></i>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const contactsTemplate = (rowData) => {
        return(
            <div className='flex align-items-center'>
                <div>
                    <div><FontAwesomeIcon icon="envelope" className='mr-2'/>{rowData.email}</div>
                    <div><FontAwesomeIcon icon="phone" className='mr-2'/>{rowData.phoneNumber}</div>
                    <div><FontAwesomeIcon icon="globe" className='mr-2'/>{rowData.website}</div>
                </div>
            </div>
        )
    }

    const descriptionTemplate = (rowData) => {
        return(
            <div style={{maxHeight: '70px', overflow: 'scroll'}}>
                {rowData.description}
            </div>
        )
    }

    const scoreTemplate = (rowData) => {
        let score = Math.round(rowData.score*5/100);

        const toDoList = []

        _.each(rowData.toDo, (toDo) => {
            toDoList.push(
                <div key={uuid()}>
                    {toDo}
                </div>
            )
        })

        const toDoDiv = (
            <div>
                {
                    toDoList.length !== 0 ? 
                    
                    <div>
                        Il tuo punteggio attuale è di {score}/5.  
                        <span> Ecco alcune cose che puoi fare per aumentarlo: {toDoList}</span> 
                    </div> :
                    "Hai raggiunto il punteggio massimo, complimenti!"
                }
            </div>
            
        );

        return(
            <div className='flex align-items-center'>
                <Rating
                    value={score}
                    cancel={false} 
                    onIcon={
                        <img
                            src={"https://imageshack.com/i/pmmziZ4rp"}
                            alt="custom-image-active"
                            width="15px"
                            height="15px"
                        />
                    }
                    offIcon={<img src={"https://imageshack.com/i/pnOW1K2gp"} alt="custom-image" width="15px" height="15px" />}
                />
                <Tooltip target={".score-tooltip"+rowData.id} style={{fontSize: '10px'}}>
                    {toDoDiv}
                </Tooltip>
                <FontAwesomeIcon icon="info-circle" className={'ml-2 ' + ('score-tooltip'+rowData.id)} data-pr-position="top"/>
            </div>
        )
    }

    const actionsTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center'>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" style={{color: 'black'}} onClick={() => goToSupplierEdit(rowData.id)} />
            </div>
        )
    }

    const mediaTemplate = (rowData) => {
        let content = [];

        _.each(rowData.media, (m) => {
            content.push(
                <span key={uuid()} className="flex mr-2">
                    <Image src={m.url} className="flex" style={{height: '35px', width: "auto"}} preview/>
                </span>
            )
        })

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.media.length !== 0 &&
                    <div>
                        <div className='w-full'>
                            Totale:  {rowData.media.length}
                        </div>
                        <div className='flex justify-content-start align-items-center mt-3' style={{maxWidth: '100px', overflow: "scroll"}}>
                            {content}
                        </div>
                    </div>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun media</span>
                }
            </div>
        )
    }

    const claimTemplate = (rowData) => {
        let content = [];

        _.each(rowData.claims, (c) => {
            content.push(
                <span key={uuid()} className="flex mr-2">
                    <Image src={c.url} className="flex" style={{height: '35px', width: "auto"}} preview/>
                </span>
            )
        })

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.media.length !== 0 &&
                    <div>
                        <div className='w-full'>
                            Totale:  {rowData.claims.length}
                        </div>
                        <div className='flex justify-content-start align-items-center mt-3' style={{maxWidth: '100px', overflow: "scroll"}}>
                            {content}
                        </div>
                    </div>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun claim</span>
                }
            </div>
        )
    }

    const docsTemplate = (rowData) => {

        return (
            <div className='flex align-content-center justify-content-center'>
                {
                    rowData.documents.length !== 0 &&
                    <div className='w-full'>
                        Totale:  {rowData.documents.length}
                    </div>
                }
                {
                    rowData.media.length === 0 &&

                    <span><FontAwesomeIcon icon='triangle-exclamation' className='mr-1' style={{width: '10px'}}></FontAwesomeIcon>Nessun documento</span>
                }
            </div>
        )
    }

    const navigate = useNavigate();

    const goToSupplierEdit = (id) => {
        navigate("/supplierNewEdit/"+id);
    }

    const supplierVisualizationOption = [
        { label: 'tabella', icon: 'pi pi-list', code: 1},
        { label: 'box', icon: 'pi pi-th-large', code: 2 }
    ]

    const [selectedSupplierVisualizationOption, setSelectedSupplierVisualizationOption] = useState(supplierVisualizationOption[1]);

    const setSelectedSupplierVisualizationOptionControl = (value) => {
        if(value!==null){
            setSelectedSupplierVisualizationOption(value);
        }
    }
    
    const optionTemplate = (option) => {
        return <i className={option.icon}></i>;
    }

    return(
        <>
            <div className='flex mt-5 justify-content-between'>
                <div className=''>
                    <Button icon="pi pi-plus" label="Crea nuovo" className="prisma-green-bg text-blue no-border mr-2" onClick={() => navigate("/supplierNewEdit")}/>
                </div>
                <div className='flex'>
                    <SelectButton value={selectedSupplierVisualizationOption} options={supplierVisualizationOption} onChange={(e) => setSelectedSupplierVisualizationOptionControl(e.value)} itemTemplate={optionTemplate} optionLabel="value" />
                </div>
            </div>

            <Divider className='divider-blue'></Divider>

            {
                selectedSupplierVisualizationOption.code === 1 && 

                <DataTable resizableColumns columnResizeMode="expand" showGridlines className='mt-5' value={suppliers} 
                    stripedRows responsiveLayout="scroll" paginator paginatorTemplate={paginatorTemplate} first={props.startRecord} 
                    rows={props.recordsPerPage} onPage={props.onPageChange}
                >

                    <Column bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionsTemplate} />
                    <Column header="Nome" body={nameTemplate}></Column>
                    <Column header="Descrizione" body={descriptionTemplate}></Column>
                    <Column header="Indirizzo" body={addressTemplate}></Column>
                    <Column header="Contatti" body={contactsTemplate}></Column>
                    <Column header="Punteggio" body={scoreTemplate}></Column>
                    <Column header="Media" body={mediaTemplate}></Column>
                    <Column header="Claim" body={claimTemplate}></Column>
                    <Column header="Documenti" body={docsTemplate}></Column>

                </DataTable>
            }

            {
                selectedSupplierVisualizationOption.code === 2 && 

                <SupplierItemDataView suppliers={suppliers} onScoreTemplate={scoreTemplate}/* onDeleteCurrentProduct={deleteCurrentProduct} onGenerateProductUnity={generateProductUnity} onSetSelectedRow={setSelectedRow} onSetShowRowDetail={setShowRowDetail} *//>
            }
        </>
    )
}

export default SuppliersList