import { TabView, TabPanel } from 'primereact/tabview';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import QRCode from "react-qr-code";

import { v4 as uuid } from 'uuid';
import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useState } from 'react';

import { getProductUnities } from '../services/RemoteService';
import { getStepClaimsMediaDocs } from '../services/UtilsService'

import { productModelToView } from '../services/DbUtils';
import SupplyChainChart from './supplyChainChart';

var BASEURL = ""
if(window.location.host.includes("localhost")){
    BASEURL = "http://localhost:3000"; 
  }else{
    BASEURL = "https://platform.blockvision.it";
  }

const ProductDetail = (props) => {

    const data = productModelToView(props.product);

    const supplyChainTypeOptions = [
        {
            'code': 1,
            'colorCode': "#42A5F5"
        },
        {
            'code': 2,
            'colorCode': "#42F8F5"
        },
        {
            'code': 3,
            'colorCode': "#90A5F5"
        },
        {
            'code': 4,
            'colorCode': "#23A5D5"
        },
        {
            'code': 5,
            'colorCode': "#40AF25"
        },
        {
            'code': 6,
            'colorCode': "#AAA6F5"
        },
        {
            'code': 7,
            'colorCode': "#3289F5"
        },
        {
            'code': 8,
            'colorCode': "#10A512"
        }
    ]

    const [productUnities, setProductUnities] = useState([]);
    const [qrProductUnity, setQrProductUnity] = useState({});
    useEffect(() => {
        const fetchProductUnities = async () => {
            const qrProductUnityResponse = await getProductUnities({
                "nfcTagId": null,
                "productId": props.product.id
                });
            if(qrProductUnityResponse.status === 200){
                setQrProductUnity(qrProductUnityResponse.response.data.productUnities[0]);
            }

            const productUnitiesResponse = await getProductUnities({
                "productId": props.product.id
                });
            if(productUnitiesResponse.status === 200){
                if(productUnitiesResponse.response.data.productUnities.length > 1 || 
                (productUnitiesResponse.response.data.productUnities.length == 1 && 
                    productUnitiesResponse.response.data.productUnities[0].nfcTagId))
                {
                    setProductUnities(_.filter(productUnitiesResponse.response.data.productUnities, (pu) => {
                        return pu.nfcTagId;
                    }));
                }
            }
        }

        fetchProductUnities()
    }, [])
    
    const chartData ={
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                ],
                hoverBackgroundColor: [
                ]
            }
        ]
    };
    
    const groupedSteps = _.toArray(_.groupBy(data.supplyChainSteps, (step) => {
        return step.type.code;
    }));
    
    const groupedLinks = _.toArray(_.groupBy(data.supplyChainLinks, (link) => {
        return link.vehicle?.label;
    }));
    
    const mappedSteps = _.map(groupedSteps, (step) => {
        return {
            'num': step.length,
            'label': step[0].type.label,
            'code': step[0].type.code
        }
    })

    const mappedLinks = _.map(groupedLinks, (link) => {
        var distance = 0;

        _.each(link, (l) => {
            distance+=l.distance;
        })
        
        return {
            'num': link.length,
            'label': link[0].vehicle?.label,
            'icon': link[0].vehicle?.iconBlack,
            'distance': distance
        }
    })
    
    const mappedStepsDiv = [];
    const mappedLinksDiv = [];

    _.each(mappedSteps, (step) => {
        const stepColor = (_.find(supplyChainTypeOptions, (type) => {
            return type.code === step.code;
        })).colorCode;
        
        chartData.labels.push(step.label);
        chartData.datasets[0].data.push(step.num);
        chartData.datasets[0].backgroundColor.push(stepColor);
        chartData.datasets[0].hoverBackgroundColor.push(stepColor);

        mappedStepsDiv.push(
            <div key={uuid()} className="mb-1">
                <Badge value={step.num} className="mr-2" style={{backgroundColor: stepColor}}></Badge>{step.label}
            </div>
        )
    })

    _.each(mappedLinks, (link) => {
        mappedLinksDiv.push(
            <div key={uuid()} className="mb-1 flex">
                <div className='flex'>
                    <Badge value={link.num} className="mr-2" style={{backgroundColor: 'grey'}}></Badge>
                </div>
                <div className=''>
                    <div>{link.label}</div>
                    <h5 className='m-0 p-0'>Total distance: {link.distance}km</h5>
                </div>
            </div>
        )
    })

    const media = [];
    const docs = [];
    const claims = [];

    const noMediaSteps = [];
    const noDocsSteps = [];
    const noClaimsSteps = [];

    _.each(data.supplyChainSteps, (step) => {
        let [tClaims, tMedia, tDocs] = getStepClaimsMediaDocs(step);

        _.each(tMedia, (m) => {
            media.push(m);
        })
        if(tMedia.length === 0){
            noMediaSteps.push(
                <div key={uuid()} className="mb-1 flex">
                    <div className='flex'>
                        <Image className="flex mr-2" imageStyle={{width: '15px', height: '15px'}} src={step.type.iconBlack}/>
                    </div>
                    <div className=''>
                        <div>{step.name}</div>
                    </div>
                </div>
            ); 
        }

        _.each(tDocs, (d) => {
            docs.push(d);
        })
        if(tDocs.length === 0){
            noDocsSteps.push(
                <div key={uuid()} className="mb-1 flex">
                    <div className='flex'>
                        <Image className="flex mr-2" imageStyle={{width: '15px', height: '15px'}} src={step.type.iconBlack}/>
                    </div>
                    <div className=''>
                        <div>{step.name}</div>
                    </div>
                </div>
            ); 
        }

        _.each(tClaims, (c) => {
            claims.push(c);
        })
        if(tClaims.length === 0){
            noClaimsSteps.push(
                <div key={uuid()} className="mb-1 flex">
                    <div className='flex'>
                        <Image className="flex mr-2" imageStyle={{width: '15px', height: '15px'}} src={step.type.iconBlack}/>
                    </div>
                    <div className=''>
                        <div>{step.name}</div>
                    </div>
                </div>
            ); 
        }
    });

    const productUnityHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <Image className="flex mr-2" imageStyle={{width: '25px', height: '25px'}} src={"https://imageshack.com/i/pnKV13A4p"} alt="Image"/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Digital Product Passport</span>
            </a>
        )
    }

    const supplyChainHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <Image className="flex mr-2" imageStyle={{width: '25px', height: '25px'}} src={"https://imageshack.com/i/poLFN8Trp"} alt="Image"/>
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Overview di filiera</span>
            </a>
        )
    }

    /* const generalProductUnity = () => {
        const filteredProductUnities = _.find(productUnities, (pu) => {
            return pu.nfcTagID === "" && pu.supplyChainSteps.length === 0 && pu.supplyChainLink.length === 0
        })

        return filteredProductUnities
    } */

    const qrTemplate = (rowData) => {
        return(
            <>
                <div className='flex justify-content-start' style={{maxHeight: '80px'}}>
                    <QRCode className='p-2' value={BASEURL+"/digitalProductPassport/"+rowData.nfcTag.internalId} style={{height: 'auto', width: '100%'}}/>
                </div>
                <small className='flex mt-3'>
                    <a href={BASEURL+"/digitalProductPassport/"+rowData.nfcTag.prismaId} target="_blank">{BASEURL+"/digitalProductPassport/"+rowData.nfcTag.internalId}</a>
                </small>
            </>
        )
    }

    return(
        <>
            <div>
                <TabView className="tabview-header-icon">

                    <TabPanel headerTemplate={productUnityHeaderTemplate} className='p-2'>
                        <div className='col col-12 grid p-0 mx-0' style={{maxHeight: "60vh", overflow: 'scroll'}}>
                            {
                                !_.isEmpty(qrProductUnity) &&
                                <>
                                    <div className='col col-12 mx-0 px-0'>
                                        {
                                            !_.isEmpty(productUnities) &&
                                            <Message severity="warn" text="Il Qr code mostrato rappresenta il passaporto digitale generico del prodotto. 
                                            In caso si voglia visualizzare il passaporto di uno specifico prodotto selezionare o inserire l'ID del tag NFC dalla lista a destra
                                            per visualizzare il link specifico." className='w-full justify-content-start'/>
                                        }
                                        {
                                            _.isEmpty(productUnities) &&
                                            <Message severity="info" text="Il Qr code mostrato rappresenta il passaporto digitale generico del prodotto." className='w-full justify-content-start'/>
                                        }
                                    </div>
                                    <div className="col-12 lg:col-6 col grid">
                                        <h3 className='col col-12'>Codice Qr Generico</h3>
                                        <div className='flex justify-content-start col-4'>
                                            <QRCode className='p-2' value={BASEURL+"/digitalProductPassport/"+qrProductUnity.id} style={{height: 'auto', width: '100%'}}/>
                                        </div>
                                        <div className='mt-3 flex grid col-12'>
                                            <div className='col-12'>
                                                <div className='flex align-items-center'>
                                                    <FontAwesomeIcon icon="arrow-up-right-from-square" className='mr-2'/>
                                                    <h5 className='m-0 p-0'>Direct Link</h5>
                                                </div>
                                                <small className='flex mt-3'>
                                                    <a href={BASEURL+"/digitalProductPassport/"+qrProductUnity.id} target="_blank">{BASEURL+"/digitalProductPassport/"+qrProductUnity.id}</a>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !_.isEmpty(productUnities) &&
                                        <div className="col-12 lg:col-6 col grid">
                                            <h3 className='col col-12'>Unità di prodotto</h3>
                                            <div className="col-12 col" style={{maxHeight: '40vh', overflow: 'scroll'}}>
                                                <DataTable resizableColumns columnResizeMode="expand" showGridlines className='mt-5' value={productUnities} 
                                                    stripedRows responsiveLayout="scroll"
                                                >
                                                    <Column header="Nome" body={qrTemplate}></Column>
                                                    <Column header="Id interno" field="nfcTag.internalId"></Column>
                                                    <Column header="Id esterno" field="nfcTag.prismaId"></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                    }
                                    {
                                        _.isEmpty(productUnities) &&
                                        <h5><FontAwesomeIcon icon="fa-triangle-exclamation" className='mr-2'/>Non è presente nessun tag NFC registrato per questo prodotto.</h5>
                                    }
                                </>
                            }

                            {
                                _.isEmpty(qrProductUnity) &&

                                <Message severity="warn" text="Non sono stati ancora registrate unità di prodotto" className='w-full justify-content-start'/>
                            }

                        </div>
                    </TabPanel>

                    <TabPanel headerTemplate={supplyChainHeaderTemplate} className='p-2'>
                        <div className="col-12 p-0 mx-0 grid align-content-start justify-content-between">
                            {
                                mappedSteps.length !== 0 &&
                                <>
                                    <div className='col-12 lg:col-4'>
                                        <SupplyChainChart chartData={chartData}></SupplyChainChart>
                                    </div>
                                    <div className='col-12 mx-0 lg:col-8 lg:px-5 grid align-content-center' style={{fontSize: '15px'}}>
                                        <div className='col-6 lg:col-4 px-1 lg:px-3'>
                                            <h5 className='mb-1'>Numero totale di step</h5>
                                            <Badge value={mappedSteps.length} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                            <div className='my-3'>
                                                <small>
                                                    di cui: <br/>
                                                </small>
                                            </div>
                                            {mappedStepsDiv}
                                        </div>

                                        <div className='col-6 lg:col-4 px-1 lg:px-3'>
                                            <h5 className='mb-1'>Numero totale di trasporti</h5>
                                            <Badge value={data.supplyChainLinks.length} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                            <div className='my-3'>
                                                <small>
                                                    di cui: <br/>
                                                </small>
                                            </div>
                                            {mappedLinksDiv}
                                        </div>

                                        <div className='col-6 lg:col-4 px-1 lg:px-3'>
                                            <h5 className='mb-1'>Numero di media</h5>
                                            <Badge value={media.length} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                            {
                                                noMediaSteps.length !== 0 ? 

                                                <>
                                                    <div className='my-3'>
                                                        <small>
                                                            <Tag icon="pi pi-exclamation-triangle" severity="warning" value="Alcuni step non hanno alcun media: "></Tag>
                                                        </small>
                                                    </div>

                                                    {noMediaSteps}
                                                </>

                                                : 


                                                    
                                                <div className='my-3'>
                                                    <small>
                                                        <Tag icon="pi pi-check" severity="success" value="Tutti gli step hanno almeno un media"></Tag>
                                                    </small>
                                                </div>
                                            }
                                        </div>

                                        <div className='col-6 lg:col-4 px-1 lg:px-3'>
                                            <h5 className='mb-1'>Numero di documenti</h5>
                                            <Badge value={docs.length} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                            {
                                                noMediaSteps.length !== 0 ? 

                                                <>
                                                    <div className='my-3'>
                                                        <small>
                                                            <Tag icon="pi pi-exclamation-triangle" severity="warning" value="Alcuni step non hanno alcun documento: "></Tag>
                                                        </small>
                                                    </div>

                                                    {noDocsSteps}
                                                </>

                                                : 


                                                    
                                                <div className='my-3'>
                                                    <small>
                                                        <Tag icon="pi pi-check" severity="success" value="Tutti gli step hanno almeno un documento"></Tag>
                                                    </small>
                                                </div>
                                            }
                                        </div>

                                        <div className='col-6 lg:col-4 px-1 lg:px-3'>
                                            <h5 className='mb-1'>Numero di claims</h5>
                                            <Badge value={docs.length} className="mr-2 prisma-blue-bg no-border" size="large"></Badge>

                                            {
                                                noMediaSteps.length !== 0 ? 

                                                <>
                                                    <div className='my-3'>
                                                        <small>
                                                            <Tag icon="pi pi-exclamation-triangle" severity="warning" value="Alcuni step non hanno alcun claim: "></Tag>
                                                        </small>
                                                    </div>

                                                    {noClaimsSteps}
                                                </>

                                                : 


                                                    
                                                <div className='my-3'>
                                                    <small>
                                                        <Tag icon="pi pi-check" severity="success" value="Tutti gli step hanno almeno un claim"></Tag>
                                                    </small>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                mappedSteps.length === 0 &&

                                <>
                                    <Message severity="warn" text="Non sono stati ancora registrati step di filiera" className='w-full justify-content-start'/>
                                </>
                            }
                        </div>
                    </TabPanel>
                    
                </TabView>

            </div>

        </>
    )

};

export default ProductDetail


