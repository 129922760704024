import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';

import { useNavigate, useParams } from 'react-router-dom';

const Menu = () => {

    const navigate = useNavigate();

    const params = useParams();

    return (
        <>
            <div className='prisma-blue-bg hidden lg:block' style={{height: '100vh'}}>
                <div className='px-4 pt-4'>
                    <Image alt="logo" src="https://imageshack.com/i/poUjCWn3p" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} height="35" />
                </div>

                <div style={{cursor: 'not-allowed'}} className={'flex align-items-center px-4 '+ (params['*'].split('/')[0] === 'profile' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'profile' ? "https://imageshack.com/i/pofuWZTRp" : "https://imageshack.com/i/pnynFwESp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>Dashboard</div>
                </div>

                <div className={'flex align-items-center cursor-pointer px-4 '+ (params['*'].split('/')[0] === 'supplierNewEdit' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")} onClick={() => navigate('/supplierNewEdit')}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'supplierNewEdit' ? "https://imageshack.com/i/pnuYfHvTp" : "https://imageshack.com/i/pmbIHwnXp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>Nuovo fornitore</div>
                </div>

                <div className={'flex align-items-center cursor-pointer px-4 '+ (params['*'].split('/')[0] === 'suppliers' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")} onClick={() => navigate('/suppliers')}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'suppliers' ? "https://imageshack.com/i/pnwg3km8p" : "https://imageshack.com/i/pnWBk7eyp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>I miei fornitori</div>
                </div>

                <div className={'flex align-items-center cursor-pointer px-4 '+ (params['*'].split('/')[0] === 'productNewEdit' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")} onClick={() => navigate('/productNewEdit')}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'productNewEdit' ? "https://imageshack.com/i/pnuYfHvTp" : "https://imageshack.com/i/pmbIHwnXp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>Nuovo prodotto</div>
                </div>

                <div className={'flex align-items-center cursor-pointer px-4 '+ (params['*'].split('/')[0] === 'products' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")} onClick={() => navigate('/products')}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'products' ? "https://imageshack.com/i/pm8mHY4Sp" : "https://imageshack.com/i/pnp1B5uDp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>I miei prodotti</div>
                </div>

                <div style={{cursor: 'not-allowed'}} className='flex align-items-center px-4 py-2 '>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src="https://imageshack.com/i/pm3ilNUDp" alt="Image"/>
                    <div className='flex text-white font-medium text-xl'>Crea Digital Product Passport</div>
                </div>

                <div style={{cursor: 'not-allowed'}} className={'flex align-items-center px-4 '+ (params['*'].split('/')[0] === 'analytics' ? "prisma-green-bg text-blue py-4" : "py-3 text-white")} onClick={() => navigate('/analytics')}>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src={(params['*'].split('/')[0] === 'analytics' ? "https://imageshack.com/i/po3fsZ8Ip" : "https://imageshack.com/i/poCqrpiLp")} alt="Image"/>
                    <div className='flex font-medium text-xl'>Analytics</div>
                </div>

                <div style={{cursor: 'not-allowed'}} className='flex align-items-center px-4 py-2 '>
                    <Image className="flex mr-2" imageStyle={{width: '18px', height: '18px'}} src="https://imageshack.com/i/poBTkcEkp" alt="Image"/>
                    <div className='flex text-white font-medium text-xl'>Sustainable Design Tools</div>
                </div>

                {/* <div className='text-white font-medium text-2xl px-4'>
                    Ultimi prodotti aggiunti
                </div>

                <div className='grid mt-3 px-4 mx-2' style={{height: '30vh', overflow: 'scroll'}}>
                    <div className='flex mb-2 align-items-center cursor-pointer' onClick={() => navigate('/products')}>
                        <Image className="flex mr-2" imageStyle={{width: '30px', height: '30px'}} src="https://imageshack.com/i/poyua1ozj" alt="Image"/>
                        <div className='flex text-white font-medium text-xl'>4NAT - Water</div>
                    </div>
                    <div className='flex mb-2 align-items-center cursor-pointer' onClick={() => navigate('/products')}>
                        <Image className="flex mr-2" imageStyle={{width: '30px', height: '30px'}} src="https://imageshack.com/i/poyua1ozj" alt="Image"/>
                        <div className='flex text-white font-medium text-xl'>4NAT - Water</div>
                    </div>
                    <div className='flex mb-2 align-items-center cursor-pointer' onClick={() => navigate('/products')}>
                        <Image className="flex mr-2" imageStyle={{width: '30px', height: '30px'}} src="https://imageshack.com/i/poyua1ozj" alt="Image"/>
                        <div className='flex text-white font-medium text-xl'>4NAT - Water</div>
                    </div>
                    <div className='flex mb-2 align-items-center cursor-pointer' onClick={() => navigate('/products')}>
                        <Image className="flex mr-2" imageStyle={{width: '30px', height: '30px'}} src="https://imageshack.com/i/poyua1ozj" alt="Image"/>
                        <div className='flex text-white font-medium text-xl'>4NAT - Water</div>
                    </div>
                    <div className='flex mb-2 align-items-center cursor-pointer' onClick={() => navigate('/products')}>
                        <Image className="flex mr-2" imageStyle={{width: '30px', height: '30px'}} src="https://imageshack.com/i/poyua1ozj" alt="Image"/>
                        <div className='flex text-white font-medium text-xl'>4NAT - Water</div>
                    </div>
                </div> */}
            </div>

        </>
    );
}

export default Menu
