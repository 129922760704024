import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

import _ from 'underscore'

const handleStyle = { left: 10 };

const StepSupplierFlowNode = ({ data }) => {
  return (
    <div className="step-supplier-flow-node">
      
      
      <div className="px-1">
        {
          !_.isEmpty(data.originSupplier) &&
          <div className='text-center'> 
            <FontAwesomeIcon icon="seedling"/>
            <div style={{fontSize: '8px'}}>{data.originSupplier.name}</div>
          </div>
        }
        {
          !_.isEmpty(data.processorSupplier) &&
          <div className='text-center'> 
            <FontAwesomeIcon icon="industry"/>
            <div style={{fontSize: '8px'}}>{data.processorSupplier.name}</div>
          </div>
        }
        {
          !_.isEmpty(data.traderSupplier) &&
          <div className='text-center'> 
            <FontAwesomeIcon icon="right-left"/>
            <div style={{fontSize: '8px'}}>{data.traderSupplier.name}</div>
          </div>
        } 
        {
          !_.isEmpty(data.owner) &&
          <div className='text-center'> 
            <FontAwesomeIcon icon="lightbulb"/>
            <div style={{fontSize: '8px'}}>{data.owner.name}</div>
          </div>
        } 
      </div>

      <Handle position={Position.Top} style={{ background: 'orange' }} id="a"/>
      <Handle position={Position.Bottom} style={{ background: 'green' }} id="b"/>
      <Handle position={Position.Right} style={{ background: 'green' }} id="c"/>
      <Handle position={Position.Left} style={{ background: 'orange' }} id="d"/>
    </div>
  );
}

export default StepSupplierFlowNode;

