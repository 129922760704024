import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


import _ from 'underscore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getProductUnities } from '../services/RemoteService'

const CreateProductUnityForm = (props) => {
    const [selected, setSelected] = useState([]);

    const navigate = useNavigate();

    const [qrProductUnity, setQrProductUnity] = useState({});
    useEffect(() => {
        const fetchProductUnities = async () => {
            const qrProductUnityResponse = await getProductUnities({
                "nfcTagId": null,
                "productId": props.product.id
                });
            if(qrProductUnityResponse.status === 200){
                setQrProductUnity(qrProductUnityResponse.response.data.productUnities[0]);
            }
        }

        fetchProductUnities()
    }, [])

    const itemTemplate = (item) => {
        return (
            <div className="grid shadow-2 border-round-md px-3 py-2">
                <div className='col col-6'>
                    <Divider align="left" className='my-0 pt-0 pb-2'>
                        <div className="inline-flex align-items-center">
                            <b>Numero tag</b>
                        </div>
                    </Divider>
                    <small className="font-italic">{item.prismaId}</small>
                </div>
                <div className='col col-6'>
                    <Divider align="left" className='my-0 pt-0 pb-2'>
                        <div className="inline-flex align-items-center">
                            <b>Codice interno</b>
                        </div>
                    </Divider>
                    <small className="font-italic">{item.internalId}</small>
                </div>
            </div>
        );
    };

    const qrCodeHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <FontAwesomeIcon className="mr-2" icon="qrcode" />
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Qr Code</span>
            </a>
        )
    }

    const nfcTagHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link" onClick={options.onClick}>
                <FontAwesomeIcon className="mr-2" icon="tag" />
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Tag NFC</span>
            </a>
        )
    }

    const productUnityHeaderTemplate = (options) => {
        return (
            <a role="tab" className="p-tabview-nav-link">
                <FontAwesomeIcon className="mr-2" icon="wand-magic-sparkles" />
                <span style={{color: '#495057', fontWeight: '600', lineHeight: '1', whiteSpace: 'nowrap'}}>Tag NFC personalizzato</span>
                <Tooltip target=".target-icon-descr" style={{fontSize: '10px'}}/>
                <i className="pi pi-ban target-icon-descr ml-2" data-pr-tooltip="Funzionalità attualmente non disponibile" data-pr-position="top" ></i>
            </a>
        )
    }

    const generateBulkProductUnities = () => {
        if(selected){
            confirmDialog({
                message: 'Sicuro di voler generare i passaporti per le unità di prodotto selezionate? L\'operazione non sarà reversibile.',
                header: 'Richiesta di conferma',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: ()  => {
                    props.onRegisterProductUnityBulk(selected)
                },
                reject: () => function(){}
            });
        }
    }

    return(
        <>
            <ConfirmDialog></ConfirmDialog>

            <TabView className="tabview-header-icon">

                <TabPanel headerTemplate={nfcTagHeaderTemplate} className='p-2'>
                    <div className='pr-3 col col-12 mx-0 px-0 flex align-items-end justify-content-between'>
                        <div className='flex justify-content-between'>
                            <div className='col col-6'>
                                <h5 className='mb-1 mt-0'>Numero di tag</h5>
                                <div className='flex mt-3'>
                                    <InputText value={props.limit} onChange={props.onLimitChange} className='flex-auto flex mr-3' placeholder="Inserisci numero tag"/>
                                    <Button icon="pi pi-refresh" className="flex-initial flex prisma-green-bg text-blue no-border-btn" onClick={props.onRefreshTags} />
                                </div>
                            </div>

                            <div className='col col-6'>
                                <h5 className='mb-1 mt-0'>Filtra per tag ID</h5>
                                <div className='flex mt-3'>
                                    <InputText value={props.tagIdFilter} onChange={props.onTagIdFilterChange} className='flex-auto flex mr-3' placeholder="Inserisci l'id del tag"/>
                                    <Button icon="pi pi-refresh" className="flex-initial flex prisma-green-bg text-blue no-border-btn" onClick={props.onRefreshTags} />
                                </div>
                            </div>
                        </div>
                        <div className='mb-1'>
                            <Button icon="pi pi-send" label="Registra" className="flex-initial flex prisma-green-bg text-blue no-border-btn" disabled={selected.length===0} onClick={() => generateBulkProductUnities()}/>
                        </div>
                    </div>
                    {
                        props.showLimitMessage &&
                        <Message className='w-full justify-content-start mb-2' severity="warn" text={"Il numero di tag richiesto è superiore al numero di tag disponibili ("+props.nfcTags.length+")"} />
                    }
                    <div className="card">
                        <DataTable value={props.nfcTags} selectionMode={null} selection={selected} onSelectionChange={(e) => setSelected(e.value)} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="prismaId" header="Code"></Column>
                            <Column field="internalId" header="Name"></Column>
                        </DataTable>
                    </div>
                </TabPanel>

                <TabPanel headerTemplate={qrCodeHeaderTemplate} className='p-2'>
                    {
                        _.isEmpty(qrProductUnity) &&
                        <>
                            <Message className='w-full justify-content-start' severity="info" text="Cliccando su procedi verrà generato un QrCode per accedere 
                            al passaporto del prodotto corrente." />
                            
                            <Button icon="pi pi-arrow-right" label="Procedi" className="flex-initial flex prisma-green-bg text-blue no-border-btn mt-5" onClick={props.onGenerateQrCode} />
                        </>
                    }

                    {
                        !_.isEmpty(qrProductUnity) &&

                        <Message className='w-full justify-content-start' severity="info" text="Il prodotto corrente ha già un Qr Code associato. E' possibile associarvi dei tag NFC." />
                    }
                </TabPanel>

                <TabPanel headerTemplate={productUnityHeaderTemplate} className='p-2'>
                </TabPanel>
                    
            </TabView>
        </>
    )
}

export default CreateProductUnityForm;